import Joi from "joi";
import {address_schema} from '.';

export const application_schema = Joi.object({
    name: Joi.string()
        .required()
        .messages({
            "any.required": `"Business Name" is a required field`,
        }),
    type: Joi.string()
        .required()
        .messages({
            "any.required": `"Business Type" is a required field`,
        }),
    license: Joi.when("type", {
        is: Joi.valid('company'),
        then: Joi.string().required(),
        otherwise: Joi.optional(),
        })
        .messages({
            "any.required": `"Business License" is a required field`,
        }),    
    contact: Joi.string()
        .required()
        .messages({
            "any.required": `"Business Contact" is a required field`,
        }),
    email: Joi.string()
        .email({ minDomainSegments: 2, tlds: false })
        .required()
        .messages({
            "string.email": `"Email" must be a valid email address`,
        }),
    phone: Joi.string()
        .required()
        .min(10)
        .messages({
            'string.min': 'Invalid phone number',
            'any.required': 'Phone is a required field',
        }),   
    about: Joi.string()
        .required()
        .messages({
            'any.required': '"About" is a required field',
        }),           
    address: address_schema,
    accept_terms: Joi.any()
        .valid(1, true)
        .required()
        .messages({
            "any.required": "Agreement to the Terms and Conditions is required",
        }),
});