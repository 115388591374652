import { useEffect, useState } from 'react';
import { Header, Loading } from 'components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useVideoContext } from 'hooks';
import { flash, debug } from 'lib';

export const Countdown = ({
    open = false,
    onDone = (success: boolean) => {},
}:{
    open?: boolean;
    onDone: (success: boolean) => void;
}) => {

    const {
      startStream,
    } = useVideoContext();

    const [active, setActive] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number>(-1);

    useEffect(() => {
        setActive(open);
    },[open]);

    useEffect(() => {
        if (active) {
            setCountdown(5);
        }
    },[active]);

    useEffect(() => {
        if (countdown < 0) return;
        if (countdown === 0) {
            handleComplete();
        }
        const timer = window.setTimeout(() => {
            setCountdown(prev => (!!prev && prev > 0 ? prev - 1 : 0));
        }, 1000);
        return () => window.clearTimeout(timer);
    },[countdown]);

    const handleComplete = async () => {
        debug('At handle complete');
        setCountdown(-1);
        const started = await startStream!();
        if (!started) flash.error('Error starting video');
        setActive(false);
        onDone(started);
    }

    const handleCancel = () => {
        setCountdown(-1);
        setActive(false);
        onDone(false);
    }

    if (!active) return null;

    return (
        <Box 
          component="div"
          sx={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              textAlign: 'center',
              zIndex: 3,
              fontColor: '#fffff0',
          }}
        >
          <Box component="div"
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: '#121212',
              opacity: .3,
              borderRadius: '5px',
            }}
          />
          <Box 
            sx={{
              zIndex: 4,
              position: 'relative',
              fontColor: '#fffff0',
            }}
            mt={{ xs: 12, md: 8 }}
          >
            <Header as="h4" style={{color: '#ffffff'}}>
              Get Ready!
            </Header>
            <Box sx={{ position: 'relative', color: '#fffff0' }}>
              {countdown < 0 
                ? <Loading>Starting Video</Loading>
                : <Typography
                    fontSize={'5rem'}
                    fontWeight='bolder'
                    sx={{ color: '#fffff0' }}
                  >
                    {!!countdown ? countdown : ''}
                  </Typography>
              }
            </Box>
          </Box>
          {countdown > 0 && (
            <Box display="flex" justifyContent="center">
              <Stack direction="row" spacing={2}>
                <Button onClick={() => handleCancel()} variant='outlined'>
                  <Typography color='#fffff0'>
                    Cancel
                  </Typography>
                </Button>
                <Button onClick={() => handleComplete()} variant='outlined'>
                  <Typography color="#fffff0">
                    Skip
                  </Typography>
                </Button>
              </Stack>
            </Box>
          )}
    </Box>
  )
}