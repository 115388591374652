import Joi from "joi";

export const store_schema = Joi.object({
    vanity: Joi
        .string()
        .required()
        .min(4)
        .max(25)
        .messages({
            "string.min": `"Vanity" should have a minimum length of {#limit} characters`,
            "string.max": `"Vanity" should have a maximum length of {#limit} characters`,
            "any.required": `"Vanity" is a required field`,
        }),
    name: Joi
        .string()
        .required()
        .min(4)
        .max(50)
        .messages({
            "string.min": `"Store Name" should have a minimum length of {#limit} characters`,
            "string.max": `"Store Name" should have a maximum length of {#limit} characters`,
            "any.required": `"Store Name" is a required field`,
        }),
})