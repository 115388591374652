import styled from  '@emotion/styled';
import { keyframes } from '@emotion/react';
import {useMobile} from 'components';
import {useEventContext} from 'hooks';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const BlastMessage = ({text = '', presenter = false}) => {
  const {clearBlastMessage} = useEventContext();

  if (!text || text === '') return null;

  return useMobile ? (
    <>
      <MobileBlastContainer>
        <ScrollingMarqueeText duration={text.split(' ').length * 0.5 + 10}>
          {text}
        </ScrollingMarqueeText>
      </MobileBlastContainer>
      <div
        style={{
          backgroundColor: 'red',
          position: 'absolute',
          zIndex: 16,
          right: '0',
          width: '38px',
          top: 0,
          height: '30px',
        }}
      >
        <IconButton
          style={{
            position: 'absolute',
            right: '0',
            bottom: '-3px',
            zIndex: '12',
            backgroundColor: 'transparent',
            color: '#ffffff',
          }}
        >
          <CloseIcon/>
        </IconButton>
      </div>
    </>
  ) : (
    <>
      <BlastContainer presenter={presenter}>
        <ScrollingMarqueeText duration={text.split(' ').length * 0.5 + 10}>
          {text}
        </ScrollingMarqueeText>
      </BlastContainer>
      <CloseButton
        sx={presenter ? { bottom: '30px'} : {top: '2px'}}
        onClick={() => clearBlastMessage()}
      >
        <CloseIcon/>
      </CloseButton>
    </>
  );
};

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0px;
  z-index: 16;
  background-color: transparent !important;
  color: rgb(255, 255, 255) !important;
  cursor: pointer;
  text-align: center;
  width: 16px;
  height: 16px;
  padding: 0 !important;
`;

interface BlastContainerProps {
  presenter?: boolean;
}

const BlastContainer = styled.div<BlastContainerProps>`
  position: absolute;
  height: 50px;
  text-align: center;
  width: 100%;
  z-index: 15;
  ${props => (props.presenter ? 'bottom: 0' : 'top : 0')};
  background-color: red;
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  line-height: 48px;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
  animation: fadeIn ease 1s;
`;

const marquee = keyframes`
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
`;

interface ScrollingMarqueeTextProps {
  duration?: number;
}

const ScrollingMarqueeText = styled.div<ScrollingMarqueeTextProps>`
  width: auto;
  display: inline-block;
  padding-left: 100%;
  animation: ${marquee} ${props => props.duration || 20}s linear infinite;
  text-shadow: 3px 2px 2px rgb(0, 0, 0, 0.7);
`;

const MobileBlastContainer = styled(BlastContainer)`
  height: 30px;
  bottom: 30px;
  font-size: 16px;
  line-height: 27px;
`;
