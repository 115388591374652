import {useEventContext} from 'hooks';
//import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Typography from '@mui/material/Typography';

export const EventTitle = () => {
  const {event} = useEventContext();
  return event ? (
    <>
      <Typography noWrap>
        {/*event?.get('ticketed') && <ConfirmationNumberIcon />*/}
        <span style={{ fontWeight: 'bolder' }}>
          {event?.get('name')}
        </span>
          {event?.get('presentedBy') && (
            <span style={{ fontSize: 'small' }}> - {event?.get('presentedBy')}</span>
          )}
      </Typography>
    </>
  ) : null;
};

