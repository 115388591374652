//import { Model, validator, TransformValidateDateTime } from '../Classes';
//import { DateTime } from 'luxon';

export enum InviteAction {
    accept = 'accept',
    decline = 'decline',
}

export enum InviteStatus {
    pending = 'pending',
    accepted = 'accepted',
    declined = 'declined',
}

/*
export class Invite extends Model<Invite> {
    @validator.IsEnum(InviteStatus)
    status: InviteStatus;

    @validator.IsString()
    @validator.IsOptional()
    uid?: string;

    @validator.IsString()
    email: string;

    @validator.IsString()
    orgId: string;

    @validator.IsString()
    organization: string;

    @validator.IsString()
    invitedBy: string;

    @TransformValidateDateTime()
    createdAt: DateTime;

    @validator.IsString()
    createdBy: string;

    @TransformValidateDateTime()
    @validator.IsOptional()
    updatedAt?: DateTime;
}
*/