import {memo, useState} from 'react';
import {CdnImage} from 'components';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { Modal } from 'modals';

const isEqual = (prev: any, next: any) => {
  return (
    prev && next && prev.name === next.name && prev.image === next.image //&&
    //prev.trigger === next.trigger
  );
};

interface Props {
  name?: string;
  image?: string;
  description?: string;
  trigger?: JSX.Element;
}

export const ProductModal = memo(({name, image, trigger, description}: Props) => {

  return (
    <Modal
      trigger={trigger || <CdnImage link src={image} size="tiny"/>}
      title={name}
      closed={()=>{}}
    >
      <DialogContent dividers>
        <Box mb={1}>
          <CdnImage src={image} style={{margin: '0 auto'}} />
        </Box>
        <Box>
          {description}
        </Box>
      </DialogContent>
    </Modal>
  );
}, isEqual);
