import {useEffect, useState} from 'react';
import {useFirebaseContext, useRefs} from 'hooks';
import {DateTime} from 'luxon';
import {Invitation, GenericObject} from 'components';
import {getDocs, query, where} from 'firebase/firestore';

export const CheckInvitation = () => {
  const [invite, setInvite] = useState<GenericObject | null>(null);

  const {firebaseUser} = useFirebaseContext();
  const { invitesRef } = useRefs();

  useEffect(() => {
    if (!firebaseUser || !invitesRef) return;
    checkInvitations();
  }, [firebaseUser, invitesRef]);

  const checkInvitations = async () => {
    try {
      const inviteDocs = await getDocs(
        query(
          invitesRef!,
          where('uid', '==', firebaseUser?.uid),
          where('status', '==', 'pending')
        )
      );
      // see if any of the invites are still valid
      const valid = inviteDocs.docs
        .filter(
          doc =>
            DateTime.utc().diff(
              DateTime.fromJSDate(doc.get('createdAt').toDate()),
              ['hours']
            ).hours <= 24
        )
        .sort(
          (a, b) =>
            DateTime.fromJSDate(a.get('createdAt').toDate()).toMillis() -
            DateTime.fromJSDate(b.get('createdAt').toDate()).toMillis()
        );
      if (valid.length > 0) {
        setInvite(Object.assign({}, {id: valid[0].id}, valid[0].data()));
      }
    } catch (err) {}
  };

  return (
    invite && (
      <Invitation
        invite={invite}
        onFinish={() => {
          setInvite(null);
          checkInvitations();
        }}
      />
    )
  );
};
