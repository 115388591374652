"use client";
//import { FirebaseProvider, AppProvider, GameProvider } from 'providers';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import { RefsProvider, IntlMessageProvider, FirebaseProvider, SessionProvider, SessionContext, FacebookProvider, MessagingProvider } from 'providers';
import { InternalRoutes } from 'routes';
import { PageLoader, AlertBox } from 'components';
import {IntlProvider} from 'react-intl';
import languages from 'intl';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import LogRocket from 'logrocket';

//if (process.env.REACT_APP_STAGE === 'production') {
  if (process.env.REACT_APP_LOGROCKET)
    LogRocket.init(process.env.REACT_APP_LOGROCKET);
//}

function fallbackRender({ error, resetErrorBoundary }: FallbackProps) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <AlertBox severity="error">
      <p>Something went wrong:</p>
      <p>{error.message}</p>
    </AlertBox>
  );
}

const App = () => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme => theme}>
      <CssBaseline/>
      <ErrorBoundary fallbackRender={fallbackRender}>
        <FirebaseProvider>
          <RefsProvider>
          <SessionProvider>
            <SessionContext.Consumer>
              {({locale = 'en', ready}) => (
                  <IntlProvider
                      locale={locale}
                      defaultLocale="en"
                      messages={languages[locale]}
                    >
                      <IntlMessageProvider>
                        {ready ? (
                          <>
                            <MessagingProvider>
                              <FacebookProvider>
                                {/*<ModalProvider>*/}
                                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    <InternalRoutes/>
                                  </LocalizationProvider>
                              {/* </ModalProvider>*/}
                              </FacebookProvider>
                            </MessagingProvider>
                            <ToastContainer autoClose={3000} hideProgressBar/>
                          </>
                        ) : (
                          <PageLoader/>
                        )}
                      </IntlMessageProvider>
                  </IntlProvider>
              )}
            </SessionContext.Consumer>
          </SessionProvider>
          </RefsProvider>
        </FirebaseProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </MuiThemeProvider>
)

export default App;
