import {VideoProvider,EventType} from '@kwixl/interface';
import {CdnImage} from 'components';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

export const VideoProviderIcon = ({
  value,
  height = 0,
  width = 0,
}: {
  value: string;
  height?: number;
  width?: number;
}) => {
  if (!width && !height) {
    height = 18;
  }
  if (height && !width) width = height;
  if (width && !height) height = width;
  switch (value) {
    case EventType.ccs:
    case VideoProvider.facebook:
      return <FacebookIcon color="info" height={height} width={width}/>;
    case VideoProvider.youtube:
      return <YouTubeIcon color="error" height={height} width={width}/>;
    default:
      return <CdnImage inline src="/icons/favicon-16x16.png" height={height} width={width} permanent={true}/>
  }
};
