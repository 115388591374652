import extend from 'lodash/extend';
import {GenericObject} from 'components';

const HEADERS = {'Content-Type': 'application/json'};

const handleResponse = (response: Response) => {
  if (response.status === 401 || response.status === 403) {
    return {success: false, error: 'Unauthorized'};
  }
  return response.json().then(payload => {
    return payload;
  });
};

const Api = {
  token: '',
  exec: (url: string, options: GenericObject) => {
    if (url.indexOf('http') < 0) {
      url = `${process.env.REACT_APP_API}${
        url.indexOf('/') !== 0 ? '/' : ''
      }${url}`;
    }
    options.headers = extend({}, options.headers || {}, HEADERS);
    if (Api.token) {
      options.headers[process.env.REACT_APP_TOKEN_ID || ''] = Api.token;
    }
    return fetch(url, options)
      .then(handleResponse)
      .catch(err => {
        console.error(err.message);
      });
  },
  get: (url: string, options: GenericObject) =>
    Api.exec(url, {
      method: 'GET',
      ...options,
    }),
  post: (url: string, payload: GenericObject, options = {}) =>
    Api.exec(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      ...options,
    }),
  put: (url: string, payload: GenericObject, options: GenericObject) =>
    Api.exec(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
      ...options,
    }),
  patch: (url: string, payload: GenericObject, options: GenericObject) =>
    Api.exec(url, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      ...options,
    }),
  delete: (url: string) =>
    Api.exec(url, {
      method: 'DELETE',
    }),
  setUserToken: (token: string) => {
    Api.token = token;
  },

  syncUser: (data: GenericObject) => Api.post('/auth/sync', {data}),
  addressLookup: (address: string) =>
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    )
      .then(handleResponse)
      .then(json => {
        try {
          const result: GenericObject = {};
          json.results[0].address_components.forEach((row: GenericObject) => {
            if (row.types.indexOf('locality') > -1) {
              result.city = row.long_name;
            } else if (row.types.indexOf('administrative_area_level_2') > -1) {
              result.county = row.short_name;
            } else if (row.types.indexOf('administrative_area_level_1') > -1) {
              result.state = row.short_name;
            } else if (row.types.indexOf('country') > -1) {
              result.country = row.short_name;
            }
          });
          return result;
        } catch (err) {
          return null;
        }
      })
      .catch(err => {
        console.error(err.message);
      }),

  startCommentsListener: (eventId: string) =>
    Api.post('fb/listener', {eventId}),
  stopCommentsListener: (eventId: string) => Api.post('fb/stop', {eventId}),
};

export default Api;
