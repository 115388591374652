import { Model, validator, TransformValidateDateTime } from '../Classes';
import {DateTime} from 'luxon';

export class MessageQueue extends Model<MessageQueue> {

    @validator.IsString()
    @validator.IsOptional()
    uid?: string;

    @validator.IsString()
    @validator.IsOptional()
    template: string;

    @validator.IsObject()
    data: {[key:string]:any};

    @validator.IsString()
    @validator.IsOptional()
    email?: string;
    
    @TransformValidateDateTime()
    createdAt: DateTime;

    @validator.IsString()
    @validator.IsOptional()
    phone?: string;

    @TransformValidateDateTime()
    @validator.IsOptional()
    sentAt?: DateTime;
}
