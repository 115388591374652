import {isMobile, mobileModel, isTablet, isDesktop} from 'react-device-detect';

export const checkMobile = () => isMobile || window?.innerWidth <= 768;

export const useMobile = checkMobile()

export const checkTablet = () => isTablet || window?.innerWidth <= 768;

export const checkDesktop = isDesktop || window?.innerWidth >= 769;

export const isIPhone = () => mobileModel === 'iPhone';

export * from 'react-device-detect';

export const isIPhone8 = () =>
  mobileModel === 'iPhone' &&
  ((window.matchMedia('(orientation: portrait)').matches &&
    window.screen.width === 375 &&
    window.screen.height === 667) ||
    (window.matchMedia('(orientation: landscape)').matches &&
      window.screen.width === 667 &&
      window.screen.height === 375) ||
    (window.matchMedia('(orientation: portrait)').matches &&
      window.screen.width === 414 &&
      window.screen.height === 736) ||
    (window.matchMedia('(orientation: landscape)').matches &&
      window.screen.width === 736 &&
      window.screen.height === 414));

/*

// iphone 3 
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 1) { }

// iphone 4 
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2) { }

//iphone 5 
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) { }

// iphone 6, 6s, 7, 8
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) { }

// iphone 6+, 6s+, 7+, 8+
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) { }

// iphone X , XS, 11 Pro
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) { }

// iphone XR, 11
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) { }

// iphone XS Max, 11 Pro Max
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3) { }

*/
