//import { Model, validator, TransformValidateModel, TransformValidateDateTime } from '../Classes';
//import {DateTime} from 'luxon';

  
export const invoiceStatusName = (status: InvoiceStatus) => {
    switch (status) {
        case InvoiceStatus.pending:
            return 'Pending';
        case InvoiceStatus.active:
            return 'Payable';
        case InvoiceStatus.confirmed:
            return 'Confirmed';
        case InvoiceStatus.paid:
            return 'PAID';
        case InvoiceStatus.shipped:
            return 'Shipped';
        case InvoiceStatus.refunded:
            return 'REFUNDED';
        case InvoiceStatus.canceled:
            return 'CANCELED';
        case InvoiceStatus.payment_pending:
            return 'Payment Pending';
        case InvoiceStatus.deleted:
            return 'Deleted';
        default:
            return status;
    }
};
  

export enum InvoiceStatus {
    none = "none",
    pending = "pending",
    confirmed = "confirmed",
    active = "active",
    payment_pending = "payment_pending",
    paid = "paid",
    shipped = "shipped",
    refunded = "refunded",
    canceled = "canceled",
    deleted = "deleted",
}

/*
export class InvoiceEvent extends Model<InvoiceEvent> {

    @validator.IsString()
    name: string;

    @TransformValidateDateTime()
    startAt: DateTime;

    @validator.IsObject()
    @validator.IsOptional()
    settings: { [key:string]:any};

}

export class Invoice extends Model<Invoice> {

    @TransformValidateModel(InvoiceEvent)
    event: InvoiceEvent;

}
*/