import { ChangeEvent } from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { DocumentSnapshot } from 'firebase/firestore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Money, cdnImage, ItemTypeIcon, GenericObject, GridItem } from 'components';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PublishModal } from './PublishModal';
import { ConfirmModal, EditItemModal } from 'modals';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import { useRefs, useSessionContext } from 'hooks';
import { updateDoc, serverTimestamp } from '@firebase/firestore';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { InventorySource } from '@kwixl/interface';

const DragHandle = () => <ControlCameraIcon/>;

interface SortableItemProps {
    item: DocumentSnapshot,
    source?: InventorySource,
    filtered?: boolean;
    onSelect: (id: string, checked: boolean) => void;
    selected?: boolean;
}

const ItemLine = ({ label, value }:{ label: string, value: string | JSX.Element }) => {
    return (
      <Box>
        <Typography sx={{display:'inline-block'}} variant="body2">
          <span style={{fontWeight: 'bolder', marginRight: '5px'}}>{label}:</span>
          {value}
        </Typography>
      </Box>
    )
  }

export const SortableItem = ({
    item,
    source,
    filtered = false,
    onSelect,
    selected = false,
}: SortableItemProps) => {

    if (!source && item) source = item.get('source');

    const {inventoryItemRef} = useRefs();

    const {organization} = useSessionContext();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: item.id});
  
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
  

    const handleCheck = ({ target: { checked }}: ChangeEvent<HTMLInputElement>) => {
        onSelect(item.id, checked);
    }

    const deleteItem = async (id: string) => {
        await updateDoc(inventoryItemRef!(organization?.id || 'x', id || 'x'), {deletedAt: serverTimestamp()});
    };

    const available = !!Object.keys(item.get('purchases') || {}).length
        ? item.get('qty') - Object.keys(item.get('purchases')).reduce((total: number, key: string) => total += (item.get(`purchases.${key}.qty`) || 0), 0)
        : item.get('qty');

    return (
        <Box 
            component="div" 
            ref={setNodeRef} 
            sx={{
                ...style, 
                margin: {
                    xs: '7px auto 30px auto', 
                    md: '7px',
                },
                display: { 
                    xs: 'block', 
                    md: 'inline-block' 
                }, 
                height: '300px', 
                width: { 
                    xs: '80%', 
                    md: '250px' 
                }
            }} 
            onClick={()=>true}
        >
            <Card id={`item-card-${item.id}`} sx={{width:'100%', position:'relative'}}>
                <Box component="div" sx={{ position: 'absolute', right: 5, top: 5 }}>
                    {source?.type === 'post' && (
                        <PublishModal 
                            trigger={<Chip label={item.get('published') ? "Published" : "Unpublished"} color={item.get('published') ? 'info' : "warning"}/>}
                            source={source} 
                            product={item}
                        />
                    )}
                </Box>
                <EditItemModal 
                    trigger={
                        <CardMedia
                            component="img"
                            sx={{ height: 145 }}
                            image={cdnImage(item.get('image'))}
                            alt={item.get('name')}
                        />
                    }
                    item={item}
                    source={source}
                />
                <CardContent>
                    <Box sx={{ flexGrow: 1, cursor: 'pointer' }}mb={1}>
                        <Typography noWrap sx={{width:'100%', fontWeight: 'bolder'}}>{item.get('name')} {item.get('position')}</Typography>
                    </Box>
                    <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <ItemLine label="Price" value={<Money value={item.get('price')}/>}/> 
                        <ItemLine label="Qty" value={item.get('qty')}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ItemLine label="Lot" value={item.get('lot')}/>
                        <ItemLine label="SKU" value={item.get('sku')}/>
                    </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{ borderTop: '1px solid #e2e2e2' }}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Checkbox checked={selected} onChange={handleCheck} sx={{padding:0}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <ItemTypeIcon type={item.get('type')} fontSize='small' sx={{ verticalAlign:'middle'}}/>
                        </Grid>
                        <GridItem flexGrow={1} textAlign='center'>
                            {available > 0 && `${available} Available`}
                            {!available && <Typography color="error" fontWeight='bold'>SOLD OUT</Typography>}
                        </GridItem>
                        <Grid item xs={filtered ? 4 : 2} textAlign={filtered ? 'right' : 'center'}>
                            <ConfirmModal
                                fullWidth
                                maxWidth='sm'
                                title={`Delete ${item.get('name')}?`}
                                trigger={<DeleteForeverIcon color="error" fontSize="small" sx={{verticalAlign:'middle'}}/>}
                                onConfirm={() => deleteItem(item.id)}
                            />
                        </Grid>
                        {!filtered && (
                            <Grid item xs={2} textAlign='right'>
                                <div {...attributes} {...listeners}><DragHandle/></div>
                            </Grid>
                        )}
                    </Grid>
                </CardActions>
            </Card>
        </Box>
    );
}