export const CardLogo = ({brand = ''}) => {
  let name = 'credit card outline';
  let color = 'blue';
  return (() => {
    switch (brand.toLowerCase()) {
      case 'visa':
        name = 'cc visa';
        break;
      case 'mastercard':
        name = 'cc mastercard';
        color = 'orange';
        break;
      case 'diners':
      case 'dinersclub':
      case 'diners club':
        name = 'cc diners club';
        break;
      case 'american express':
      case 'americanexpress':
      case 'amex':
        name = 'cc amex';
        color = 'green';
        break;
      case 'discover':
        name = 'cc discover';
        break;
      case 'jcb':
        name = 'cc jcb';
        break;
      default:
        break;
    }
    return <></>;
  })();
};
