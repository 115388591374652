import React from 'react';
import {useEventContext} from 'hooks';
import {OptimizedVideo, KwixlVideo} from 'components';
import {VideoProvider} from '@kwixl/interface';

interface Props {
  onSize?: ({ width, height }:{ width: number, height: number }) => void;
  fullScreen?: boolean;
}

export const Video = React.memo<Props>(
  ({
    onSize = () => {}, 
    fullScreen = false,
  }: Props) => {

    const {event} = useEventContext();

    switch (event?.get('stream.provider')) {
      case VideoProvider.facebook:
      case VideoProvider.youtube:
        return (
          <OptimizedVideo
            url={event?.get('stream.url')}
            onSize={onSize}
            fullScreen={fullScreen}
          />
        );
      default:
        return <KwixlVideo onSize={onSize} fullScreen={fullScreen}/>;
    }
  }
);
