import Joi from "joi";

export const phone_schema = Joi.object({
    phoneNumber: Joi
        .string()
        .min(11)
        .messages({
            "string.min": `"Phone" should have a minimum length of {#limit} characters including country code`,
            "any.required": `"Phone" is a required field`,
        })
});