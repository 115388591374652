import {DateTime} from 'luxon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toDateTime = (value: any): DateTime | null => {
  // ISO string, perhaps.  If it isn't a valid ISO string,
  // then don't do anything
  if (typeof value === 'string') {
    const datetime = DateTime.fromISO(value);
    if (datetime.isValid) {
      return datetime.toUTC();
    }
  }

  // Native JS date object
  if (value instanceof Date) {
    const datetime = DateTime.fromJSDate(value);
    if (datetime.isValid) {
      return datetime.toUTC();
    }
  }

  //console.log('toDateTime', value);

  // Already a DateTime instance
  if (value instanceof DateTime || value.isLuxonDateTime) {
    if (value.constructor === Object && value.ts) {
      // Value is a plain object, most likely from Firebase's
      // semi-broken function Serializer that doesn't handle
      // dates correctly.
      return DateTime.fromMillis(value.ts, {zone: 'utc'});
    }

    if (value.isValid) {
      return value.toUTC();
    }
  }

  // Firestore Timestamp object
  if (value.toDate) {
    const datetime = DateTime.fromJSDate(value.toDate());
    if (datetime.isValid) {
      return datetime.toUTC();
    }
  }

  // Serialized Firestore Timestamp object
  if (value.constructor === Object && (value._seconds || value.seconds)) {
    const seconds = value._seconds || value.seconds;
    const nseconds = value._nanoseconds || value.nanoseconds || 0;
    const datetime = DateTime.fromSeconds(seconds + nseconds * 1e-9, {
      zone: 'utc',
    });

    if (datetime.isValid) {
      return datetime.toUTC();
    }
  }

  return null;
};