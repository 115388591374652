import { ChangeEvent, useReducer } from "react";
import DatePicker from "react-datepicker";
import { reducer } from "lib";
import { useRefs, useSessionContext } from 'hooks';
import {
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { PrimaryButton, CancelButton, GenericObject } from 'components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import BlockIcon from '@mui/icons-material/Block';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

const initialState = {
  open: false,
  reason: '',
  expiresAt: new Date(),
}

export const BanModal = ({ user }:{ user: GenericObject }) => {

  const[{
    open,
    reason,
    expiresAt,
  }, dispatch] = useReducer(reducer, initialState);

  const {
    organization,
  } = useSessionContext();

  const {blocksRef} = useRefs();

  const closeModal = () => {
    dispatch({ open: false });
  };

  const ban = async () => {
    await addDoc(blocksRef!, { createdAt: serverTimestamp(), displayName: user.displayName, uid: user.uid, type: 'ban', reason, expiresAt, orgId: organization?.id });
    closeModal();
  };

  if (!open) return (
    <IconButton onClick={() => dispatch({ open: true })}>
        <BlockIcon/>
    </IconButton>
  )
  return (
    <Dialog
      open={open}
      onClose={() => dispatch({ open: false })}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>
        Ban User - {user.displayName} ({user.handle})
      </DialogTitle>
      <DialogContent>
          <label>Reason (visible to user):</label>
          <TextField
            fullWidth
            placeholder="Enter a reason for banning this user."
            name="reason"
            value={reason}
            onChange={({ target: { value }}: ChangeEvent<HTMLInputElement>) => dispatch({ reason: value })}
          />
          <p style={{ marginTop: "1rem" }}>
            Select a date when this user can start participating in your events
            again. Leave blank to ban forever.
          </p>
          <DatePicker
            selected={expiresAt}
            onChange={(date) => dispatch({ expiresAt: date })}
            //onSelect={ date => setState({ startDate : date })}
            dateFormat="MM/dd/yyyy"
            minDate={new Date()}
            customInput={<TextField />}
          />
          {!expiresAt && (
            <p>
              <small>This user will be banned forever.</small>
            </p>
          )}
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={() => closeModal()}/>
        <PrimaryButton startIcon={<BlockIcon/>} onClick={() => ban()}>
          Ban
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
