import { ReactNode } from 'react';
import {Trademark} from './Trademark';

export interface BaseNameProps {
    basic?: boolean;
    children?: ReactNode;
}
export interface NameProps extends BaseNameProps {
    text: string;
}

export const Name = ({text, children = null, basic = false}: NameProps) => (
  <>
      { basic 
        ? text
        : <Trademark>{text}</Trademark>
      }
    {children}
  </>
)

export const AppName = ({children = null, basic = false}: BaseNameProps) => 
  <Name text={process.env.REACT_APP_NAME || ''} basic={basic}>{children}</Name>

export const KwixlCamText = ({children = null, basic = false}: BaseNameProps) => 
  <Name text={'KwixlCam'} basic={basic}>{children}</Name>

export const YoutubeText = ({children = null, basic = false}: BaseNameProps) => 
  <Name text={'Youtube'} basic={basic}>{children}</Name>

export const FacebookText = ({children = null, basic = false}: BaseNameProps) => 
  <Name text={'Facebook'} basic={basic}>{children}</Name>

export const StripeText = ({children = null, basic = false}: BaseNameProps) => 
  <Name text={'Stripe'} basic={basic}>{children}</Name>

export const PayPalText = ({children = null, basic = false}: BaseNameProps) => 
  <Name text={'Paypal'} basic={basic}>{children}</Name>

export const GoogleText = ({children = null, basic = false}: BaseNameProps) => 
  <Name text={'Google'} basic={basic}>{children}</Name>