import { useContext } from 'react';
import { RefsContext } from 'providers';

export const useRefs = () => {
  const context = useContext(RefsContext);
  if (!context) {
    throw new Error('useRefs must be used within a RefsProvider');
  }
  return context;
}
