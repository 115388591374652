import {useEffect} from 'react';
import {
    ref as getStorageRef,
    uploadBytesResumable,
  } from 'firebase/storage';
import {
    updateDoc,
} from 'firebase/firestore';
import {useEventContext, useFirebaseContext, useRefs} from 'hooks';

const timeout: number = 20; //300; // in seconds

export const ScreenShot = () => {

    const {event} = useEventContext();
    const {getCdn} = useFirebaseContext();
    const {eventRef} = useRefs();

    useEffect(() => {
        setTimeout(capture, 5000);
        const intv = setInterval(capture, timeout * 1000);
        return () => {
            clearInterval(intv);
        }
    },[]);

    const capture = async () => {
        const video = document.querySelector('video') as HTMLVideoElement;
        if (!video) return;
        const canvas = document.createElement("canvas");
        if (!canvas) return;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas?.getContext("2d")?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const url = canvas.toDataURL('image/jpeg', 1.0)
        let file = await fetch(url).catch((err: any) => {
            console.error(`Error saving image: ${err.message}`);
            return;
        });
    
        if (!file) {
            console.error(`Error saving image`);
            return;
        }
        // Upload here
        try {
            let contentType = 'image/jpeg';
            let ext = 'jpg';

            let storage = getCdn!();

            const filename = `/captures/${event?.id}.${ext}`;

            const storageRef = getStorageRef(
                storage,
                filename,
            );

            const uploadTask = uploadBytesResumable(storageRef, await file.blob(), {
                contentType,
            });
        
            uploadTask.on(
                'state_changed',
                () => {},
                error => {
                    console.log(`Error uploading image: ${error.code}: ${error.message}`);
                    canvas.remove();
                },
                () => {
                    canvas.remove();
                    const url = `${process.env.REACT_APP_CDN}/captures/${event?.id}.jpg`;
                    updateDoc(eventRef!(event?.id || 'x'), { capture: url });
                });

        } catch (err) {
            console.log(err);
        }
    }

    return null;

}