/**
 * Generic schema validator for forms
 */

import { GenericObject } from "components";

export const validate = (data: any, schema: any): GenericObject | null => {
  const {error} = schema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
  });

  let errors: { [x: string]: any; } | null = null;

  (error?.details || []).forEach(({message = '', path = '', type = '', context = { key: '' }}) => {
    const {key} = context;

    if (!errors || !errors[key]) {
      if (!errors) {
        errors = {};
      }
      switch (type) {
        case 'any.empty':
          errors[key] = `"${key}" cannot be blank.`;
          break;
        default:
          errors[key] = message;
          break;
      }
    }
  });

  return errors;
};
