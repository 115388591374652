import {StripeText, AppName, Header, ExternalLink} from 'components';

export const Terms = () => (
  <>
    <p>
      Welcome to the{' '}<AppName />{' '}website (the "Website").
    </p>

    <p>
      <AppName />
      provides this Website and Services (located at{' '}
      <span className="website_url">{process.env.REACT_APP_FIREBASE_AUTHDOMAIN}</span>) to you
      subject to the notices, terms, and conditions set forth in these terms
      (the "Terms of Use"). In addition, when you use any of our Services, you
      will be subject to the rules, guidelines, policies, terms, and conditions
      applicable to such service, and they are incorporated into this Terms of
      Use by this reference.
    </p>

    <p>
      These Terms of Use are effective as of{' '}
      <span className="date">April 11, 2022</span>.
    </p>

    <p>
      Your eligibility for use of the Website is contingent upon meeting the
      following conditions:
    </p>

    <ul>
      <li>You are at least 13 years of age</li>
      <li>
        You use the Website and Services according to these Terms of Use and all
        applicable laws and regulations determined by the state and country of
        residence
      </li>
      <li>
        You provide complete and accurate registration information and maintain
        accurate registration information on the Webite
      </li>
      <li>
        You agree and understand that{' '}
        <span className="website_name">
          <AppName />
        </span>{' '}
        may, at any time, and without prior notice, revoke and/or cancel your
        access if you fail to meet these criteria or violate any portion of
        these Terms of Use
      </li>
    </ul>

    <Header as="h5">Use of this Website</Header>

    <p>
      In connection with your use of our Website, you must act responsibly and
      exercise good judgment. Without limiting the foregoing, you will not:
    </p>

    <ul>
      <li>
        Violate any local, state, provincial, national, or other law or
        regulation, or any order of a court
      </li>
      <li>
        Infringe the rights of any person or entity, including without
        limitation, their intellectual property, privacy, publicity or
        contractual rights
      </li>
      <li>
        Interfere with or damage our Services, including, without limitation,
        through the use of viruses, cancel bots, Trojan horses, harmful code,
        flood pings, denial-of-service attacks, packet or IP spoofing, forged
        routing or electronic mail address information or similar methods or
        technology
      </li>
      <li>
        Use automated scripts to collect information or otherwise interact with
        the Services or the Website
      </li>
      <li>
        Enter into this agreement on behalf of another person or entity without
        consent or the legal capacity to make such agreements as a
        representative of an organization or entity
      </li>
    </ul>

    <Header as="h5">
      Selling on <AppName />
    </Header>
    <p>
      If you setup a seller account on <AppName />, you agree to abide by the
      following terms of selling.
    </p>

    <Header as="h6">Prohibited Items</Header>
    <p>
      You may not use our Services to offer or otherwise engage in the sale or
      purchase of the following items:
    </p>
    <ul>
      <li>
        Any product or service that is Federally restricted (or restricted by
        your state/region) such as alcohol, tobacco and tobacco products,
        firearms (including parts), ammunition, explosives, illegal drugs and
        compounds
      </li>
      <li>Sexual services</li>
      <li>Any goods received illegally</li>
      <li>
        Any other products or services which are deemed legally questionable
      </li>
      <li>Products and services prohibited by our payment platform provider, <StripeText/>. A list of these can be found <ExternalLink asLink to="https://stripe.com/legal/restricted-businesses">here</ExternalLink>.</li>
    </ul>

    <Header as="h5">Payment and Fees</Header>
    <p>
      <AppName /> will deposit any monies collected from buyers for products
      sold directly to your payment processing account minus any selling fees.
    </p>
    <p>
      We use <StripeText/> to process payments and to protect your bank account and keep your information safe and secure.{' '}
      <AppName/> does <strong>not</strong> have access to your banking information.
    </p>
    <p>
      You, as the seller, are responsible for the following payment processing
      fees and charges collected by <StripeText />:
    </p>
    <ul>
      <li>Payment processing fees</li>
      <li>Transaction fees</li>
      <li>Refunds</li>
      <li>Chargebacks</li>
    </ul>
    <p>
      Your payment method on file will be used for any other expenses (such as
      account overages and event fees.)
    </p>

    <Header as="h5">Event Cancelation</Header>
    <p>
      We reserve the right to cancel any event we deem violates this agreement.
    </p>

    <Header as="h5">Intellectual Property</Header>

    <p>
      All code, text, software, scripts, graphics, files, photos, images, logos,
      and materials contained on this Website, or within the Services, are the
      sole property of{' '}
      <span className="website_name">
        <AppName />
      </span>
      .
    </p>

    <p>
      Unauthorized use of any materials contained on this Website or within the
      Service may violate copyright laws, trademark laws, the laws of privacy
      and publicity, and/or other regulations and statutes. If you believe that
      any of the materials infringe on any third party's rights, please contact{' '}
      <span className="website_name">
        <AppName />
      </span>{' '}
      immediately using our contact form.
    </p>

    <Header as="h5">Right to Use - Advertisting</Header>
    <p>
      <AppName /> reserves the right to use any item listed for sale or any
      event created on the system for advertisting and marketing purposes. All
      information will be anonymized where appropriate.
    </p>

    <Header as="h5">Third Party Websites</Header>

    <p>
      Our Website may link you to other sites on the Internet or otherwise
      include references to information, documents, software, materials and/or
      services provided by other parties. These websites may contain information
      or material that some people may find inappropriate or offensive.
    </p>

    <p>
      These other websites and parties are not under our control, and you
      acknowledge that we are not responsible for the accuracy, copyright
      compliance, legality, decency, or any other aspect of the content of such
      sites, nor are we responsible for errors or omissions in any references to
      other parties or their products and services. The inclusion of such a link
      or reference is provided merely as a convenience and does not imply
      endorsement of, or association with, the Website or party by us, or any
      warranty of any kind, either express or implied.
    </p>

    <Header as="h5">Disclaimer of Warranty and Limitation of Liability</Header>

    <p>
      The Website is provided "AS IS." <AppName />, its suppliers, officers,
      directors, employees, and agents exclude and disclaim all representations
      and warranties, express or implied, related to this Website or in
      connection with the Services. You exclude{' '}
      <span className="website_name">
        <AppName />
      </span>{' '}
      from all liability for damages related to or arising out of the use of
      this Website.
    </p>

    <Header as="h5">Changes to these Terms of Use</Header>

    <p>
      <span className="website_name">
        <AppName />
      </span>{' '}
      retains the right to, at any time, modify or discontinue, any or all parts
      of the Website without notice.
    </p>

    <p>
      Additionally,{' '}
      <span className="website_name">
        <AppName />
      </span>{' '}
      reserves the right, in its sole discretion, to modify these Terms of Use
      at any time, effective by posting new terms on the Website with the date
      of modification. You are responsible for reading and understanding the
      terms of this agreement prior to registering with, or using the Service.
      Your use of the Website and/or Services after any such modification has
      been published constitutes your acceptance of the new terms as modified in
      these Terms of Use.
    </p>

    <Header as="h5">Governing Law</Header>

    <p>
      These Terms of Use and any dispute or claim arising out of, or related to
      them, shall be governed by and construed in accordance with the internal
      laws of the <span className="country">United States of America</span>{' '}
      without giving effect to any choice or conflict of law provision or rule.
    </p>

    <p>
      Any legal suit, action or proceeding arising out of, or related to, these
      Terms of Use or the Website shall be instituted exclusively in the federal
      courts of the <span className="country">United States of America</span>.
    </p>
  </>
);

export default Terms;