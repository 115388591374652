import {DateTime} from 'luxon';

export const postDate = (created_time: number | string): DateTime => {
    try {
      return typeof created_time === 'string' 
        ? DateTime.fromISO(created_time)
        : typeof created_time === 'number' 
          ? DateTime.fromSeconds(created_time)
          : DateTime.utc()
    } catch (err: any) {
      console.error(`Error determing post time for ${created_time}: ${err.message}`, err);
    }
    return DateTime.utc();
}