import {
//    Header, 
    ContactUs, 
    AppName, 
//    FacebookText, 
//    YoutubeText, 
    StripeText,
    ButtonLink,
    IntlText,
} from 'components';
//import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
//import ArrowRightIcon from '@mui/icons-material/ArrowRight';
//import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'routes';
import EastIcon from '@mui/icons-material/East';


export const Pricing = () => (
    <>
        <Typography paragraph>
            With <AppName basic/>, pricing is simple!
        </Typography>

        <Typography paragraph>
            We charge a flat 6% commission for the total amount of sales generated by your event, and this amount includes payment processing fees.
        </Typography>

        <Typography paragraph>
            During your trial period, you'll pay only the fees charged by <StripeText basic/>, currently at 2% of the ticket price plus 30 cents per transaction.
        </Typography>

        <Typography paragraph>
            After your trial, the 6% fee goes into effect and a minimum fee of $4.95 per event is charged, which will typically be covered by the 6% fee.  If not, we'll charge your card on file for the difference.
        </Typography>

        <Box mt={1} mb={2}>
            <ButtonLink to={ROUTES.signup} variant="contained" color="primary" endIcon={<EastIcon/>}>
                Get Started Today!
            </ButtonLink>
        </Box>
     {/*
        <Header as="h5">How much does an event cost?</Header>
        <p>The cost of a live video event on <AppName/> varies depending on the streaming method used (see chart below for specific pricing):</p>
        <List>
            <ListItem alignItems="flex-start">
                <ListItemIcon sx={{ mt: '5px' }}>
                    <ArrowRightIcon/>
                </ListItemIcon>
                <ListItemText primary='All events are assessed a low hourly minimum fee (waived if less than sales fees.)'/>
            </ListItem>
            <ListItem alignItems="flex-start">
                <ListItemIcon sx={{ mt: '5px' }}>
                    <ArrowRightIcon/>
                </ListItemIcon>
                <ListItemText primary={<>Host (event presenter) video fee (unless video is being broadcast directly through <FacebookText/>.)</>}/>
            </ListItem>
            <ListItem alignItems="flex-start">
                <ListItemIcon sx={{ mt: '5px' }}>
                    <ArrowRightIcon/>
                </ListItemIcon>
                <ListItemText primary={<>Events that use <AppName/> Video for the audience (best quality and response time) are charged a per minute rate per viewer. (Does not apply if host video is broadcast directly through Facebook.)</>}/>
            </ListItem>
            <ListItem alignItems="flex-start">
                <ListItemIcon sx={{ mt: '5px' }}>
                    <ArrowRightIcon/>
                </ListItemIcon>
                <ListItemText primary={<>Events that use <FacebookText/> or <YoutubeText/> to broadcast video are not charged a per-viewer fee.</>}/>
            </ListItem>
        </List>
        <p>For all events, the following fees are assessed:</p>

        <Grid container spacing={2} mb={3}>
            <Grid item container>
                <Grid item xs={6} md={3}>
                    <strong>Base hourly rate:</strong>
                </Grid>
                <Grid item xs={6} md={9}>
                    $4.99
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={6} md={3}>
                    <strong>Event host video:</strong>
                </Grid>
                <Grid item xs={6} md={9}>
                    This is for the person presenting the event and is billed at <strong>0.015</strong> per minute (0.90 per hour.)
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={6} md={3}>
                    <strong>Audience:</strong>
                </Grid>
                <Grid item xs={6} md={9}>
                    These are the people watching your event and is billed at <strong>0.008</strong> per viewer per minute (0.48 per hour.) However, if your
                    event is being broadcast through <FacebookText /> or <YoutubeText />, <strong>this fee does NOT apply.</strong>
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={6} md={3}>
                    <strong>Sales Commission:</strong>
                </Grid>
                <Grid item xs={6} md={9}>
                    The sales commission is 5% of total sales generated by your event. The <strong>greater</strong> of this fee OR the base hourly rate X the number of hours of the event is billed.
                </Grid>
            </Grid>
        </Grid>
*/}
        <Box mb={3}>
            <Divider/>
        </Box>
        <p>We're happy to answer any questions you may have regarding our billing policies.  Please feel free to <ContactUs lower/>.</p>
    </>
)

export default Pricing;

