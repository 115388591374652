import {useEffect, useReducer, PropsWithChildren} from 'react';
import {isIOS} from 'react-device-detect';
import throttle from 'lodash/throttle';
import {reducer, debug} from 'lib';
import './style.css';
import {useSessionContext, useEventContext} from 'hooks';
import styled from '@emotion/styled';
import {Urls} from 'const';
import {PausedVideo, GenericObject} from 'components';
import ReactPlayer from 'react-player';
import {UserRoles,VideoStatus} from '@kwixl/interface';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const DEFAULT_STATE = {
  videoUrl: null,
  pip: false,
  playing: false,
  controls: true,
  light: false,
  volume: 0.8,
  muted: true,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  width: 0,
  height: 0,
  muteMessageShown: false,
  videoEnded: false,
  showPreRoll: false,
  iOSmessageShown: false,
  fbMessageShown: false,
  timeout: null,
};

interface Props extends PropsWithChildren<any> {
  url?: string;
  onSize?: ({ width, height }:{ width: number, height: number }) => void;
  fullScreen?: boolean;
  preview?: boolean;
}

export const VideoPlayer = ({
  url,
  onSize = () => {},
  fullScreen = false,
  preview = true,
}: Props) => {


  const {orientation, mobile, userProfile} = useSessionContext();

  const {event} = useEventContext();

  const [
    {
      videoUrl,
      playing,
      controls,
      volume,
      muted,
      width,
      height,
      muteMessageShown,
      videoEnded,
      showPreRoll,
      iOSmessageShown,
      fbMessageShown,
      timeout,
    },
    dispatch,
  ] = useReducer(reducer, DEFAULT_STATE);

  const isFacebook = (url || '').indexOf('facebook.com') > -1;

  useEffect(() => {
    debug('Optimized video effect')
    setVideoSize();
    window.addEventListener('resize', setVideoSize);
    setTimeout(() => {
      dispatch({fbMessageShown: true});
    }, 5000);
    //window.addEventListener('orientationchange', orientationChange);
    return () => {
      window.removeEventListener('resize', setVideoSize);
      //window.removeEventListener('orientationchange', orientationChange);
    };
  }, []);

  useEffect(() => {
    setVideoSize();
  }, [orientation]);

  useEffect(() => {
    if (timeout) {
      window.clearTimeout(timeout);
    }
    switch (event?.get('stream.status')) {
      case VideoStatus.paused:
        dispatch({muted: true, videoEnded: false, timeout: null});
        break;
      case VideoStatus.started:
        dispatch({videoEnded: false, timeout: null, playing: true});
        break;
      case VideoStatus.stopped:
        // failsafe 30 second end
        const intv = window.setTimeout(() => {
          dispatch({muted: true, videoEnded: true});
        }, 20000);
        dispatch({timeout: intv});
        break;
      default:
        dispatch({showPreRoll: true, muted: false, timeout: null});
        break;
    }
  }, [event]);

  useEffect(() => {
    const badUrl = !url || (url && !event?.get('stream.status'));
    dispatch({videoUrl: url, showPreRoll: badUrl});
  }, [url]);

  useEffect(() => {
    if (playing && muted && !muteMessageShown) {
      window.setTimeout(() => {
        dispatch({muteMessageShown: true});
      }, 10000);
    }
  }, [playing]);

  const setVideoSize = throttle(() => {
    let w = 0;
    let h = 0;
    try {
      const el = document.getElementById('video-column');
      if (el) {
        w = el.clientWidth; //rect.width;
        h = el.clientHeight;
      }
      //h = Math.ceil(w / (16 / 9));
      onSize({ width: w, height: h }); // + (admin ? 0 : 35));
    } catch (err: any) {
      console.error('Error getting video dimensions', err.message);
    }
    dispatch({width: w, height: h, loading: false});
  }, 500);

  const showVideo = () => {
    if (
      !videoUrl &&
      (!showPreRoll ||
        userProfile?.role === UserRoles.admin ||
        userProfile?.role === UserRoles.presenter)
    ) {
      return <PausedVideo />;
    }

    if (videoEnded) {
      return (
        <PausedVideo>
          <p>The video for this event has ended.</p>
        </PausedVideo>
      );
    }

    let urlToPlay = Urls.preRoll;
    let loop = showPreRoll;

    if (preview) {
      urlToPlay = videoUrl;
      loop = true;
    } else {
      switch (event?.get('stream.status')) {
        case VideoStatus.none:
          urlToPlay = Urls.preRoll;
          loop = true;
          break;
        case VideoStatus.paused:
          urlToPlay = Urls.paused;
          loop = true;
          break;
        case VideoStatus.started:
          urlToPlay = videoUrl || Urls.preRoll;
          break;
        default:
          urlToPlay =
            !event?.get('stream.status') || showPreRoll || !videoUrl
              ? Urls.preRoll
              : typeof videoUrl === 'object'
              ? videoUrl.url
              : url;
      }
    }
    
    const props: GenericObject = {};

    if (mobile) {
      props.height = '100%';
    }

    return (
      <ReactPlayer
        id="video-player"
        playsinline={true}
        controls={controls}
        pip={false}
        className="react-player"
        width={!fullScreen && width > 0 ? `${width}px` : '100%'}
        height={!fullScreen && height > 0 ? `${height}px` : '100%'}
        url={urlToPlay}
        playing={playing}
        volume={volume}
        loop={loop}
        muted={muted}
        config={{
          youtube: {
            playerVars: {
              autoplay: 1,
              controls: controls ? 1 : 0,
              fs: 0,
              modestbranding: 1,
              playsinline: 1,
            },
          },
          facebook: {
            appId: process.env.REACT_APP_FB_ID,
            playerId: 'fb-player',
          },
          vimeo: {
            playerOptions: {
              autoplay: true,
              muted: false,
              loop: loop,
              controls: false,
            },
          },
        }}
        onReady={() => {
          dispatch({
            fbMessageShown: false,
            //videoStatus: VideoStatus.started,
            playing: true,
          });
          //setVideoSize();
        }}
        onStart={() => {
          dispatch({
            //videoStatus: VideoStatus.started,
            playing: true,
          });
          //setVideoSize();
        }}
        onPlay={() => {
          dispatch({
            fbMessageShown: true,
            //videoStatus: VideoStatus.started,
            playing: true,
          });
          //setVideoSize();
        }}
        onError={err => {
          dispatch({playing: false});
        }}
        onProgress={({played, playedSeconds, loaded, loadedSeconds}) => {
          if (
            Math.ceil(playedSeconds) >= Math.floor(loadedSeconds) &&
            event?.get('stream.status') === VideoStatus.stopped
          ) {
            dispatch({muted: true, videoEnded: true});
          }
        }}
        onPause={() => {
          dispatch({
            //videoStatus: VideoStatus.paused,
            playing: false,
          });
        }}
        onBuffer={() => {
          //dispatch({ videoStatus : VIDEO_STATUS.BUFFERING });
        }}
        onBufferEnd={() => {
          if (!playing) {
            dispatch({
              //videoStatus: VideoStatus.started,
              playing: true,
            });
          }
        }}
        onEnded={() => {
          dispatch({
            playing: false,
            //videoStatus: VideoStatus.stopped
          });
        }}
      />
    );
  };

  return (
    <VideoContainer id="video-container">
      <VideoWrapper id="video-wrapper">
        {showVideo()}
        {/*event?.get('stream.status') !== VideoStatus.none &&
          !showPreRoll &&
          videoUrl &&
          muted &&
          !muteMessageShown &&
          playing && (
            <MutedMessage
              onClick={e => {
                e.preventDefault();
                dispatch({muted: false, muteMessageShown: true});
              }}
            >
              <VolumeOffIcon id="mute"/> Click to unmute
            </MutedMessage>
            )*/}
      </VideoWrapper>
      {isIOS && !iOSmessageShown && (url || '').indexOf('facebook.com') > -1 && (
        <Dialog maxWidth="sm" open={!iOSmessageShown}>
          <DialogTitle textAlign='center'>
            -= Notice for iOS Users =-
          </DialogTitle>
          <DialogContent>
            <p>
              The video may go to full screen mode when playing. Simply{' '}
              <b>pinch the video</b> and it will return to play in place.
            </p>
            <p>
              <b>
                <i>
                  We apologize for this slight inconvenience, this is a designed
                  behavior by Facebook on iOS.
                </i>
              </b>
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => dispatch({iOSmessageShown: true})}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {!isIOS && isFacebook && !fbMessageShown && (
        <FBClickNotice>
          <div style={{width: '82%', textAlign: 'center', margin: '0 auto'}}>
            If the video does not start automatically,{' '}
            {mobile ? 'tap' : 'click'} the video to start playback
          </div>
        </FBClickNotice>
      )}
    </VideoContainer>
  );
};

export const OptimizedVideo = VideoPlayer; //React.memo(VideoPlayer, isEqual);

const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
  flex: 1;
  margin: 0 auto;
`;

//${/*props => props.mobile ? `flex-grow: 1; order: 1; align-items: stretch;max-height:${props.height}px;` : ''*/}
const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const FBClickNotice = styled.div`
  text-align: center;
  position: absolute;
  top: 50px;
  left: 0;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  height: 75px;
  width: 100%;
  z-index: 500;
  -webkit-text-stroke: 1px black;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
    background-color: #000000;
    top: 90px;
  }
`;


