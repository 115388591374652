import { Model, validator, TransformValidateDateTime } from '../Classes';
import {DateTime} from 'luxon';

export class Notification extends Model<Notification> {

    @validator.IsString()
    subject: string;

    @validator.IsString()
    message: string;

    @TransformValidateDateTime()
    createdAt: DateTime;
    
    @validator.IsString()
    @validator.IsOptional()
    uid?: string;

    @validator.IsNumber()
    @validator.IsOptional()
    orgId?: string;

    @validator.IsString()
    @validator.IsOptional()
    type?: string;

    @validator.IsObject()
    @validator.IsOptional()
    meta?: { [key:string]:any } = {}

    @TransformValidateDateTime()
    @validator.IsOptional()
    readAt?: DateTime;

    @TransformValidateDateTime()
    @validator.IsOptional()
    deletedAt?: DateTime;

    @TransformValidateDateTime()
    @validator.IsOptional()
    deletedBy?: string;

    @TransformValidateDateTime()
    @validator.IsOptional()
    sentAt?: DateTime;
}
