import { Loading } from 'components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { ReactNode } from 'react';

export const LoadingModal = ({ children = 'Please wait...' }: { children?: ReactNode}) => {
    return (
        <Dialog open={true} fullWidth maxWidth='sm'>
            <DialogContent>
                <Loading>
                    {children}
                </Loading>  
            </DialogContent>
        </Dialog>
    )
}