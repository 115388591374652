import { NumericFormat } from "react-number-format";
//import { NumericFormatProps } from "react-number-format/types";
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export const CurrencyInput = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      decimalScale={2}
      // isNumericString
    />
  );
}

export const CurrencyField = (props: TextFieldProps) => 
    <TextField 
        { ...props }
        InputProps={{
            inputComponent: CurrencyInput,
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon/>
              </InputAdornment>
            )
        }}
    />

