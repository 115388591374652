import {useEffect, useReducer, useCallback, useRef} from 'react';
import throttle from 'lodash/throttle';
import {reducer, debug} from 'lib';
import './style.css';
import {useSessionContext, useVideoContext, useEventContext} from 'hooks';
import styled from '@emotion/styled';
import {VideoStatus} from '@kwixl/interface';
import {PausedVideo} from 'components';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

interface Props {
  onSize?: ({ height, width }:{ height: number, width: number }) => void;
  fullScreen?: boolean;
}

export const KwixlVideo = ({onSize = () => {}, fullScreen = false}: Props) => {

  const {orientation} = useSessionContext();

  const {
    remoteVideoTrack, 
    videoClient, 
    join,
    leave,
    getVideoClient,
  } = useVideoContext();

  const {
    event,
  } = useEventContext();

  const [
    {
      videoUrl = null,
      playing = false,
      muted = true,
      width = 0,
      height = 0,
      muteMessageShown = false,
      showPreRoll = false,
      timeout = null,
    },
    dispatch,
  ] = useReducer(reducer, {});

  const loadingClient = useRef<boolean>(false);

  // page load
  useEffect(() => {
    (async () => {
      setVideoSize();
      await initVideo();
      window.addEventListener('resize', setVideoSize);
      //window.addEventListener('orientationchange', orientationChange);
      return async () => {
        window.removeEventListener('resize', setVideoSize);
        //window.removeEventListener('orientationchange', orientationChange);
        await leave!();
      };
    })();
  }, []);

  useEffect(() => {
    playVideo();
  }, [remoteVideoTrack]);

  useEffect(() => {
    setVideoSize();
  }, [orientation]);

  useEffect(() => {
    (async () => {
      debug('Event effect', 'stream staus', event?.get('stream.status'));
      if (timeout) {
        window.clearTimeout(timeout);
      }
      switch (event?.get('stream.status')) {
        case VideoStatus.paused:
          dispatch({muted: true, timeout: null});
          break;
        case VideoStatus.started:
          dispatch({timeout: null});
          playVideo();
          break;
        case VideoStatus.stopped:
          dispatch({muted: true});
          break;
        default:
          dispatch({showPreRoll: true, muted: false, timeout: null});
          break;
      }
    })()
  }, [event]);

  useEffect(() => {
    if (playing && muted && !muteMessageShown) {
      window.setTimeout(() => {
        dispatch({muteMessageShown: true});
      }, 10000);
    }
  }, [playing]);

  const playVideo = useCallback(() => {
    if (!remoteVideoTrack) return;
    if (remoteVideoTrack.isPlaying) return;
    remoteVideoTrack.play('player', {
      fit: 'cover',
      mirror: false,
    });
  },[remoteVideoTrack]);

  const initVideo = async () => {
    if (loadingClient.current) return;
    loadingClient.current = true;
    if (!videoClient) {
      await getVideoClient!().then(async () => {
        await join!('audience')
      });
    } else if (videoClient && !['CONNECTED', 'CONNECTING'].includes(videoClient.connectionState)) {
      await join!('audience');
    }
    loadingClient.current = false;
  }

  const setVideoSize = throttle(() => {
    let w = 0;
    let h = 0;
    try {
      const el = document.getElementById('video-column');
      if (el) {
        w = el.clientWidth; //rect.width;
        h = el.clientHeight;
      }
      //h = Math.ceil(w / (16 / 9));
      onSize({ width: w, height: h }); // + (admin ? 0 : 35));
    } catch (err: any) {
      console.error('Error getting video dimensions', err.message);
    }
    dispatch({width: w, height: h, loading: false});
  }, 500);

  debug('VIDEO', event?.get('stream.status'));
  debug('Remote video track', remoteVideoTrack);

  return (
    <VideoContainer id="video-container">
      <VideoWrapper id="video-wrapper">
        <div
          id="player"
          style={{
            width: fullScreen ? '100%' : `${width}px`,
            height: fullScreen ? '100vh' : `${height}px`,
          }}
        >
          {(() => {
            if (!remoteVideoTrack) {
              switch (event?.get('stream.status')) {
                case VideoStatus.paused:
                  return (
                    <PausedVideo>
                      Video has been paused by presenter...
                    </PausedVideo>
                  );
                case VideoStatus.stopped:
                  return <PausedVideo>Video has ended...</PausedVideo>;
                default:
                  return (
                    <PausedVideo>Waiting for video to start...</PausedVideo>
                  );
              }
            } else {
              switch (event?.get('stream.status')) {
                case VideoStatus.none:
                  return (
                    <PausedVideo>Waiting for video to start...</PausedVideo>
                  );
                case VideoStatus.stopped:
                  return <PausedVideo>Video has ended...</PausedVideo>;
                case VideoStatus.paused:
                  return (
                    <PausedVideo>
                      Video has been paused by presenter...
                    </PausedVideo>
                  );
                default: {
                  return (
                    !showPreRoll &&
                    videoUrl &&
                    muted &&
                    !muteMessageShown &&
                    playing && (
                      <MutedMessage
                        onClick={e => {
                          e.preventDefault();
                          dispatch({muted: false, muteMessageShown: true});
                        }}
                      >
                        <VolumeOffIcon id="mute"/> Click to
                        unmute
                      </MutedMessage>
                    )
                  );
                }
              }
            }
          })()}
        </div>
      </VideoWrapper>
    </VideoContainer>
  );
};

/*
const isEqual = (prev, next) => {
  return prev && next && prev.url === next.url && prev.status === next.status;
};

export const OptimizedVideo = React.memo(VideoPlayer, isEqual);
*/

const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: auto;
  min-height: 200px;
  width: 100%;
  flex: 1;
  margin: 0 auto;
`;

const MutedMessage = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  max-width: 50%;
  height: 40px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 5px;
  font-size: 1.25em;
  line-height: 1.5em;
  z-index: 5;
  @media only screen and (min-width: 769px) {
    bottom: 100px;
  }
`;

const VideoContainer = styled.div`
  overflow: hidden;
  min-height: 200px;
  height: 100%;
  width: 100%;
`;
