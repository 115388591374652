import { useReducer, useEffect } from 'react';
import { reducer, flash } from 'lib';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {AlertBox, NextButton, BackButton, Loading} from 'components';
import {
  GenericObject,
} from 'components';
import InputLabel from '@mui/material/InputLabel';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useFacebookContext, useRefs, useSessionContext } from 'hooks';
import { serverTimestamp, setDoc } from 'firebase/firestore';

export const SelectPage = ({ 
    value,
    live = false,
    onNext,
    onBack,
}:{ 
    value?: GenericObject;
    live?: boolean;
    onNext: (page: GenericObject) => void;
    onBack: () => void;
}) => {

    const { 
        fbPages, 
        fbPagesLoading,
        fbLongLivedToken,
    } = useFacebookContext();

    const {
        organization,
    } = useSessionContext();

    const {
        organizationFacebookPageRef,
    } = useRefs();

    const [{
        page,
    }, dispatch] = useReducer(reducer, {});

    useEffect(() => {
        dispatch({ page: value });
    },[value]);

    const handleNext = async () => {
        // Save page info to db 
        if (!organizationFacebookPageRef || !organization?.id) {
            flash.error(`Invalid configuration.  Cannot save page!`);
            return;
        }
        await setDoc(
            organizationFacebookPageRef!(organization.id, page.id),
            { 
                ...page,
                page_token: page.access_token,
                access_token: fbLongLivedToken,
                updatedAt: serverTimestamp(),
            },
            {
                merge: true,
            }
        )
        onNext(page);
    }

    const pages = fbPages || [];

    if (fbPagesLoading) {
        return (
            <>
                <DialogContent dividers>
                    <Loading>Loading pages...</Loading>
                </DialogContent>
            </>
        )
    }
    
    return (
        <>
            <DialogContent dividers>
                {!pages?.length && (<AlertBox severity="error">No pages were found.</AlertBox>)}
                {pages?.length > 0 && (
                    <>
                        <p>Select a page {live ? 'where your live video is located' : 'to broadcast your video to'}.</p>
                        {pages?.length > 0 && (
                            <FormControl fullWidth>
                                <InputLabel>Pages You Manage</InputLabel>
                                <Select
                                    label="Pages You Manage"
                                    value={page?.id || ''}
                                    onChange={(e: SelectChangeEvent) => {
                                        dispatch({ page: pages.find(p => p.id === e.target.value) });
                                    }}
                                >
                                    {pages.map(({id, name}: GenericObject) => (
                                    <MenuItem value={id}>{name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <BackButton sx={{ position: 'absolute', left: '10px' }} onClick={onBack}/>
                <NextButton onClick={handleNext} disabled={!page?.id}/>
            </DialogActions>
        </>
    );

}