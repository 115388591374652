export const Sound = ({ 
    id = 'default-sound',
    file = 'none'
}) => {

    return (
        <audio id={id}>
            {['ogg', 'wav', 'mp3'].map(ext => 
                <source src={`${process.env.REACT_APP_STATIC_CDN}/sounds/${file}.${ext}`} type={`audio/${ext}`} />
            )}
        </audio>
    )

}