import {useEffect, useRef, useReducer} from 'react';
import {useEventContext, useVideoContext} from 'hooks';
import {ScreenShot, StatusBar, CameraControl, Countdown} from './components';
import throttle from 'lodash/throttle';
import {reducer, debug} from 'lib';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { ConfirmModal } from 'modals';
import Stack from '@mui/material/Stack';

import {
  PausedMessage,
} from './styled';
import {VideoStatus} from '@kwixl/interface';
import {ROUTES} from 'routes';

const initialState = {
  ready: false,
  height: 0,
  width: 0,
  isPreview: false,
};

export const Desktop = ({
  preview = false,
  onEvent = () => {},
  onSize = () => {},
}:{
  preview?: boolean;
  onEvent?: (status?: string) => void;
  onSize?: ({ width, height }:{ width: number, height: number }) => void;
}) => {

  const {
    videoClient,
    localVideoTrack,
    videoStatus,
    stopClient,
    stopStreaming,
    videoDevices,
  } = useVideoContext();

  console.log('VIDEO DEVICES', videoDevices);

  const {
    event, 
    eventEnded, 
    hostId,
  } = useEventContext();

  const [{
    ready, 
    isPreview,
    confirmOpen = false,
    starting = false,
  }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const videoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('resize', setVideoSize);
    setVideoSize();
    return () => {
      window.removeEventListener('resize', setVideoSize);
      //if (videoStatus === VideoStatus.started) {
      //  stopClient(true);
      //}
      if (hostId === event?.get('hostId')) {
        stopStreaming!(true, ROUTES.cp_event);
      } else {
        stopClient!(true);
      }
    };
  }, []);

  useEffect(() => {
    debug('Broadcast video effect', eventEnded(), videoClient ? 'client initialized' : 'no client');
    if (!eventEnded() && videoClient) dispatch({ ready: true });
  },[videoClient, event]);

  useEffect(() => {
    onEvent(videoStatus);
}, [videoStatus]);

  useEffect(() => {
    dispatch({isPreview: preview});
  }, [preview]);

  useEffect(() => {
    if (!localVideoTrack) return;
    initVideo();
  }, [localVideoTrack]);

  const initVideo = async () => {
    try {
      await localVideoTrack?.play('video-ref', {
        fit: 'cover',
        mirror: false,
      });
      setVideoSize();
      //toggleMic(false);
    } catch (err) {
      console.error('Error playing video', err);
    }
  };

  const setVideoSize = throttle(() => {
    let w = 0;
    let h = 0;
    try {
      const el = document.getElementById('video-column');
      if (el) {
        w = el.clientWidth; //rect.width;
        h = el.clientHeight;
      }
      //h = Math.ceil(w / (16 / 9));
      onSize({ width: w, height: h });
    } catch (err: any) {
      console.error('Error getting video dimensions', err.message);
    }
    if (videoRef && videoRef.current) {
      videoRef.current.style.height = `${h}px`; // settings.height;
      videoRef.current.style.width = `${w}px`; // settings.width;
    }
    dispatch({width: w, height: h, loading: false});
  }, 500);

  const handleStop = () => {
    dispatch({ confirmOpen: true });
  }

  debug('Desktop video');

  return (
    <>
    <Box 
      id="desktop-video"
      height='100%'
      width='100%'
      sx={{
        minHeight: '100px',
        position: 'relative',
      }}
    >
      {!isPreview && !eventEnded() && 
        <StatusBar
          onExit={() => {}}
        />
      }
      
      {videoStatus === VideoStatus.paused && (
          <PausedMessage>
            Video has been paused for your viewers.
          </PausedMessage>
        )}
      {/*<ChatScreen/>*/}
      {confirmOpen && (
        <ConfirmModal
          open={confirmOpen}
          title="Stop Video"
          content="Are you sure you wish to stop the video?"
          onConfirm={() => {
            dispatch({ confirmOpen: false });
            stopStreaming!();
          }}
        />
      )}
    </Box>
    { starting && (
      <Countdown 
        open={starting}
        onDone={() => dispatch({ starting: false })}
      />
    )}
    <Box 
      id="video-ref" 
      ref={videoRef}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        overflow: 'hidden',
        width: '100%',
        height: '100%',
      }}
    />
    {!eventEnded() && (
        <Box 
          id='camera-control-container'
          sx={{ 
            position: 'absolute',
            right: `15px`,
            top: '7px',
            width: '60px',
            zIndex: 3,
            backgroundColor: 'rgba(0,0,0,.3)',
            borderRadius: '5px',
          }}
          zIndex={5}
        >
          {!isPreview && !!videoRef.current && ( 
              <Stack direction="column" spacing={1} id="camera-control" justifyContent='flex-start'>
                <CameraControl 
                  onStart={() => {
                    dispatch({ starting: true })
                  }}
                  onStop={() => handleStop()}
                />
              </Stack>
          )}
      </Box>
    )}
    {!ready && !eventEnded() && (
      <Dialog maxWidth="sm" open={!ready}>
        <DialogContent>Initializing camera...please wait...</DialogContent>
      </Dialog>
    )}
    <ScreenShot/>
    </>
  );
};