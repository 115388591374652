import {useState, useEffect} from 'react';
import {Editor as TinyMCE} from '@tinymce/tinymce-react';

interface Props {
  initialValue?: string;
  height?: number;
  html?: boolean;
  onChange?: (value: string) => void;
}

export const Editor = (props: Props) => {
  const {
    initialValue = '',
    height = 200,
    html = true,
    onChange = () => {},
  } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const config = html
    ? {
        height,
        menubar: false,
        plugins: [
          'advlist autolink lists link image',
          'charmap print preview anchor help',
          'searchreplace visualblocks code',
          'insertdatetime media table paste',
        ],
        toolbar:
          'undo redo | formatselect | fontsizeselect | fontsize | sizeselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help',
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        fontsize_formats:
          '8pt 10pt 12pt 14pt 18pt 20pt 22pt 24pt 26pt 28pt 36pt 42pt 50pt 56pt 60pt 72pt 94pt 96pt',
      }
    : {
        height,
        toolbar: 'undo redo emoticons',
        plugins: ['emoticons'],
        menubar: false,
        statusbar: false,
      };
  return (
    <TinyMCE
      apiKey={process.env.REACT_APP_TINY_MCE_KEY}
      value={value}
      init={config}
      onEditorChange={content => {
        setValue(content);
        onChange(content);
      }}
    />
  );
};
