import {useState} from 'react';
import {useFirebaseContext, useSessionContext, useRefs} from 'hooks';
import {unlink} from 'firebase/auth';
import {ConfirmModal} from 'modals';
import {flash} from 'lib';
import {deleteDoc} from 'firebase/firestore';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { UserAvatar } from './UserAvatar';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import CancelIcon from '@mui/icons-material/Cancel';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

export const DebugBar = () => {

  const [hidden, setHidden] = useState(false);
  
  const {
    auth,
    firebaseUser, 
  } = useFirebaseContext();

  const {
    demo,
    userProfile, 
    organization,
  } = useSessionContext();

  const {
    facebookUserRef,
  } = useRefs();

  const unlinkProvider = async (providerId: string, uid: string) => {
    if (!auth?.currentUser) return;
    try {
      await unlink(auth?.currentUser, providerId);
      await deleteDoc(facebookUserRef!(uid));
      flash.success('Provider unlinked');
      window.location.reload();
    } catch (err: any) {
      flash.error(`Error unlinking provider: ${err.message}`);
    }
  }

  const hide = () => {
    setHidden(true);
  }
  
  const bar = () => {
    return (
      <Box 
          component="div" 
          sx={{
              width: '100%',
              display: { xs: 'none', md: 'block' },
              backgroundColor: theme => theme.palette.secondary.main,
              color: '#ffffff',
          }}
      >
        <Stack direction="row" spacing={2} justifyContent='space-between' sx={{ pl: 1, pr: 1, pt: '5px'} }>
          <Box>v{process.env.REACT_APP_VERSION}</Box>
          <Box>{firebaseUser?.displayName} ({userProfile?.role})</Box>
          <Box>UID: {firebaseUser?.uid}</Box>
          <Box>
              Org: {organization?.id} - {organization?.name}
          </Box>
          {firebaseUser?.providerData.map(provider => {
            return (
              <Box>
                  {(() => {
                  switch (provider.providerId) {
                      case 'facebook.com':
                      return <ConfirmModal
                          title="Unlink Facebook?" 
                          content={
                          <>
                              <p>Do you wish to unlink your Facebook account?</p>
                              {provider.displayName && <p>Name: {provider.displayName}</p>}
                              {provider.email && <p>E-mail: {provider.email}</p>}
                              {provider.phoneNumber && <p>Phone: {provider.phoneNumber}</p>}
                              {provider.photoURL && <UserAvatar src={provider.photoURL}/>}
                          </>
                          }
                          trigger={<FacebookIcon/>}
                          onConfirm={() => unlinkProvider(provider.providerId, provider.uid)}
                      />
                      case 'google.com':
                      return <GoogleIcon/>
                      case 'phone':
                      return <SmartphoneIcon/>
                      case 'password':
                      return <AlternateEmailIcon/>
                    }
                })()}
              </Box>
            )
          })}
          <Box>
            <CancelIcon onClick={hide}/>
          </Box>
        </Stack>
      </Box>
    );
  };

  return !hidden && process.env.REACT_APP_STAGE !== 'production' && !demo && firebaseUser
    ? bar()
    : null;
};