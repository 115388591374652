import React, {useReducer, useEffect} from 'react';
import {reducer, flash} from 'lib';
import {useFirebaseContext, useRefs} from 'hooks';
import {
  FacebookAuthProvider,
  signInWithPopup,
  linkWithPopup,
  getAdditionalUserInfo,
  UserCredential,
  AuthError,
} from 'firebase/auth';
import {
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import {GenericObject} from 'components';
import { DateTime } from 'luxon';

declare global {
  interface Window {
    FB: any;
  }
}

export interface FacebookLoginResponse {
  userID: string;
  loginToken: string;
}

export interface PagesAndGroupsResponse {
  userID?: string;
  accessToken?: string;
  pages?: GenericObject[];
}

export interface IFacebookContext {
  fbLongLivedToken: string;
  fbUserId: string;
  fbAccessToken: string;
  fbPages: GenericObject[];
  fbPagesLoading: boolean;
  basicPermissions: string[];
  extendedPermissions: string[];
  facebookLogin: (permissions?: string[]) => Promise<GenericObject | undefined>;
  getFacebookPages: () => Promise<PagesAndGroupsResponse | undefined>;
  filterEmbedHtml: (html: string) => string;
  signInWithFacebook: (onError?: any) => Promise<any>;
  linkFacebookAccount: (permissions?: string[]) => Promise<any>;
  expires: DateTime;
}

const initialState = {
  fbLongLivedToken: '',
  fbAccessToken: '',
  fbUserId: '',
  fbPages: [],
  fbPagesLoading: false,
  loggingIn: false,
};

export const FacebookContext =
  React.createContext<Partial<IFacebookContext>>(initialState);
export const FacebookConsumer = FacebookContext.Consumer;

interface FacebookProviderProps {
  children?: any;
}

export const basicPermissions = [
  'public_profile',
  'email',
]

export const extendedPermissions = basicPermissions.concat([
  'publish_video',
  'pages_show_list',
  'pages_manage_posts',
  'pages_read_engagement',
  'pages_read_user_content',
  //"publish_to_groups",
  //"groups_access_member_info",
  //'user_videos',
  // new for messaging
  'pages_messaging',       
  'pages_manage_metadata', 
  'pages_manage_engagement',
]);

export const FacebookProvider = ({children}: FacebookProviderProps) => {

  const {
    callable, 
    auth,
    firebaseUser,
  } = useFirebaseContext();

  const {
    userProfileRef,
    userPrivateFacebookRef, 
  } = useRefs();
  
  const [{
    fbLongLivedToken, 
    fbAccessToken,
    fbUserId, 
    fbPages,
    fbPagesLoading,
    loggingIn,
    expires = DateTime.utc().plus({ day: -1 }),
  }, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    (async () => {
      if (!firebaseUser?.uid || !userPrivateFacebookRef) return;
      try {
        const doc = await getDoc(userPrivateFacebookRef(firebaseUser.uid));
        if (doc?.exists()) {
          dispatch({ 
            fbUserId: doc.get('userID'), 
            fbLongLivedToken: doc.get('longLivedToken'),
            expires: doc.get('expires') ? DateTime.fromJSDate(doc.get('expires').toDate()) : DateTime.utc().plus({ day: -1 }),
          })
        }
      } catch (err: any) {
      }
    })();
  },[firebaseUser, userPrivateFacebookRef]);

  const filterEmbedHtml = (html: string) => {
    let value = html.substr(html.indexOf('href=') + 5);
    value = decodeURIComponent(value.substr(0, value.indexOf('" width')));
    if (value.indexOf('&width') > -1) {
      value = value.substr(0, value.indexOf('&width'));
    }
    if (value.indexOf('"') > -1) {
      value = value.substr(0, value.indexOf('"'));
    }
    return value;
  };

  const handleLoginResult = async (result: UserCredential, provider: FacebookAuthProvider) => {
    const profile = await getDoc(userProfileRef!(result.user.uid || 'x'));
    if (!profile.exists()) {
      try {
        const [firstName, lastName] = (result.user.displayName || '').split(
          ' '
        );
        let verified = result.user.email ? true : false;
        const data = {
          firstName,
          lastName,
          createdAt: serverTimestamp(),
          orgId: result.user.uid,
          verified,
          photoURL: result.user.photoURL,
        };
        await setDoc(userProfileRef!(result.user.uid), data, { merge: true });
      } catch (err: any) {
        flash.error(`Error: ${err.message}`);
        return;
      }
    }
    return result;
  };

  const signInWithFacebook = async (onError: (err: any) => {}) => {
    let result: UserCredential | AuthError;
    const provider = new FacebookAuthProvider();
    provider.setCustomParameters({
      'config_id': process.env.REACT_APP_FACEBOOK_LOGIN_PROFILE || '',
    });
    try {
      result = await signInWithPopup(auth!, provider);
      await handleLoginResult(result, provider);
      const details = getAdditionalUserInfo(result);
      const token = await result.user.getIdToken();
      return { result, details, token };
    } catch (err: any) { 
      onError(err);
      window.scrollTo(0, 0);
    }
  }

  const facebookLogin = async (permissions = basicPermissions) => {
    if (loggingIn) return;
    dispatch({ loggingIn: true });
    if (fbUserId && fbLongLivedToken && expires.toMillis() > DateTime.utc().toMillis()) {
      return {
        userID: fbUserId,
        token: fbLongLivedToken,
      }
    }
    const p: Promise<FacebookLoginResponse | void> = new Promise((resolve, reject) => {
      try {
        window.FB.login(
          (response: FacebookLoginResponse | void) => resolve(response)
          /*
            try {
              const {
                authResponse: {accessToken, userID},
              } = response;
              resolve({ userID, loginToken: accessToken});
            } catch(err: any) {
              //flash.error(`Error logging in to Facebook: ${err.message}`);
              resolve();
            } finally {
              dispatch({loggingIn: false});
            }
          }*/,
          {
            configId: process.env.REACT_APP_FACEBOOK_LOGIN_PROFILE,
            scope: permissions.join(','),
          }
        );
      } catch (err: any) {
        reject(err);
      } finally {
        dispatch({loggingIn: false});
      }
    });
    const {userID, loginToken} = await p
      .then((response: GenericObject | void) => {
        if (!response) return {};
        try {
          /**
          * accessToken: "EAAJZBi6XKwuQBO0syu5hnjbXWMDDFkT0aLvbczrLI2m89h37kYfFh4ikeywoZCVvp6E1KRjc3C9siZC4YpUGOBYR3DChIoYIqZB5RTFTjvysCusF1d2rrz7LvmAb5TDrPMkqH2ZAj10bQPRA9lQTgazco1UW3HqoHdc7WZBYjXRdhOfi8ATgyXrJxZCrkCyIeC39FLy4VFuZCedMx8tY"
          * data_access_expiration_time: 1731199805
          * expiresIn: 4195
          * graphDomain: "facebook"
          * signedRequest: "2YfX4C4t-d6FfGezoD3PRex_PRbnfH715FSUx1Vgpok.eyJ1c2VyX2lkIjoiMTAyMTc5MzU1NTE3MTQ1NzYiLCJjb2RlIjoiQVFDS1lYbi1ob240WEx1TVVKeEFaUzJRWVdnLV9zTUJfWUJLM1IzeW9pSlZtTVFfdnVQb295OVVUZUJBU1NtU2JibkxmNHYxdTFzVDJ4TVZjN0ItV0gxV2EzX09RVlA0RzBpZ1FyZVBPV1RKR2V6dE9ZZDVmSS1QeUFOMnhZMkFjX09QbVl2bkZKNFBEdDNyM1doZlJSTm5wZVQxeWFmbjVNZTFSQTRlZ0VOaGo1TjViTS1ZTHhLMGpSYWk1S2V5OUVlMUFLWDdEZGJZclNkYVJadThhdnZHNXpFenhtU1VsUE1USERPMFRMMWhuS0ptb29UOUwteXFDMUplY1RvYlkxZVJkYjJlaGRJT2o3U0tEMkV0QlE1TzUwWXhRTGhpbVRQOWt6UnFGRDhCU1BXbzBvWjJNN2VmM0g4TTBvZnBqaWotVVV1V21Dd0ctVnA2VDM5WkhCTDctVEV2UF9qNzVyOXA1LU4xU3FidHVqb05UYTFnbFNNTHlOcW1idXJDank0IiwiYWxnb3JpdGhtIjoiSE1BQy1TSEEyNTYiLCJpc3N1ZWRfYXQiOjE3MjM0MjM4MDV9"
          * userID: "10217935551714576"
          */
          const {
            authResponse: {
              accessToken, 
              userID,
            },
          } = response;
          return { userID, loginToken: accessToken};
        } catch(err: any) {
          return {};
        }
      })
      .catch(console.log) || {};
    if (!userID || !loginToken) return;
    // exchange for long-lived user token
    const { data } = await callable!('facebook-exchangeToken', {token: loginToken});
    if (!data?.token) throw new Error('Invalid token');
    dispatch({ 
      fbUserId: userID, 
      fbLongLivedToken: data?.token, 
      expires: data?.expires 
    });
    // save to Firebase 
    try {
      await setDoc(userPrivateFacebookRef!(firebaseUser!.uid), { 
        userID, 
        createdAt: serverTimestamp(),
        longLivedToken: data?.token || '',
        expires: DateTime.fromMillis(data.expires).toJSDate(),
      },{
        merge: true,
      });
    } catch (err: any) {
      console.log('Error saving FB login:', err.message);
    }
    return { userID, token: data?.token, expires: data?.expires }
  }

  const getFacebookPages = async (): Promise<PagesAndGroupsResponse | undefined> => {

    if (fbUserId && fbLongLivedToken && fbPages?.length > 0) {
      return {userID: fbUserId, accessToken: fbLongLivedToken, pages: fbPages };
    }

    dispatch({ fbPagesLoading: true });

    const { userID = '', token = '' } = await facebookLogin(extendedPermissions) || {};

    if (!token || !userID) return;

    const p: Promise<PagesAndGroupsResponse> = new Promise((resolve) => {
      try {
        window.FB.api(
          `/${userID}/accounts?fields=name,access_token`,
          'GET',
          {
            access_token: token,
          },
          (result: GenericObject) => {
            const payload: PagesAndGroupsResponse = {
              userID,
              pages: result.data,
              accessToken: token,
            };
            dispatch({
              fbPages: result.data || [],
              fbPagesLoading: false,
            });
            resolve(payload);
          }
        );
      } catch (err: any) {
        flash.error(`Error getting Facebook pages: ${err.message}`);
        resolve({});
        dispatch({ fbPagesLoading: false });
      }
    });
    const result: PagesAndGroupsResponse = await p;
    return result;
  };

  const linkFacebookAccount = async (permissions = basicPermissions) => {
    if (!auth?.currentUser) return;
    try {
      const provider = new FacebookAuthProvider();
      for (const p of permissions) {
        provider.addScope(p);
      }
      const result = await linkWithPopup(auth.currentUser, provider);
      return result;
      //handleLoginResult(result, provider);
    } catch (err: any) {
      console.log(err);
      window.scrollTo(0, 0);
      throw(err);
    }
  }

  return (
    <FacebookContext.Provider
      value={{
        facebookLogin,
        getFacebookPages,
        filterEmbedHtml,
        fbUserId,
        fbAccessToken,
        fbLongLivedToken,
        fbPages: fbPages || [],
        signInWithFacebook,
        linkFacebookAccount,
        basicPermissions,
        extendedPermissions,
        fbPagesLoading,
      }}
    >
      {children}
    </FacebookContext.Provider>
  );
};

