import { createContext, useState, useEffect, ReactNode } from "react";
import { DateTime } from "luxon";
import { getDatabase, ref, onValue } from "firebase/database";

const initialState = {
  localTime: DateTime.now(),
  serverOffset: 0,
  currentTime: DateTime.now(),
}

interface ITimeContext {
  localTime: DateTime;
  serverOffset: number;
  currentTime: DateTime;
}

export const TimeContext = createContext<ITimeContext>(initialState);
export const TimeConsumer = TimeContext.Consumer;

export const TimeProvider = ({ children }:{ children: ReactNode }) => {

  const [localTime, setLocalTime] = useState(DateTime.now());
  const [serverOffset, setServerOffset] = useState(0);
  const [currentTime, setCurrentTime] = useState(DateTime.utc());

  //const currentTime = new DateTime.fromMillis(localTime + serverOffset);

  useEffect(() => {
    const timer = setInterval(() => {
      setLocalTime(prev => prev.plus({ milliseconds: 1000 }));
      setCurrentTime(prev => prev.plus({ milliseconds: 1000 }));
    }, 1000);
    const db = getDatabase();
    const offsetRef = ref(db, ".info/serverTimeOffset");
    const unsubServer = onValue(offsetRef, (snap) => {
      const offset = snap.val();
      setServerOffset(offset);
      setCurrentTime(DateTime.utc().plus({ milliseconds: offset }));
    }, {
      onlyOnce: true
    });
    return () => {
      unsubServer();
      clearInterval(timer);
    }
  },[]);

  return (
    <TimeContext.Provider value={{ localTime, currentTime, serverOffset }}>
      {children}
    </TimeContext.Provider>
  );
  
};
