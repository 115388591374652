import { ReactNode } from 'react';
import styled from '@emotion/styled';
import Button, { ButtonProps } from '@mui/material/Button';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import {useNavigate} from 'react-router-dom';
import { ROUTES } from 'routes';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { IntlText } from 'components';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

export const LoginContainer = styled.div`
  width: 90%;
  margin: 10px auto;
  font-size: 14px;
`;

export const LoginButtonContainer = styled.div`
  margin: 10px 0;
  text-align: center;
`;

export const LoginButton = styled(Button)`
  width: 100%;
  max-width: 400px;
  margin: 0 auto !important;
  box-shadow: 2px 2px 7px #222222 !important;
  padding: 9px !important;
`;

export const FacebookButton = styled(LoginButton)`
  background-color: #1877f2 !important;
`;

export const GoogleButton = styled(LoginButton)`
  background-color: #ffffff !important;
  font-family: Roboto !important;
`;

/*
:active,
  &:hover {
    background-color: #4285f4 !important;
    color: #ffffff !important;
  }
*/

export const ResponsiveLoadingButton = (props: LoadingButtonProps) => {
  const { sx, variant = 'outlined', color = 'primary', children, ...rest } = props;
  return (
    <LoadingButton 
      variant={variant} 
      color={color} 
      sx={{ 
        ...sx,
        fontSize: { 
          xs: 'small', 
          md: 'initial' 
        }
      }} 
      { ...rest }
    >
      {children}
    </LoadingButton>
  )
}
export const PrimaryButton = (props: LoadingButtonProps) => (
  <ResponsiveLoadingButton color={'primary'} {...props}/>
)

export const SecondaryButton = (props: LoadingButtonProps) => (
  <ResponsiveLoadingButton color={'secondary'} {...props}/>
)

export const TertiaryButton = (props: LoadingButtonProps) => (
  <ResponsiveLoadingButton color={'tertiary'} {...props}/>
)

export const CalloutButton = (props: LoadingButtonProps & { filled?: boolean }) => (
  <ResponsiveLoadingButton variant={props.variant || props.filled ? 'contained' : 'outlined'} color={'secondary'} {...props}/>
)

export const CheckButton = ({ 
  onClick, 
  disabled = false, 
  loading = false,
  children,
  filled = false,
  variant,
  ...rest
}: LoadingButtonProps & { children?: ReactNode, filled?: boolean }) => (
  <LoadingButton 
    startIcon={<CheckIcon/>} 
    onClick={onClick} 
    disabled={disabled} 
    loading={loading}
    variant={variant ? variant : filled ? 'contained' : 'outlined'}
    { ...rest }
  >
    {children || <IntlText id="save"/>}
  </LoadingButton>
);

export const CancelButton = ({ 
  onClick, 
  disabled = false, 
  loading = false,
  filled = false,
  children,
  close = false,
  variant,
  color = 'secondary',
}: LoadingButtonProps & { children?: ReactNode, close?: boolean, filled?: boolean }) => ( 
    <LoadingButton 
      startIcon={<CancelIcon/>} 
      onClick={onClick} 
      disabled={disabled} 
      loading={loading}
      variant={variant ? variant : filled ? 'contained' : 'outlined'}
      color={color}
    >
      {children || <IntlText id={close ? "close" : "cancel"}/>}
    </LoadingButton>
  );

export const NextButton = (props: ButtonProps) => <PrimaryButton endIcon={<EastIcon/>} { ...props }>{props.children || 'Next'}</PrimaryButton>
export const BackButton = (props: ButtonProps) => <PrimaryButton startIcon={<WestIcon/>} { ...props }>{props.children || 'Back'}</PrimaryButton>

interface ButtonLinkProps {
  to: string;
  children?: ReactNode;
  loading?: boolean;
}

export const ButtonLink = ({ to, children, loading = false, ...rest }: LoadingButtonProps & ButtonLinkProps) => {
  const navigate = useNavigate()
  return (
    <LoadingButton loading={loading} onClick={() => navigate(to)} {...rest}>
      {children}
    </LoadingButton>
  )
}

export const PrimaryButtonLink = ({ to, children, ...rest }: LoadingButtonProps & ButtonLinkProps) => {
  const navigate = useNavigate()
  return (
    <PrimaryButton onClick={() => navigate(to)} {...rest}>
      {children}
    </PrimaryButton>
  )
}

export const SecondaryButtonLink = ({ to, children, ...rest }: LoadingButtonProps & ButtonLinkProps) => {
  const navigate = useNavigate()
  return (
    <SecondaryButton onClick={() => navigate(to)} {...rest}>
      {children}
    </SecondaryButton>
  )
}

export const CalloutButtonLink = ({ to, children, ...rest }: LoadingButtonProps & ButtonLinkProps) => {
  const navigate = useNavigate()
  return (
    <CalloutButton onClick={() => navigate(to)} {...rest}>
      {children}
    </CalloutButton>
  )
}

export const CancelButtonLink = ({ to, ...rest }: LoadingButtonProps & ButtonLinkProps) => {
  const navigate = useNavigate()
  return (
    <CancelButton onClick={() => navigate(to)} {...rest}/>
  )
}

export const SignupButton = () => (
  <ButtonLink to={ROUTES.signup} variant="text" sx={{color:"#ffffff"}} endIcon={<PersonAddIcon/>}>
      Join
  </ButtonLink>
)