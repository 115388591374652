import {Link, Navigate, useLocation, Outlet} from 'react-router-dom';
import {useFirebaseContext, useSessionContext} from 'hooks';
import {Loading} from 'components';
import {ROUTES, PrivateRouteProps} from 'routes';
import {UserRoles} from '@kwixl/interface';
import Alert from '@mui/material/Alert';

export const AdminRoute = ({
  redirect = ROUTES.signin,
  ...props
}: PrivateRouteProps) => {
  const {firebaseUser} = useFirebaseContext();

  const {userProfile} = useSessionContext();
  
  const location = useLocation();

  if (!firebaseUser) {
    return <Navigate to={redirect} state={{from: location}} />;
  }

  if (!userProfile) {
    return <Loading />;
  }

  if (!userProfile?.verified) {
    return <Navigate to={ROUTES.confirm} state={{from: location}} />;
  }

  //window.setTimeout(() => window.scrollTo(0, 0), 150);

  if (![UserRoles.admin,UserRoles.presenter].includes(userProfile.role)) {
    return (
      <div style={{textAlign: 'center'}}>
        <Alert severity="error" sx={{marginTop: '5rem'}}>
          The page you requested cannot be accessed at this time.
        </Alert>
        <p>
          <Link to={ROUTES.home}>Return to {process.env.REACT_APP_NAME}</Link>
        </p>
      </div>
    );
  }

  return <Outlet />;
};
