import { TermsContent } from "content";
import { BaseModal } from './BaseModal';


interface Props {
  header?: string,
  trigger?: any
}

export const TermsModal = ({ header = 'Terms of Use', trigger }: Props) => 
  <BaseModal 
    trigger={trigger || 'Terms of Use'}
    header={header}
  >
    <TermsContent/>
  </BaseModal>
