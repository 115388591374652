import {useState, useEffect} from 'react';
import {
  addDoc,
  setDoc,
  getDoc,
  DocumentSnapshot,
  DocumentData,
} from 'firebase/firestore';
import {useFirebaseContext, useSessionContext, useRefs} from 'hooks';
import styled from '@emotion/styled';
import { useCollection } from 'react-firebase-hooks/firestore';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

export const ConversationScreen = ({ customer }:{ customer: DocumentSnapshot }) => {

  const {
    firebaseUser, 
    defaultListenerOptions,
  } = useFirebaseContext();

  const { organization } = useSessionContext();

  const {
    conversationRef, 
    conversationMessagesRef,
  } = useRefs();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [conversation, setConversation] = useState<DocumentSnapshot<unknown, DocumentData>>();
  const [conversationId, setConversationId] = useState('');

  const [messages] = useCollection(
    conversationMessagesRef!(conversationId || 'x'),
    defaultListenerOptions,
  )

  useEffect(() => {
    if (!customer || !organization) return;
    setConversationId(`${organization.id}#${customer.id}`);
  }, [customer, organization]);

  useEffect(() => {
    if (!conversationId) return;
    loadConversation();
  }, [conversationId]);

  const loadConversation = async () => {
    const c = await getDoc(conversationRef!(conversationId || 'x'));
    setConversation(c);
  };

  const handleSubmit = async () => {
    if (!message.trim()) return;
    setLoading(true);
    try {
      if (!conversation || !conversation.get('conversation')) {
        await setDoc(conversationRef!(conversationId || 'x'), {
          user: {
            uid: customer.id,
            displayName: customer.get('displayName'),
          },
          orgId: organization?.id,
          createdBy: firebaseUser?.uid,
          message,
        });
      } else {
        await addDoc(
          conversationMessagesRef!(conversationId || 'x'),
          {
            message,
          }
        );
      }
      setMessage('');
    } catch (err: any) {
      console.log('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConversationWrapper>
      <ConversationContainer>
        <MessagesWrapper>
          <MessagesContainer>
            {messages?.docs.map(message => {
              return (
                <Message>
                  <Author>
                    {message.get('author') === 'system'
                      ? 'You'
                      : conversation?.get('user.displayName')}
                  </Author>
                  {message.get('message')}
                  <MessageStatus>
                    {message.get('status') === 'pending' ||
                    !message.get('status')
                      ? 'Sending...'
                      : message.get('status')}
                  </MessageStatus>
                </Message>
              );
            })}
          </MessagesContainer>
        </MessagesWrapper>
        <InputWrapper>
          <MessageInput>
            <TextField
              onChange={({ target: {value}}) => setMessage(value)}
              placeholder="Enter your message"
              value={message}
            />
          </MessageInput>
          <ButtonInput>
            <LoadingButton
              variant="contained"
              onClick={() => handleSubmit()}
              style={{marginTop: '40px'}}
              disabled={loading}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </ButtonInput>
        </InputWrapper>
      </ConversationContainer>
    </ConversationWrapper>
  );
};

const ConversationWrapper = styled.div`
  flex: 1 1 auto;
  margin-bottom: 100px;
`;

const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  height: 100%;
`;

const MessagesWrapper = styled.div`
  flex: 1 1 auto;
  align-self: auto;
  overflow-y: auto;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: none;
`;

const InputWrapper = styled.div`
  flex: 0 1 auto;
  align-self: auto;
`;

const MessageInput = styled.div`
  width: 80%;
  display: inline-block;
`;

const ButtonInput = styled.div`
  width: 15%;
  display: inline-block;
`;

const Message = styled.div`
  padding: 10px;
  width: 100%;
  flex: 0 0 1;
  margin-bottom: 10px;
`;

const Author = styled.span`
  display: block;
  font-weight: bolder;
  font-size: 1.2em;
  margin-bottom: 5px;
`;

const MessageStatus = styled.span`
  display: block;
  font-size: 0.85em;
  color: gray;
`;
