import styled from '@emotion/styled';
import { styled as muiStyled } from "@mui/material/styles";
import {InternalLink as Link, SiteTitle, ExternalLink, IntlText } from 'components';
import {useFirebaseContext, useSessionContext} from 'hooks';
import {ROUTES} from 'routes';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const PageFooter = () => {
  
  const {demo,userHome, userCanSell} = useSessionContext();

  const {firebaseUser} = useFirebaseContext();

  return (
    <Footer id="page-footer">
      <FooterContainer>
        <MobileFooter>
            <p>
              &copy; {new Date().getFullYear()} 421, LLC
            </p>
            <p>
              <Link to={ROUTES.events} color="#fffff0"><IntlText id="events"/></Link>
              {' | '}
              <Link to={ROUTES.privacy} color="#fffff0"><IntlText id="privacy"/></Link>
              {' | '}
              <Link to={ROUTES.terms} color="#fffff0"><IntlText id="terms"/></Link>
              {' | '}
              <Link to={ROUTES.pricing} color="#fffff0"><IntlText id="pricing"/></Link>
              {' | '}
              <ExternalLink to={process.env.REACT_APP_SUPPORT_LINK || ''}><IntlText id="help"/></ExternalLink> 
              {' | '}
              <Link to={ROUTES.contact} color="#fffff0"><IntlText id="contact"/></Link>
            </p>
            {!userCanSell && (
              <p>
                <Link to={ROUTES.selling} color="#fffff0">
                  <IntlText id="sell"/>
                </Link>
              </p>
            )}
            <Version>
              v{process.env.REACT_APP_VERSION}
            </Version>
        </MobileFooter>
        <DesktopFooter>
            <Box flexGrow={1}>
          <Grid container spacing={2}>
              <Grid item md={4}>
                <Stack direction='column'>
                {window.location.pathname !== '/' && (
                    <Link to={ROUTES.home} color="#fffff0">
                      <IntlText id="home"/>
                    </Link>
                )}
                {firebaseUser ? (
                    <Link to={userHome || ''} color="#fffff0">My Account</Link>
                ) : (
                  !demo && (
                      <Link to={ROUTES.signin} color="#fffff0"><IntlText id="signin"/></Link>
                  )
                )}
                  <Link to={ROUTES.events} color="#fffff0"><IntlText id="events"/></Link>
                  <Link to={ROUTES.contact} color="#fffff0"><IntlText id="contact"/></Link>
                {!userCanSell && (
                  <>
                      <Link to={ROUTES.selling} color="#fffff0">
                        <IntlText id="sell"/>
                      </Link>
                      {/*<Link to={ROUTES.demo} color="#fffff0"><IntlText id="request_demo"/></Link>*/}
                  </>
                )}
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack direction="column">
                  <Link to={ROUTES.privacy} color="#fffff0">
                    <IntlText id="privacy"/>
                  </Link>
                  <Link to={ROUTES.terms} color="#fffff0"><IntlText id="terms"/></Link>
                  <Link to={ROUTES.pricing} color="#fffff0"><IntlText id="pricing"/></Link>
                  <Link to={ROUTES.faq} color="#fffff0"><IntlText id="faq"/></Link>
                </Stack>
              </Grid>
              <Grid item md={4}>
                <SiteTitle />
                <FooterInfo>
                    &copy; {new Date().getFullYear()} 421, LLC | v{process.env.REACT_APP_VERSION}
                </FooterInfo>
              </Grid>
          </Grid>
          </Box>
        </DesktopFooter>
        {process.env.REACT_APP_STAGE !== 'production' && (
          <>
            <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
              xs
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' }}}>
              sm
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'none' }}}>
              md
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'none' }}}>
              lg
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' }}}>
              xl
            </Box>
            </>
        )}
      </FooterContainer>
    </Footer>
  );
};

const Version = styled.p`
  font-size: .75rem;
  color: #c2c2c2;
`

const MobileFooter = styled.div`
  text-align: center;
  font-size: .9rem;
  margin-top: 10px;
  min-height: 65px;
  @media (min-width: 769px) {
    display: none;
  }
`

const DesktopFooter = styled.div`
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`

const Footer = muiStyled('div')`
  width: 100%;
  position: relative;
  padding-top: 20px !important;
  background-color: rgb(35, 43, 62) !important;
  padding-bottom: 20px;
  color: '#ffffff';
  & a {
    text-transform: capitalize;
    color: '#ffffff';
  }
  & a:hover {
    text-decoration: underline;
    color: '#ffffff';
  }
  & a:visited {
    color: '#ffffff';
  }
  align-self: stretch;
  flex: 0;
`;

const FooterContainer = muiStyled('div')`
  width: 95%;
  margin: 0 auto;
  color: #fffff0;
`;

const FooterInfo = styled.div`
  margin-top: 20px;
  font-size: 10px; 
  color: #fffff0;
`;

