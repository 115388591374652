
import IconButton from '@mui/material/IconButton';
import { useVideoContext } from 'hooks';
import { VideoStatus } from '@kwixl/interface';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ButtonGroup from '@mui/material/ButtonGroup';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { MicToggle } from './MicToggle';
import { CameraSelect } from './CameraSelect';

export const CameraControl = ({
    onStart = () => {},
    onStop = () => {},
    countingDown = false,
}:{
    onStart: () => void;
    onStop: () => void;
    countingDown?: boolean;
}) => {

    const { 
        pauseStreaming,
        resumeStreaming,
        stream,
     } = useVideoContext();

    const videoStatus = stream?.get('status') || VideoStatus.none;
    
    return (
        <>
        <ButtonGroup orientation="vertical">
            {(() => {
                switch (videoStatus) {
                    case VideoStatus.started:
                        return (
                            <>
                                <IconButton
                                    onClick={() => pauseStreaming!()}
                                >
                                    <PauseCircleIcon fontSize="large" htmlColor="#fffff0"/>
                                </IconButton>
                                <IconButton
                                    onClick={() => onStop()}
                                >
                                    <StopCircleIcon fontSize="large" htmlColor="#fffff0"/>
                                </IconButton>
                            </>
                        );
                    case VideoStatus.paused:
                    return (
                        <>
                            <IconButton
                                onClick={() => resumeStreaming!()}
                            >
                                <PlayCircleIcon fontSize="large" htmlColor="#fffff0"/>
                            </IconButton>
                            <IconButton
                                onClick={() => onStop()}
                            >
                                <StopCircleIcon fontSize="large" htmlColor="#fffff0"/>
                            </IconButton>
                        </>
                    );
                    default:
                        return (
                            <IconButton
                                sx={{ cursor: 'pointer' }}
                                onClick={() => onStart()}
                                disabled={countingDown}
                            >
                                <RadioButtonCheckedIcon fontSize="large" color={countingDown ? 'disabled' : "error" }/>
                            </IconButton>
                        );
                    }
                })()
            }
            <MicToggle/>
            <CameraSelect/>
        </ButtonGroup>
        </>
    )
}

