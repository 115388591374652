import { useReducer } from 'react';
import { GenericObject, Loading,  AlertBox, NextButton, BackButton, TertiaryButton } from 'components';
import { useEffect } from 'react';
import { reducer, flash } from 'lib';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ReplayIcon from '@mui/icons-material/Replay';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';

export const SelectLiveVideo = ({
    page,
    onNext,
    onBack,
}:{
    page: GenericObject;
    onNext: (video: GenericObject) => void;
    onBack: () => void;
}) => {

    const [{
        ready = false,
        videos = [],
        loading = false,
        video,
    }, dispatch] = useReducer(reducer, {});

    useEffect(() => {
        if (!page || !page?.id || loading) return;
        searchVideos();
    },[page]);

    const handleNext = () => {
        onNext(video);
    }

    const searchVideos = () => {
        if (loading) return;
        dispatch({ loading: true });
        const token = page?.access_token;
        try {
            window.FB.api(
                `/${page?.id}/live_videos`,
                {
                    access_token: token,
                },
                (response: GenericObject) => {
                    if (response.error) {
                        flash.error(response.error.message);
                        return;
                    }
                    if (response?.data?.length > 0) {
                        dispatch({
                            videos: response.data.filter(({status}:{ status: string }) => status === 'LIVE'),
                            loading: false,
                            ready: true,
                        });
                    }
                }
            );
        } catch (err: any) {
            dispatch({
                loading: false,
                ready: true,
            });
        }

    };

    return (
        <>
            <DialogContent dividers>
                {(!ready || loading) && <Loading>Searching for videos...</Loading>}
                {(ready && !loading) && (
                    <>
                        {!!videos?.length ? (
                            <>
                                <Box mb={2}>Please select a video to use for your event.</Box>
                                <FormControl fullWidth>
                                    <InputLabel>Select a video</InputLabel>
                                    <Select
                                        label="Select a video"
                                        onChange={(e: SelectChangeEvent) =>
                                            dispatch({
                                                video: videos.find(({ id }:{ id: string }) => id === e.target.value),
                                            })
                                        }
                                        value={video?.id}
                                    >
                                    {videos.map(({id = '', title = ''}) => (
                                        <MenuItem value={id}>{title && title !== '' ? title : id}</MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </>
                        ) : (
                            <>
                                <AlertBox severity="error">
                                    No live videos were found.  Your video must already be live for it to appear here. If your video was just started, wait a few seconds then try again.
                                </AlertBox>
                                <Box display={'flex'} justifyContent='center'>
                                    <TertiaryButton disabled={loading} loading={loading} startIcon={<ReplayIcon/>} onClick={searchVideos}>
                                        Try Again
                                    </TertiaryButton>
                                </Box>

                            </>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <BackButton sx={{ position: 'absolute', left: '10px' }} onClick={onBack}/>
                <NextButton disabled={!video || loading} onClick={handleNext}/>
            </DialogActions>
        </>
    ) 


}