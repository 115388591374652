export const formatCurrency = (value: string | number, len = 2) => {
  if (!value) return "";
  if (!value) value = "";
  let s = (value + "").replace(/[^\d.-]/g, "");
  let parts = s.split(".");
  s = `${parts[0]}`;
  if (parts.length > 1) {
    s += `.${parts[1].substring(0, len)}`;
  }
  return s;
};



