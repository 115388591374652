export const copyLink = (url: string, message = "Link copied to clipboard!") => {
    try {
        const el = document.createElement("textarea");
        el.value = url;
        document.body.appendChild(el);
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(el);
        return true;
    } catch (err) {
        return false;
    }
};
