import { ReactElement, cloneElement } from 'react';
import { SignupButton, ButtonLink, SiteTitle, NavMenu } from 'components';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import LoginIcon from '@mui/icons-material/Login';
import { ROUTES } from 'routes';
import { useFirebaseContext } from 'hooks';
import { InternalLink } from 'components';

function ElevationScroll({ children }:{ children: ReactElement}) {

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });
  
    return cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
}

export const TopMenu = () => {

    const {firebaseUser} = useFirebaseContext();

    return (
        <ElevationScroll>
            <AppBar 
                position="sticky" 
                sx={{ 
                    backgroundColor: '#0e6dad', 
                    padding: { xs: 0, md: '5px 30px 5px 30px' }
                }}
            >
                <Toolbar>
                    <SiteTitle/>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: 'flex' }}>
                        {!firebaseUser && <SignupButton/>}
                    </Box>
                    <Box sx={{display: { xs: !firebaseUser ? 'none' : 'flex', md: 'flex' }, alignItems: 'right' }}>
                        {window.location.pathname !== ROUTES.home && (
                            <Box ml={2}>
                                <InternalLink to={ROUTES.home} style={{ color: '#fffff0' }}>Home</InternalLink>
                            </Box>
                        )}
                        <Box ml={2}>
                            <InternalLink to={ROUTES.events} style={{ color: '#fffff0' }}>Events</InternalLink>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex' }} ml={2}>
                        {!firebaseUser && (
                            <ButtonLink 
                                to={ROUTES.signin} 
                                variant="contained" 
                                color={'secondaryAction'} 
                                endIcon={<LoginIcon/>}
                                disabled={window.location.pathname === ROUTES.signin}
                            >
                                Sign-In
                            </ButtonLink>
                        )}
                    </Box>
                    <Box>
                        <NavMenu/>
                    </Box>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    )
}
