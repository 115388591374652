import { useState } from "react";
import styled from "@emotion/styled";
import {AppName, PrimaryButton} from 'components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

interface Props {
  trigger?: any;
}

export const BillingTerms = ({ trigger }: Props) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((prev) => !prev);

  if (!open) return (
    <a onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>billing terms</a>
  )

  return (
    <Dialog
      open={open}
      onClose={toggleOpen}
      scroll='paper'
    >
      <DialogTitle><AppName/> Billing Terms</DialogTitle>
      <DialogContent>
        <Section>
          <small>Last updated: Apr 30, 2022</small>
        </Section>
        <SectionTitle>Parties to the agreement</SectionTitle>
        <Section>
          Users, subscribers, or customers, collectively referred to herein as
          "Customer," are parties to this agreement. The website and its owners
          and/or operators are parties to this agreement, herein referred to as
          "Company." This agreement is between the Customer and the Company and
          covers the use of and services provided by this website.
        </Section>
        <SectionTitle>Term of agreement</SectionTitle>
        <Section>
          The agreement shall remain in effect in perpetuity beginning with the
          Customer's first use of the website or its services.
        </Section>
        <SectionTitle>Billing agreement</SectionTitle>
        <Section>
          The customer agrees that the Company provides a service of value to
          the Customer and Customer has agreed to provide payment to the Company
          for services rendered. The Company's payment processor will keep a
          payment method on file (credit card) to charge the Customer for
          services rendered, both one-time and recurring, including but not
          limited to setup fees, sales fees, shipping labels and subscriptions.
          Customer agrees to provide a valid payment method to Company's payment
          processor for use for these charges and acknowledges and gives consent
          for the Company and its payment processor to automatically charge
          amounts due for services rendered at any time.
        </Section>
        <SectionTitle>Third Parties</SectionTitle>
        <Section>
          Except for the payment processor used by the Company, there shall be
          no other parties to this agreement.
        </Section>
        <SectionTitle>Privacy/Security</SectionTitle>
        <Section>
          Customer acknowledges that the Company does not store payment method
          information, but that this information is stored in encrypted format
          by the Company's payment processor. The information provided to the
          Company by the payment processor is only an identifier for the payment
          method used and the Company does not have access to Customer's payment
          method information.
        </Section>
        <SectionTitle>Limitation of Liability</SectionTitle>
        <Section>
          Now, therefore, by the Customer acknowledging how their payment
          information is stored, and by the continued use of the website and/or
          the services provided, the Customer agrees to hold harmless the
          Company regarding data breaches or misuse of data by the Company's
          payment processor and its affiliates, employees, contractors,
          third-party service providers and other assigns.
        </Section>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={toggleOpen}>
          Ok
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

const SectionTitle = styled.span`
  display: block;
  text-transform: uppercase;
  font-weight: 600;
`;

const Section = styled.div`
  margin: 10px 0 20px;
`;
