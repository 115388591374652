import {useEffect, memo} from 'react';
import Message from './Message';
import {useChatContext} from 'hooks';
import Box from '@mui/material/Box';
import throttle from 'lodash/throttle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IconButton from '@mui/material/IconButton';
import { DocumentSnapshot } from 'firebase/firestore';

export const Messages = ({
  onReply = () => {},
}:{
  onReply?: (chat: DocumentSnapshot) => void;
}) => {

  const {chatMessages, chatAtBottom, setChatAtBottom, scrollChat} = useChatContext();

  useEffect(() => {
    document
      .getElementById('chat-scroller')?.addEventListener('scroll', onChatScroll);
    return () => {
      try {
        document
          .getElementById('chat-scroller')?.removeEventListener('scroll', onChatScroll);
      } catch (err) {
      }
    };
  }, []);

  const onChatScroll = throttle(() => {
    window.setTimeout(() => {
      try {
        const el = document.getElementById('chat-scroller');
        if (!el) return;
        const atBottom =
          Math.abs(el.scrollHeight - el.clientHeight - el.scrollTop) < 35;
        setChatAtBottom(atBottom);
      } catch (err: any) {
        console.error(err.message);
      }
    }, 150);
  }, 250);

  return (
    <>
      <Box 
        flex={'1 0 auto'} 
        height={0}
        overflow='auto' 
        position='relative' 
        id="chat-scroller"
        sx={{ 
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          color: '#fffff0'
        }}
      >
        {chatMessages?.docs?.sort((a, b) => a.get('createdAt').toDate() - b.get('createdAt').toDate()).map((row, index) => (
            <Message 
              key={`__cm__${index}`} 
              data={row as DocumentSnapshot} 
              onReply={onReply}
            />
        ))}
      </Box>
      {!chatAtBottom && (
        <IconButton
          sx={{
            backgroundColor: 'primary',
            animation: 'fadeIn ease 1s',
            padding: '5px',
            textAlign: 'center',
            position: 'absolute',
            bottom: '70px',
            right: { xs: '96px', md: '20px' },
            marginLeft: '-100px !important',
            zIndex: 1,
            cursor: 'pointer',
          }}
          color="info"
          onClick={() => scrollChat()}
        >
          <ArrowDownwardIcon htmlColor="#fffff0" />
        </IconButton>
      )}
    </>
  );
};

/*
const isEqual = (prev: any, next: any) => {
  //return (prev.data === next.data) && (prev.data.length === next.data.length) && (prev.height === next.height);
  //return (prev.height === next.height);
};
*/

export default memo(Messages); //, isEqual);
