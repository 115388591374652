import { useContext } from "react";
import { IntlMessageContext } from "providers";

export const useIntlText = () => {
  const context = useContext(IntlMessageContext);
  if (!context) {
    throw new Error("useIntlText must be used within a IntlProvider");
  }
  return context;
};
