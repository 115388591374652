import { BaseModal } from './BaseModal';

interface Props {
  content: JSX.Element | string,
  header?: string,
  trigger?: any
}

export const ContentModal = ({ content = '', header = 'Privacy Policy', trigger = 'Learn More' }: Props) => 
  <BaseModal 
    trigger={trigger}
    header={header}
  >
    {content}
  </BaseModal>