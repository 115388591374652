import Joi from "joi";

export const address_schema = Joi.object({
  address1: Joi.string().required().min(4).max(100).messages({
    "string.empty": "Address cannot be blank",
    "string.min": "Address should have a minimum length of {#limit} characters",
    "string.max": "Address should have a maximum length of {#limit} characters",
    "any.required": "Address is a required field",
  }),
  address2: Joi.string().allow(null).allow("").optional(),
  city: Joi.string().required().min(2).max(100).messages({
    "string.empty": "City cannot be blank",
    "string.min": "City should have a minimum length of {#limit} characters",
    "string.max": "City should have a maximum length of {#limit} characters",
    "any.required": "City is a required field",
  }),
  state: Joi.string().required().min(2).messages({
    "string.empty": "State cannot be blank",
    "string.min": "State should have a minimum length of {#limit} characters",
    "string.max": "State should have a maximum length of {#limit} characters",
    "any.required": "State is a required field",
  }),
  zip: Joi.string().required().min(5).messages({
    "string.empty": "Zip cannot be blank",
    "string.min": "Zip should have a minimum length of {#limit} characters",
    "string.max": "Zip should have a maximum length of {#limit} characters",
    "any.required": "Zip is a required field",
  }),
  country: Joi.string().required().min(2).messages({
    "string.empty": "Country cannot be blank",
    "string.min": "Country should have a minimum length of {#limit} characters",
    "string.max": "Country should have a maximum length of {#limit} characters",
    "any.required": "Country is a required field",
  }),
});
