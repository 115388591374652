import { Model, validator, TransformValidateModel, TransformValidateDateTime } from '../Classes';
import {DateTime} from 'luxon';

export enum EventStatus {
    none = 'none',
    pending = 'pending',
    active = 'active',
    paused = 'paused',
    closed = 'closed',
    processing = 'processing',
    canceled = 'canceled',
    complete = 'complete',
    locked = 'locked',
}

export enum VideoProvider {
    none = 'none',
    kwixl = 'kwixl',
    facebook = 'facebook',
    youtube = 'youtube',
    embed = 'embed',
}

export enum EventType {
    none = '',
    kwixl = 'kwixl',
    claim = 'claim',
    ccs = 'ccs',
    cca = 'cca',
  }
  
export const eventStatusName = (status: EventStatus) => {
    switch (status) {
        case EventStatus.none:
            return 'None';
        case EventStatus.pending:
            return 'Pending';
        case EventStatus.active:
            return 'Active';
        case EventStatus.paused:
            return 'Paused';
        case EventStatus.closed:
            return 'Closed';
        case EventStatus.canceled:
            return 'Canceled';
        case EventStatus.processing:
            return 'Processing';
        case EventStatus.complete:
            return 'Complete';
        default:
            return status.substring(0, 1).toUpperCase() + status.substring(1);
    }
};
  
export enum VideoStatus {
    none = 'none',
    started = 'started',
    paused = 'paused',
    stopped = 'stopped',
    resumed = 'resumed',
    buffering = 'buffering',
    ready = 'ready',
}
  
export const videoStatusKey = (status: VideoStatus) => {
    switch (status) {
        case VideoStatus.none:
            return 'none';
        case VideoStatus.started:
            return 'start';
        case VideoStatus.paused:
            return 'pause';
        case VideoStatus.stopped:
            return 'stop';
        case VideoStatus.resumed:
            return 'resume';
        case VideoStatus.buffering:
            return 'buffering';
        case VideoStatus.ready:
            return 'ready';
        default:
            return 'unknown';
    }
};
  
export class EventStream extends Model<EventStream> {

    @validator.IsEnum(VideoStatus)
    status: string;

    @validator.IsEnum(VideoProvider)
    provider: VideoProvider;

    @validator.IsString()
    url: string;

    @validator.IsString()
    type: string;

    @validator.IsString()
    @validator.IsOptional()
    streamId?: string;

    @validator.IsString()
    @validator.IsOptional()
    target?: string;

    @validator.IsString()
    @validator.IsOptional()
    targetId?: string;

    @validator.IsString()
    @validator.IsOptional()
    streamKey?: string;

    @validator.IsString()
    @validator.IsOptional()
    token?: string;

}

export class EventSettings extends Model<EventSettings> {

    @validator.IsBoolean()
    @validator.IsOptional()
    allowChat?: boolean = true;

    @validator.IsBoolean()
    @validator.IsOptional()
    autoInvoice?: boolean = true;

    @validator.IsBoolean()
    @validator.IsOptional()
    confirmPurchase?: boolean = false;

    @validator.IsBoolean()
    @validator.IsOptional()
    payInvoice?: boolean = true;

    @validator.IsBoolean()
    @validator.IsOptional()
    payOnline?: boolean = true;

    @validator.IsBoolean()
    @validator.IsOptional()
    preview?: boolean = true;

    @validator.IsBoolean()
    @validator.IsOptional()
    showAll?: boolean = true;

    @validator.IsString()
    @validator.IsOptional()
    successSound?: string = '';

}


export class Event extends Model<Event> {

    @TransformValidateDateTime()
    createdAt: DateTime;

    @validator.IsString()
    createdBy: string;

    @TransformValidateDateTime()
    updatedAt: DateTime;
    
    @validator.IsString()
    updatedBy: string;

    @TransformValidateDateTime()
    @validator.IsOptional()
    deletedAt?: DateTime;

    @validator.IsString()
    @validator.IsOptional()
    deletedBy?: string;

    @validator.IsString()
    @validator.IsOptional()
    description?: string;

    @TransformValidateDateTime()
    endAt: DateTime;

    @TransformValidateDateTime()
    startAt: DateTime;

    @validator.IsString()
    name: string;

    @validator.IsString()
    orgId: string;

    @TransformValidateDateTime()
    @validator.IsOptional()
    processedAt?: DateTime;

    @validator.IsBoolean()
    @validator.IsOptional()
    published: boolean = false;

    @validator.IsString()
    slug: string;

    @validator.IsEnum(EventStatus)
    status: EventStatus;

    @TransformValidateModel(EventStream)
    stream: EventStream;

    @validator.IsEnum(EventType)
    type: EventType;

    @validator.IsBoolean()
    @validator.IsOptional()
    ticketed?: boolean = false;

    @validator.IsNumber()
    @validator.IsOptional()
    ticketPrice?: number;

    @validator.IsString()
    @validator.IsOptional()
    image?: string;

    @validator.IsString()
    @validator.IsOptional()
    presenterId?: string;

    @TransformValidateModel(EventSettings)
    @validator.IsOptional()
    settings?: EventSettings;

}

export class Claim extends Model<Claim> {
    @validator.IsString()
    itemId: string;

    @validator.IsNumber()
    qty: number = 1;
}