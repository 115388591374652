import { useEventContext } from 'hooks';
import PeopleIcon from '@mui/icons-material/People';
import Stack from '@mui/material/Stack';

export const Viewers = () => {
  const { event } = useEventContext();
  return (
    <Stack direction="row">
        <PeopleIcon sx={{verticalAlign:'middle'}}/> 
        <span style={{marginLeft:'.3em'}}>
          {event?.get('viewers') || 0}
        </span>
    </Stack>
  )
};
