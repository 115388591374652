import styled from "@emotion/styled";

export const NetworkQuality = ({ value }: { value: number }) => {
  let max = 0;
  switch (value) {
    case 1:
      max = 4;
      break;
    case 2:
      max = 3;
      break;
    case 3:
      max = 2;
      break;
    case 4:
      max = 1;
      break;
    default:
      max = 0;
  }
  const a = ["", "", "", ""];
  return (
    <Container>
      {a.map((v, i) => (
        <Bar key={`bar${i}`} index={i + 1} active={i < max} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
  width: 24px;
`;

interface BarProps {
  index: number;
  active: boolean;
}

const Bar = styled.div<BarProps>`
  height: ${(props) => props.index * 4}px;
  width: 5px;
  background-color: ${(props) => (props.active ? "#34eb3d" : "gray")};
  display: inline-block;
  margin-right: 1px;
`;
