export enum Status {
  none = 'none',
  pending = 'pending',
  active = 'active',
  blocked = 'blocked',
  deleted = 'deleted',
}

export const UserRoleTypes = ['user', 'presenter', 'admin'] as const;
export type UserRole = typeof UserRoleTypes[number];

export enum UserRoles {
  user = 'user',
  presenter = 'presenter',
  admin = 'admin',
  system = 'system',
}
