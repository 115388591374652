import { Children, cloneElement } from 'react';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';

interface Props {
  error?: boolean;
  message?: string;
  required?: boolean;
  children?: any;
  noMessage?: boolean;
}
export const RequiredField = ({
  error = false,
  message = 'This is a required field and cannot be blank',
  required = true,
  children,
  noMessage = false,
}: Props) => (
  <>
    {Children.map(children, child =>
      cloneElement(child, {required: required, error})
    )}
    {error && noMessage === false && (
      <Box mt={1}>
        <FormHelperText error>
          {message}
        </FormHelperText>
      </Box>
    )}
  </>
);
