import { ReactNode } from 'react';

export const Trademark = ({children}:{ children: ReactNode }) => (
  <span style={{marginLeft: '.3em'}}>
    {children}
    <sup style={{ fontSize: '.6em'}}>
      TM
    </sup>
  </span>
);
