import {UserAvatar} from './';
import styled from '@emotion/styled';

interface Props {
  image: string;
  name: string;
}

export const ChatAvatar = ({image, name}: Props) => (
  <AvatarContainer>
    <UserAvatar src={image} name={name} />
  </AvatarContainer>
);

const AvatarContainer = styled.div`
  display: block;
  width: 2.5em;
  float: left;
  margin: 0.2em 0.7em 0 0;
  height: 2.5rem;
`;
