import { Link as RouterLink, LinkProps } from 'react-router-dom';
import Link from '@mui/material/Link';

export const InternalLink = ({ to, children, color = 'primary', style = { display: 'inline-block' }, ...rest }: LinkProps) => (
    <Link 
        component={RouterLink}  
        to={to}
        color={color}
        style={{ ...style }}
        {...rest}
    >
        {children}
    </Link>
)