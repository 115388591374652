import { Loading } from './Loading';
import { Body } from './Body';

export const PageLoader = () =>
    <Body>
        <div style={{height:`${ (window.innerHeight/2)}px`}}>
            <Loading/>
        </div>
    </Body>



