import {PathRouteProps} from 'react-router';
import {Navigate, useLocation, Outlet} from 'react-router-dom';
import {useFirebaseContext, useSessionContext} from 'hooks';
import {Loading} from 'components';
import {ROUTES} from 'routes';

export interface PrivateRouteProps extends PathRouteProps {
  redirect?: string;
  message?: string;
}

export const AuthenticatedRoute = ({
  redirect = ROUTES.signin,
  message = '',
}: PrivateRouteProps) => {

  const {firebaseUser} = useFirebaseContext();

  const {userProfile} = useSessionContext();
  
  const location = useLocation();

  if (!firebaseUser) {
    return <Navigate to={redirect} state={{from: location, message}} />;
  }

  if (!userProfile) {
    return <Loading>Waiting for user account...</Loading>;
  }

  if (!userProfile?.verified) {
    return <Navigate to={ROUTES.confirm} state={{from: location, message}} />;
  }

  return <Outlet />;

};
