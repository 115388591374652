import React from 'react';

const isEqual = (prev: any, next: any) =>
  prev.content === next.content && prev.length === next.length;

interface Props {
  content?: string;
  length?: number;
  trigger?: any;
  onMore?: (content: string) => void | undefined;
}

export const Ellipses = React.memo<Props>(
  ({content = '', length = 105, onMore, trigger}: any) => {

    const handleClick = (e: React.MouseEvent) => {
      e.preventDefault();
      onMore(content);
      return false;
    }

    try {
      return content.length <= length ? (
        content
      ) : (
        <>
          {`${content.substring(0, length)}...`}
          {trigger}
          {onMore && <a onClick={handleClick}>more</a>}
        </>
      );
    } catch (err) {
      return '';
    }
  },
  isEqual
);
