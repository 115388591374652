import Joi from 'joi';

export const edit_event_schema = Joi.object({
  //orgId : Joi.number().integer().greater(0).required(),
  /*
  category: Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required(),
    ['root']: Joi.string().optional(),
  }),
  */
  type: Joi.string().valid('kwixl', 'ccs', 'cca').required().messages({
    'any.only': 'Event type must be selected',
    'any.required': 'Please select an event type',
  }),
  name: Joi.string().min(5).required().messages({
    'any.required': 'Please provide an event name of at least 5 characters',
    'string.min':
      'The event name should have a minimum length of {#limit} characters',
    'string.max':
      'The event name should have a maximum length of {#limit} characters',
  }),
  startAt: Joi.date().required().messages({
    'date.base': 'Please select a valid start date and time',
    'any.required': 'A valid start date and time is required',
  }),
  ticketed: Joi.boolean().optional(),
  ticketPrice: Joi.number()
    .when('ticketed', {
      is: true,
      then: Joi.number().min(1).required(),
      otherwise: Joi.optional(),
    })
    .messages({
      'any.required':
        'Please provide a ticket price of at least 1 (one) dollar',
      'number.min': 'The ticket price must be a minimum of {#limit} dollar',
    }),
});
