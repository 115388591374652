import 'reflect-metadata';

import * as validator from 'class-validator';
import * as transformer from 'class-transformer';

//import {DateTime} from 'luxon';

import {Model} from './model';
import {toDateTime} from './utils';

interface EqualityValidationOptions extends validator.ValidationOptions {
  allowEqual?: boolean;
}

//
// TODO: Finish implementing this
//
//export class DateTime extends LuxonDateTime {
//  static serverTimestamp(value: unknown) {
//    const datetime = this.utc();
//
//    Object.assign(datetime, {
//      serverTimestamp: value,
//    });
//
//    return datetime;
//  }
//}

/**
 *
 * Verifies that the decorated property is greater than the
 * supplied 'property'.
 *
 */
export function IsGreaterThan(
  property: string,
  validationOptions?: EqualityValidationOptions
) {
  return function (object: Object, propertyName: string) {
    validator.registerDecorator({
      name: 'isGreaterThan',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      validator: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validate: (value: any, args: validator.ValidationArguments) => {
          const [relatedPropertyName] = args.constraints;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const relatedValue = (args.object as any)[relatedPropertyName];
          return (
            value > relatedValue ||
            (validationOptions?.allowEqual ? value === relatedValue : false)
          );
        },
      },
    });
  };
}

/**
 *
 * Verifies that the decorated property is less than the
 * supplied 'property'.
 *
 */
export function IsLessThan(
  property: string,
  validationOptions?: EqualityValidationOptions
) {
  return function (object: Object, propertyName: string) {
    validator.registerDecorator({
      name: 'isGreaterThan',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      validator: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validate: (value: any, args: validator.ValidationArguments) => {
          const [relatedPropertyName] = args.constraints;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const relatedValue = (args.object as any)[relatedPropertyName];
          return (
            value < relatedValue ||
            (validationOptions?.allowEqual ? value === relatedValue : false)
          );
        },
      },
    });
  };
}

/**
 *
 * Transform and validate a Luxon DateTime object.  When coming from
 * a plain JS object, valid inputs are:
 *
 *    - ISO string
 *    - JS Date object
 *    - Luxon DateTime object
 *    - Firestore Timestamp object
 *
 * When converting back to a plain JS object, this will convert the
 * Luxon DateTime object to a JS Date object.
 *
 */
export const TransformValidateDateTime = () => {
  return (target: Object, propertyKey: string | symbol) => {
    // Hack: This looks really stange, so here's what's going on.
    // When the incoming value is a string, DateTime or Date object,
    // we return the `Date` constructor which results in the transformation
    // library doing the "right thing".  If the incoming object has a `toDate`
    // property, we assume it is a Firestore `Timestamp` object and wrap it
    // in a function that returns the `Timestamp` object.  This stops the
    // transformation library from choking on the value which we will then
    // process later in `Transform`.
    transformer.Type(args => {
      if (args?.newObject.constructor === Object) {
        // Going to a plain object we convert the date to a string
        return Date;
      }

      const value = args?.object[args.property];
      if (
        value &&
        (value.toDate || value._seconds || value.constructor === Object)
      ) {
        return function () {
          return value;
        };
      }
      return Date;
    })(target, propertyKey);

    transformer.Transform(({value}) => toDateTime(value), {toClassOnly: true})(
      target,
      propertyKey
    );

    transformer.Transform(({value}) => value.toUTC().toJSDate(), {
      toPlainOnly: true,
    })(target, propertyKey);

    validator.IsInstance(Date, {
      message: 'Value is not a supported date format',
    })(target, propertyKey);
  };
};

/**
 *
 * Transform and validate property as an instance of a {@linkcode Model}.  If
 * the property is actually and array of Model instances, make sure to pass
 * `{each: true}` in the validation options.
 *
 */
export const TransformValidateModel = <T extends typeof Model>(
  model: T,
  validationOptions?: validator.ValidationOptions
) => {
  return (target: Object, propertyKey: string | symbol) => {
    // Convert to the model type
    transformer.Type(() => model)(target, propertyKey);

    // Validate the model
    validator.ValidateNested(validationOptions)(target, propertyKey);

    // Finally make sure that the result is actually an instance of model
    validator.IsInstance(model, validationOptions)(target, propertyKey);
  };
};
