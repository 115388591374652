import {useEffect, useReducer, useRef} from 'react';
import {useEventContext, useFirebaseContext} from 'hooks';
import QRCode from "react-qr-code";
import {reducer} from 'lib';
import styled from '@emotion/styled';
import {Loading} from 'components';

export const VideoQRCode = () => {

    const codeRef = useRef<string>();

    const [{
        loading = false,
    }, dispatch] = useReducer(reducer, {});

    const {
        firebaseUser,
        callable,
    } = useFirebaseContext();

    const {
        event
    } = useEventContext();

    useEffect(() => {
        (async () => {
            if (!firebaseUser || !event || codeRef.current || loading) return;
            dispatch({ loading: true });
            const { data } = await callable!(
                'auth-presenterLink', { 
                    event: event.id,
                });
            const { id, token } = data || {};
            codeRef.current = `${process.env.REACT_APP_URL}/auth/event/${id}/${token}`;
            dispatch({ loading: false });
        })()
    },[firebaseUser, event])

    if (loading || !codeRef.current) return (
        <Block>
            <Loading>
                Please wait...
            </Loading>
        </Block>
    )

    return (
        <Container>
            <QRCode 
                value={codeRef.current} 
                size={150}
            />
            <Text>
                Use your mobile device to scan the QR code to present your live video.  
            </Text>
        </Container>
    )
}

const Block = styled.div`
    text-align: center;
    padding: 20px;
`

const Text = styled.div`
    max-width: 90%;
    margin: 10px auto;
    font-size: .8rem;
    @media (min-width: 769px) {
        max-width: 80%;
        font-size: inherit;
    }
`

const Container = styled.div`
    text-align: center;
    height: 100%;
    padding: 10px;
`
