import { useReducer, useEffect, cloneElement, useCallback, MouseEvent } from 'react';
import { reducer } from 'lib';
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ConfirmModal } from 'modals';
import { Box } from '@mui/material';

interface ModalProps {
    title?: string | JSX.Element;
    trigger?: JSX.Element;
    closed?: () => void;
    opened?: () => void;
    open?: boolean;
    closeMessage?: string;
}

export const Modal = (props: Omit<DialogProps, 'open'> & ModalProps) => {

    const { opened = () => {}, closeMessage, open = false, title, ...rest } = props;

    const [{
        active = false,
    }, dispatch] = useReducer(reducer, {});

    useEffect(() => {
        if (props.hasOwnProperty('open')) dispatch({ active: props.open });
    },[props]);

    useEffect(() => {
        dispatch({ active: open });
    },[open]);

    useEffect(() => {
        if (!active) return;
        opened();
    },[active]);

    const handleClose = () => {
        dispatch({ active: false });
        if (props.closed && typeof props.closed === 'function') props.closed();
    }

    const handleCloseEvent = (event: object, reason: string) => {
        if (['escapeKeyDown','backdropClick'].includes(reason)) return false;
        handleClose();
    }

    const Trigger = useCallback(() => props.trigger 
        ? cloneElement(
            props.trigger, { 
                cursor: 'pointer', 
                disabled: active, 
                onClick: () => dispatch({ active: true }) 
            })
        : null
    ,[]);

    return (
        <>
            <Trigger/>
            <Dialog 
                { ...rest }
                open={active}
                onClose={handleCloseEvent}
                disableEscapeKeyDown={true}
            >
                {title && (
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                )}
                <Box
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    { closeMessage 
                        ?   <ConfirmModal
                                trigger={<IconButton><CloseIcon/></IconButton>}
                                title="Confirm Cancel"
                                content={closeMessage}
                                onConfirm={handleClose}
                            />
                        :   <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                    }
                </Box>
                { active && props.children }
            </Dialog>
        </>
    )
}
