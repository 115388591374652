import { useState, cloneElement } from "react";
import {
  LoginForm,
  CancelButton,
} from "components";
import type {UserCredential, UserInfo} from 'firebase/auth';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

interface Props {
  onSuccess: (result: UserCredential | UserInfo) => void;
  trigger: any;
  providers?: string[];
}

export const LoginModal = ({ onSuccess, trigger, providers }: Props) => {

  const [open, setOpen] = useState<boolean>(false);

  const closeModal = () => {
    setOpen(false);
  };

  const Trigger = () => (
    cloneElement(trigger, { disabled: open, onClick: () => setOpen(true) })
  )

  return (
    <>
      <Trigger/>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent dividers>
          <LoginForm onSuccess={onSuccess}/>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={closeModal}/>
        </DialogActions>
      </Dialog>
    </>
  );
};
