import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { ReactNode } from 'react';

export interface HelpTextProps {
    children: string | ReactNode;
    compact?: boolean;
}

export const HelpText = ({ children, compact = false }: HelpTextProps): ReactNode => {
    switch (compact) {
        case true:
            return (
                <Tooltip disableFocusListener title={children as string}>
                    <InfoIcon/>
                </Tooltip>
            )
        default: 
            return (
                <small style={{display:'block', width: '100%', marginTop: '10px'}}>
                    {children}
                </small>
            )
    }
}
