import {useNavigate} from 'react-router-dom';
import {CdnImage, FullDate} from 'components';
import {
  DocumentSnapshot,
} from 'firebase/firestore';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Parser} from 'html-to-react';
import { ROUTES } from 'routes';

const parser = Parser();


export const EventCard = ({ event }: { event: DocumentSnapshot }) => {

    const navigate = useNavigate();

    if (!event) return null;

    return (
        <Card key={`__event_card_${event?.id}`} sx={{ width:'100%' }}>
            <CardActionArea onClick={() => navigate(ROUTES.event(event.id))}>
                <CardContent sx={{textAlign:'center'}}>
                    <FullDate date={event.get('startAt')}/>
                </CardContent>
                <CardMedia>
                    <CdnImage src={event.get('capture') | event.get('image')} sx={{ maxHeight: 175 }}/>
                </CardMedia>
                <CardContent>
                    <Box sx={{ height: 110, overflow: 'hidden'}}>
                        <Typography gutterBottom variant="h6" component="div">
                            {event.get('name')}
                        </Typography>
                        <Typography gutterBottom variant="body2" fontSize="small" color="text.secondary">
                            Presented By: {event.get('presentedBy')}
                        </Typography>
                    </Box>
                    <Box sx={{ height: 80, overflow: 'hidden'}}>
                        <Typography fontSize="small">
                            {parser.parse(event.get('description'))}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}