export enum ClaimStatus {
    pending = 'pending',
    accepted = 'accepted',
    rejected = 'rejected',
    deleted = 'deleted',
}

export const claimStatusName = (status: ClaimStatus) => {
    switch (status) {
        case 'pending':
            return 'Pending';
        case 'accepted':
            return 'Accepted';
        case 'rejected': 
            return 'Rejected';
        case 'deleted':
            return 'Deleted';
        default:
            return '';
    }
}