import {useEffect, useReducer} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {EventDetailsForm} from 'components';
import {
  Loading,
  GenericObject,
  BackButton,
  CheckButton,
} from 'components';
import {reducer, validate, flash} from 'lib';
import {edit_event_schema} from 'schema';
import {DateTime} from 'luxon';
import { EventType } from '@kwixl/interface';

export const EventDetails = ({
    data,
    stream,
    onBack,
    onNext,
    ccs = false,
}:{
    data: GenericObject;
    stream: GenericObject;
    onBack: () => void;
    onNext: (e: GenericObject) => void;
    ccs?: boolean;
}) => { 

    const [{
        event,
        formErrors,
        loading = false,
    }, dispatch] = useReducer(reducer, {});

    useEffect(() => dispatch({ event: data }), [data]);

    const validateEvent = () => {

        // Manually set some fields based on event/stream type 
        if (stream?.type === 'embed' || ccs) {
            event.startAt = DateTime.utc().plus({ seconds: 15 }).toJSDate(); 
        }

        if (ccs) event.type = EventType.ccs;

        const startDate = DateTime.fromJSDate(event.startAt);

        if (!event.endAt) {
            event.endAt = startDate.plus({hour: 4}).toJSDate();
        }

        let errors: GenericObject = validate(event, edit_event_schema) || {};
    
        if (!errors) errors = {};

        if (startDate.toMillis() < DateTime.utc().toMillis()) {
            if (!errors.startAt) errors.startAt = 'Invalid start date';
        }
    
        console.log(errors);

        if (Object.keys(errors || {}).length) {
          dispatch({
            formErrors: errors,
            loading: false,
            event,
          });
          flash.error('Error saving event! Please correct the highlighted errors.');
          return;
        }
    
        onNext(event);
        
    };

    return (
        <>
            <DialogContent dividers>
                {loading && <Loading/>}
                {!loading && (
                    <EventDetailsForm
                        initialValue={{
                            ...(event || {}),
                            startAt: event?.startAt || DateTime.utc().plus({ minute: 1 }).toJSDate(),
                        }}
                        live={stream?.type === 'embed' ? true :  false}
                        lockType={true}
                        errors={formErrors}
                        onChange={(name = '', value = '') => {
                            delete formErrors?.[name];
                            dispatch({
                                event: {...event, [name]: value},
                                formErrors,
                            });
                        }}
                        ccs={ccs}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <BackButton sx={{ position: 'absolute', left: '10px' }} onClick={onBack}/>
                <CheckButton  onClick={validateEvent}>
                    Finish 
                </CheckButton>   
            </DialogActions>
        </>
    );
}