import {useEffect, useState, useRef, useReducer} from 'react';
import styled from '@emotion/styled';
import {ScreenShot, StatusBar, MicToggle, CameraControl, Countdown} from './components';
import screenfull from 'screenfull';
import {CdnImage, PrimaryButton, CheckButton, Chat} from 'components';
import { ChatProvider } from 'providers';
import {useEventContext, useVideoContext, useWindowSize} from 'hooks';
import {CameraWrapper} from './styled';
import {reducer} from 'lib';
import {VideoStatus} from '@kwixl/interface';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CancelIcon from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ButtonGroup from '@mui/material/ButtonGroup';

const initialState = {
  ready: false,
  dimensions: {
    aspectRatio: 1,
    width: 320,
    height: 560,
    left: 20,
    right: 20,
    videoWidth: 0,
    videoHeight: 0,
  },
  started: false,
  confirmModal: false,
  confirmModalText: '',
  facingMode: 'environment',
};

interface MobileBroadcastProps {
  onEvent?: (status?: string) => void;
  onExit?: () => void;
  children?: any;
}

export const Mobile = ({
  onEvent = (status?: string) => {}, 
  onExit = () => {},
}: MobileBroadcastProps) => {

  let resizeTimer: number;

  const size = useWindowSize();

  const {
    videoClient,
    localVideoTrack,
    videoStatus,
    stopStreaming,
    videoDevices,
  } = useVideoContext();

  const [
    {ready, started, confirmModal, confirmModalText, facingMode},
    dispatch,
  ] = useReducer(reducer, initialState);

  const {eventEnded} = useEventContext();

  //const [countdown, setCountdown] = useState<number>();
  const [starting, setStarting] = useState<boolean>(false);

  const confirmModalCallback = useRef<Function>();

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
      //stopStreaming(true);
      //onExit();
    };
  }, []);

  useEffect(() => {
    if (!onEvent) return;
    if (started) onEvent(VideoStatus.ready);
    if (videoStatus) onEvent(videoStatus);
  }, [started, onEvent, videoStatus]);

  useEffect(() => {
    if (!localVideoTrack) return;
    initVideo();
  }, [localVideoTrack]);

  useEffect(() => {
    (async () => {
      if (videoClient && eventEnded() === false) {
        dispatch({ready: true});
      }
    })()
  },[videoClient]);

  const initVideo = () => {
    try {
      localVideoTrack?.play('video-ref', {
        fit: 'cover',
        mirror: true,
      });
      //toggleMic(false);
    } catch (err) {
      console.error('Error playing video', err);
    }
  };

  const setConfirmModal = (open = false, text = '', callback = console.log) => {
    dispatch({confirmModalText: text});
    confirmModalCallback.current = callback;
    dispatch({confirmModal: open});
  };

  const handleWindowResize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(onResize, 200);
  };

  const onResize = (id: string) => {
    try {
      const videoTrack = localVideoTrack?.getMediaStreamTrack();
      if (videoTrack) {
        setVideoDimensions(videoTrack.getSettings());
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setVideoDimensions = (settings: MediaTrackSettings) => {

    const {
      aspectRatio = 1,
      width,
      height,
    } = settings || {};
    
    let videoWidth = window.innerWidth; //window.screen.width; //windowHeight * (16/9);

    // Portrait mode
    let videoHeight = Math.ceil(videoWidth / aspectRatio);

    let left = 0;

    if (window.screen.height > window.screen.width) {
      // portait mode
      if (screenfull.isEnabled && screenfull.isFullscreen) {
        videoHeight = size?.height || 0;
      } else {
        videoHeight = window.innerHeight; //window.screen.height;
        videoWidth = Math.ceil(videoHeight * aspectRatio); // videoHeight;
      }

      left = Math.ceil((videoWidth - window.innerWidth) / 2);
    } else {
      // landscape mode
    }

    // Update video container
    const bc = document.getElementById('broadcast-container');
    if (bc) {
      bc.style.width = `${videoWidth}px`;
      bc.style.height = `${videoHeight}px`;
      bc.style.marginLeft = `${left * -1}px`;
    }

    dispatch({
      dimensions: {
        aspectRatio,
        width,
        height,
        videoWidth,
        videoHeight,
      },
    });
  };

  const switchMobileCamera = async () => {
    const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
    const camera = videoDevices?.filter(
      device => {
        const { facingMode, label } = device;
        return facingMode === newFacingMode || 
        newFacingMode === 'user' 
          ? label.includes('front')
          : label.includes('rear') || label.includes('back')
      });
    await localVideoTrack?.setDevice(camera?.[0].deviceId);
    dispatch({facingMode: newFacingMode});
  };

  const toggleFullscreen = () => {
    if (screenfull.isEnabled) {
      const el = document.getElementById('broadcast-container');
      if (!el) return;
      screenfull.toggle(el);
    }
  }

  const {height = 0, width = 0} = useWindowSize() || {};

  return (
    <MobileVideoContainer id="mobile-video-container" height={height}>
      <CameraWrapper id="broadcast-container">
        <MobileVideoPlayback id="video-ref" />
        {started && (
          <>
            <StatusBar
              onExit={() => {
                setConfirmModal(
                  true,
                  'Are you sure you wish to exit the event?',
                  onExit,
                )
              }}
            />
            <Box sx={{ 
                position: 'absolute',
                right: `15px`,
                top: '70px',
                width: '60px',
              }}
            >
              <Box component="div"
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: '#121212',
                  opacity: .3,
                  borderRadius: '5px',
                }}
              />
              <Stack direction="column" spacing={1}>
                <CameraControl
                  countingDown={starting}
                  onStart={() => setStarting(true)}
                  onStop={() => {
                    setConfirmModal(
                      true,
                      'Are you sure you wish to stop the livestream?',
                      () => stopStreaming!(false)
                    )
                  }}
                />
                <ButtonGroup orientation="vertical">
                  <MicToggle/>
                  <IconButton
                    onClick={() => switchMobileCamera()}
                  >
                    <CameraswitchIcon fontSize="large" htmlColor='#fffff0'/>
                  </IconButton>
                  {screenfull.isEnabled && (
                    <IconButton 
                      onClick={() => toggleFullscreen()}
                    >
                      { screenfull.isFullscreen && <FullscreenExitIcon htmlColor='#fffff0' fontSize="large"/> }
                      { !screenfull.isFullscreen && <FullscreenIcon htmlColor='#fffff0' fontSize="large"/> }
                    </IconButton>
                  )}
                </ButtonGroup>
              </Stack>
            </Box>
          </>
        )}
        {/*started ? children : null*/}
        {confirmModal && (
          <>
            <FullscreenModal width={width} height={9999} />
            <FullscreenContainer width={width} height={height}>
              <div
                style={{
                  width: '50%',
                  margin: '0 auto 20px auto',
                  height: '300px',
                  textAlign: 'center',
                }}
              >
                <Paper sx={{ padding: '10px' }}>
                  <Box display="flex" justifyContent="center" mt={3} mb={3}>
                    {confirmModalText}
                  </Box>
                  <Box display="flex" sx={{ width: '100%' }} justifyContent="flex-end">
                    <Button
                      startIcon={<CancelIcon color="error"/>}
                      onClick={() => dispatch({confirmModal: !confirmModal})}
                    >
                      No
                    </Button>
                    <CheckButton
                      onClick={() => {
                        dispatch({confirmModal: !confirmModal});
                        if (
                          confirmModalCallback.current &&
                          typeof confirmModalCallback.current === 'function'
                        ) {
                          confirmModalCallback.current();
                        }
                      }}
                    >
                      Yes
                    </CheckButton>
                  </Box>
                </Paper>
              </div>
            </FullscreenContainer>
          </>
        )}
        <MobileChatWrapper id="mobile-chat-wrapper">
          <MobileChatContainer>
          <Box 
                flex={{ 
                    xs: '1 1 auto', 
                    md: '0 1 auto'
                }} 
                display='flex' 
                flexDirection='column' 
                overflow='hidden'
                flexGrow={1}
            >
              <ChatProvider>
                <Chat.Messages/>
                {/*<Box width={'95%'}>
                    <Chat.Form/>
              </Box>*/}
              </ChatProvider>
            </Box>
          </MobileChatContainer>
        </MobileChatWrapper>
        { starting && (
          <Countdown 
            open={starting}
            onDone={() => setStarting(false)}
          />
        )}
        {/*(typeof countdown === 'number' && countdown > 0) && (
          <MobileCountdown>
            <Header as="h2" style={{color: '#ffffff'}}>
              Get Ready!
            </Header>
            <MobileTimer>{countdown}</MobileTimer>
            <CounterActions>
              <CancelTimer onClick={() => setCountdown(undefined)}>Cancel</CancelTimer>
              {' '}|{' '}
              <SkipTimer onClick={() => setCountdown(0)}>Skip</SkipTimer>
            </CounterActions>
          </MobileCountdown>
        )*/}
        {/*starting && (
          <MobileCountdown>
            <Header as="h2" style={{color: '#ffffff'}}>
              <Loading>
                Starting Live Video...
              </Loading>
            </Header>
          </MobileCountdown>
        )*/}
      </CameraWrapper>
      {!started && (
        <>
          <FullscreenModal width={width} height={9999} />
          <FullscreenContainer width={width} height={height}>
            <Box
              sx={{
                width: '70%',
                margin: '0 auto 20px auto',
                textAlign: 'left',
              }}
              mb={3}
            >
              <Alert icon={false}>
                <Box display="flex" justifyContent='center'>
                  <CdnImage
                    src="/icons/android-icon-96x96.png"
                    permanent={true}
                    centered
                    height={72}
                    width={72}
                  />
                </Box>
                {window.screen.width < window.screen.height && (
                  <>
                    <p>
                      Please note that if you use this orientation for your
                      video, your viewers may see black bars on the sides of the
                      video.
                    </p>
                    <p>
                      For best results, turn your device to landscape mode
                      before starting your video.
                    </p>
                  </>
                )}
                {window.screen.width > window.screen.height && (
                  <>
                    <p>
                      If you turn your phone to portrait mode after starting the
                      video, your viewers may see black bars on the side of the
                      video.
                    </p>
                    <p>For best results, keep your device in landscape mode.</p>
                  </>
                )}
              </Alert>
              <Box mt={3}>
                <PrimaryButton
                  variant="contained"
                  fullWidth
                  startIcon={<ThumbUpIcon/>}
                  onClick={() => {
                    const el = document.getElementById('broadcast-container');
                    if (screenfull.isEnabled && el) {
                      screenfull
                        .request(el)
                        .then(() => {
                          if (screenfull.isEnabled) {
                            if (screenfull.isFullscreen) {
                              dispatch({started: true});
                              handleWindowResize();
                            }
                          }
                        })
                        .catch(() => {
                          alert('Could not enter full screen mode');
                          dispatch({started: true});
                        });
                    } else {
                      dispatch({started: true});
                    }
                  }}
                >
                  Got It
                </PrimaryButton>
              </Box>
            </Box>
          </FullscreenContainer>
        </>
      )}
      <Dialog maxWidth="sm" open={!ready && !eventEnded()}>
        <DialogContent>Initializing camera...please wait...</DialogContent>
      </Dialog>
      <ScreenShot/>
    </MobileVideoContainer>
  );
};

const MobileVideoPlayback = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  ${props => (props.hidden ? 'display: none' : '')};
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
`;

interface FullscreenProps {
  height?: number;
  width?: number;
}

const FullscreenModal = styled.div<FullscreenProps>`
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9;
  opacity: 0.5;
  color: #ffffff;
  background-color: #000000;
  height: ${props => (props.height ? `${props.height}px` : '100%')};
  width: ${props => (props.width ? `${props.width}px` : '100%')};
`;

const FullscreenContainer = styled.div<FullscreenProps>`
  top: 0;
  left: 0;
  text-align: center;
  position: absolute;
  z-index: 10;
  color: #000000;
  padding-top: ${props => ((props.height || 0) > (props.width || 0) ? '35%' : '10%')};
  height: ${props => (props.height ? `${props.height}px` : '100%')};
  width: ${props => (props.width ? `${props.width}px` : '100%')};
`;

interface MobileVideoContainerProps {
  height?: number;
}

const MobileVideoContainer = styled.div<MobileVideoContainerProps>`
  position: relative;
  overflow: hidden;
  height: ${props => props.height}px;
  width: 100%;
`;

const MobileChatWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 0;
  height: 75vh;
  max-height: ${window.screen.width > window.screen.height ? '320' : '350'}px;
  width: 100%;
  max-width: 560px;
  z-index: 2;
  overflow: hidden;
`;

const MobileChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
`;