import {useReducer, useEffect} from 'react';
import {reducer} from 'lib';
import {useStripe} from '@stripe/react-stripe-js';
import {Loading, Money, GenericObject, PrimaryButton, CancelButton, IntlText} from 'components';
import {flash} from 'lib';
import {useFirebaseContext, useIntlText, useRefs, useSessionContext} from 'hooks';
import {getDoc} from 'firebase/firestore';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const initialState = {
  //cards: [],
  last4: null,
  exp_month: null,
  exp_year: null,
  brand: null,
  paymentMethod: null,
  name: '',
  phone: '',
  email: '',
  saveCard: false,
  ready: false,
  setup_intent: null,
  intent: null,
  loading: false,
};

interface Props {
  open: boolean;
  onSuccess: (data: GenericObject) => void;
  onClose: () => void;
  settings: GenericObject;
}

export const StripeModal = ({
  open = false,
  onSuccess,
  onClose,
  settings,
}: Props) => {

  const stripe = useStripe();

  const {intlText} = useIntlText();

  const {
    callable, 
    firebaseUser,
  } = useFirebaseContext();

  const {organization} = useSessionContext();
  
  const {
    merchantAccountRef,
    billingAccountRef,
  } = useRefs();

  const [
    {
      last4,
      exp_month,
      exp_year,
      paymentMethod,
      name,
      phone,
      email,
      ready,
      loading,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  useEffect(() => {
    init();
  }, []);

  const setupPayment = async (payment_method: any) => {
    if (!payment_method) payment_method = paymentMethod;
    let billing_details: GenericObject = {};
    if (name) billing_details.name = name;
    if (phone) billing_details.phone = phone;
    if (email) billing_details.email = email;
    /*
    const options =
      payment_method === 'new' || payment_method === ''
        ? {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details,
            },
            setup_future_usage: 'off_session',
          }
        : {
            payment_method,
          };
          */
    const {data, errors} = await callable!('payment-intent', {
      ...settings,
      paymentMethod,
    });
    if (!data || errors) {
      flash.error(intlText('payment_initialize_error'));
      onClose();
      return;
    }
    return data;
  };

  const getCards = async () => {
    dispatch({ready: false});
    const account =
      settings?.type === 'billing'
        ? await getDoc(merchantAccountRef!(organization?.id || 'x'))
        : await getDoc(billingAccountRef!(firebaseUser?.uid || 'x'));
    if (!account.exists() || !account.get('paymentId')) {
      flash.error(intlText('payment_setup'));
      onClose();
      return;
    }
    const userInfo = {
      name: firebaseUser?.displayName,
      phone: firebaseUser?.phoneNumber,
      email: firebaseUser?.email,
    };
    const card = account.get('card');
    const {brand, exp_month, exp_year, last4} = card || {};
    dispatch({
      paymentMethod: account.get('paymentId'),
      brand,
      exp_month,
      exp_year,
      last4,
      ...userInfo,
      ready: true,
    });
  };

  const init = async () => {
    await getCards();
  };

  const confirmPayment = async () => {
    dispatch({loading: true});

    const payment_intent = await setupPayment(paymentMethod);

    try {
      const {error, paymentIntent} = await stripe!.confirmCardPayment(
        payment_intent.key,
        {
          payment_method: payment_intent.card,
        }
      );
      if (error?.message) {
        flash.error(error.message);
        console.error(error.message);
      } else {
        switch (paymentIntent?.status) {
          case 'succeeded':
            const {data} = await callable!('payment-finalize', {
              id: payment_intent.paymentId,
            });
            onSuccess(data);
            flash.success(intlText('payment_successful'));
            break;
          default:
            break;
        }
        onClose();
      }
    } catch (err) {
      flash.error(intlText('payment_error'));
      onClose();
    }
    dispatch({loading: false});
  };

  if (!ready) return <Loading />;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {(() => {
          switch (settings?.type) {
            case 'ticket':
              return (
                <>
                  <IntlText id="confirm_ticket_purchase"/> (
                  <Money value={settings.amount} />)
                </>
              );
            default:
              return (
                <>
                  <IntlText id="confirm_payment"/> (
                  <Money value={settings.amount} />)
                </>
              );
          }
        })()}
        <CloseIcon sx={{float: 'right'}} onClick={onClose} />
      </DialogTitle>
      <DialogContent dividers>
        <p>
          <IntlText id="payment_method_notice"/>
        </p>
        <div style={{textAlign: 'center', width: '50%', margin: '0 auto'}}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <IntlText id="card"/>
                </TableCell>
                <TableCell>
                  ...{last4}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <IntlText id="expires"/>
                </TableCell>
                <TableCell>
                  {exp_month}/{exp_year}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <p style={{textAlign: 'justify', fontSize: 'smaller'}}> 
            <IntlText id="payment_final" values={{ seller: settings?.vendor ? settings.vendor : 'the seller'}}/>
          </p>
          {/*
            <Dropdown
              selection
              placeholder="Select saved card"
              name="saved_card"
              value={paymentMethod}
              onChange={(e, { value }) => {
                dispatch({ paymentMethod: value });
              }}
              options={[].concat(
                cards.map((card) => {
                  return {
                    key: card.id,
                    text: `${card.brand.toUpperCase()} ...${card.last4} ${
                      card.exp_month
                    }/${card.exp_year}`,
                    value: card.id,
                  };
                }),
                [
                  {
                    key: "0",
                    text: "Use a new card",
                    value: "new",
                  },
                ]
              )}
            />
              */}
        </div>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={() => onClose()} disabled={loading}/>
        <PrimaryButton
          loading={loading}
          disabled={loading}
          startIcon={<AttachMoneyIcon/>}
          onClick={() => confirmPayment()}
        >
          <IntlText id="confirm_payment"/>
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
