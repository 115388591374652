import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';

export interface DashboardCardProps {
    id: string;
    title: string;
    icon: JSX.Element;
    link?: string | null;
    action?: () => void;
    description: string | JSX.Element;
}
export const DashboardCard = ({ card }: { card: DashboardCardProps }) => {
  
    const navigate = useNavigate();
  
    const {
      id,
      title,
      icon,
      link,
      description,
      action = () => alert('Error'),
    } = card;
    
    return (
      <Grid item xs={12} md={3}>
        <Card key={id}>
          <CardActionArea onClick={() => (link ? navigate(link) : action()) }>
            <CardMedia sx={{textAlign:'center'}}>
              {icon}
            </CardMedia>
            <CardHeader title={title} sx={{textAlign:'center'}}/>
            <CardContent>
                {description}
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    )
}