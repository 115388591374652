import Box from '@mui/material/Box';
import { Chat } from 'components';

import { ChatProvider } from 'providers';

export const ChatScreen = () => {

    return (
        <Box 
            id="mobile-chat-wrapper"
            sx={{
                position: 'absolute',
                bottom: '16px',
                left: 0,
                height: '50vh',
                width: '100%',
                maxWidth: '560px',
                zIndex: 5,
                overflow: 'hidden',
            }}
        >
            <Box
                id="mobile-chat-container"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: '100%',
                    flexGrow: 1,
                    overflow: 'hidden'
                }}
            >
                <Box 
                    id="chat-flex"
                    flex={{ 
                        xs: '1 1 auto', 
                        md: '1 1 auto'
                    }} 
                    display='flex' 
                    flexDirection='column' 
                    overflow='hidden'
                    flexGrow={1}
                >
                    <ChatProvider>
                        <Chat.Messages/>
                        <Box width={'95%'}>
                            <Chat.Form/>
                        </Box>
                    </ChatProvider>
                </Box>
            </Box>
        </Box>
    )

}