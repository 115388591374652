import {createContext, PropsWithChildren} from 'react';
import {GenericObject} from "components";
import {useIntl} from 'react-intl';

type MessageContext = {
  intlText: (id: string, values?: GenericObject) => string,
}
export const IntlMessageContext = createContext<MessageContext | undefined>(undefined);

export const IntlMessageProvider = ({children}: PropsWithChildren ) => {

  const intl = useIntl();

  const intlText = (id: string, values = {}): string => 
    intl.formatMessage({ id }, { app: process.env.REACT_APP_NAME || '', facebook: 'Facebook', ...values }) as string

  return (
    <IntlMessageContext.Provider value={{
      intlText,
    }}>
      {children}
    </IntlMessageContext.Provider>
  );

};
