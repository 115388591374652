import {useEffect, useReducer} from 'react';
import {useNavigate} from 'react-router-dom';
import {useFirebaseContext, useFacebookContext, useIntlText, useRefs} from 'hooks';
import styled from '@emotion/styled';
import {flash, reducer} from 'lib';
import {ROUTES} from 'routes';
import {AuthProviders} from '@kwixl/interface';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import {
  EmailLogin, 
  GoogleLogo, 
  IntlText,
  GoogleButton,
  FacebookButton,
  GoogleText,
  FacebookText,
  GenericObject,
  CdnImage,
} from 'components';
import {setDoc, serverTimestamp, getDoc} from 'firebase/firestore';
import Box from '@mui/material/Box';

import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import type {UserCredential} from 'firebase/auth';

const defaultProviders = [
  AuthProviders.password,
  AuthProviders.facebook,
  AuthProviders.google,
];

interface Props {
  onSuccess?: (result: UserCredential) => void; 
  onError?: (result: any) => void;
  providers?: string[] | { id: string, action?: string }[];
  link?: boolean;
  signup?: boolean;
  permissions?: string[] | null | undefined,
}

const initialState = {
  loginProviders: defaultProviders,
  error: null,
}

const EXISTING_CREDENTIAL = 'auth/account-exists-with-different-credential';

export const OAuthLogin = ({
  onSuccess = (result) => {},
  onError = (result) => {},
  providers = defaultProviders,
  link = false, 
  signup = false,
  permissions,
}: Props) => {

  const {intlText} = useIntlText();

  const [{
    error,
    loginProviders,
  }, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ loginProviders: providers });
  }, [providers]);

  const {
    auth, 
    firebaseUser,
  } = useFirebaseContext();

  const {
    userProfileRef,
    facebookUserRef,
  } = useRefs();

  const {linkFacebookAccount, signInWithFacebook, basicPermissions} = useFacebookContext();

  const navigate = useNavigate();

  const handleFacebookUser = async (result: UserCredential) => {
    if (!result) return;
    const provider = result.user.providerData.find(({ providerId }) => providerId === AuthProviders.facebook);
    if (!provider) return;
    await setDoc(facebookUserRef!(provider.uid || 'x'), {
      createdAt: serverTimestamp(),
      displayName: result.user.displayName,
      email: result.user.email,
      fbId: provider.uid,
      lastLogin: serverTimestamp(),
      photoURL: result.user.photoURL,
      uid: result.user.uid,
    },{
      merge: true
    });
  }

  const handleLoginResult = async (result: UserCredential, provider: any) => {
    const profile = await getDoc(userProfileRef!(result.user.uid || 'x'));
    if (!profile.exists()) {
      try {
        const [firstName, lastName] = (result.user.displayName || '').split(
          ' '
        );
        let verified = false;
        switch (provider.providerId) {
          case AuthProviders.facebook:
          case AuthProviders.google:
            verified = result.user.email ? true : false;
            break;
          default:
            break;
        }
        const data = {
          firstName,
          lastName,
          createdAt: serverTimestamp(),
          orgId: result.user.uid,
          verified,
          photoURL: result.user.photoURL,
        };
        await setDoc(userProfileRef!(result.user.uid || 'x'), data);
        if (provider.providerId === AuthProviders.facebook) {
          await handleFacebookUser(result);
        }
        if (!verified) {
          navigate(ROUTES.confirm, {state: {user: result.user}});
        }
      } catch(err: any) {
        flash.error(`Error: ${err.message}`);
        return;
      }
    }
    if (provider.providerId === AuthProviders.facebook) {
      await handleFacebookUser(result);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth!, provider);
      await handleLoginResult(result, provider);
      if (error && error.code === EXISTING_CREDENTIAL) {
        dispatch({ 
          error: null,
          loginProviders: providers,
        });
        return;
      }
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(result);
      } else {
        navigate(ROUTES.me);
      }
    } catch(err: any) {
      console.log(err);
      window.scrollTo(0, 0);
    }
  };

  const handleFacebookSignInError = async(err: any) => { 
    dispatch({
      error: err,
    });
  }

  const facebookSignin = async () => {
    try {
      const { result } = await signInWithFacebook!(handleFacebookSignInError) || {};
      await handleFacebookUser(result);
      onSuccess(result);
    } catch(err: any) {
      console.log(err);
      window.scrollTo(0, 0);
    }
  };

  const linkFacebook = async (permissions = basicPermissions, useSuccess = true) => {
    if (!firebaseUser) {
      flash.error(intlText('login_to_link'));
      return;
    }
    try {
      const result = await linkFacebookAccount!();
      await handleFacebookUser(result);
      if (useSuccess) {
        onSuccess(result);
      } else {
        dispatch({ error: result });
        onError(result);
        return result;
      }
    } catch(err: any) {
      console.log(err);
      window.scrollTo(0, 0);
      onError(err);
      dispatch({ error: err });
    }
  };

  const showLink = link || firebaseUser;

  return (
    <>
      {error && 
        <Alert severity="error">
          <IntlText id="signin_error"/>
          {error.code === EXISTING_CREDENTIAL 
            ? <>
                <p>
                  <IntlText id="email_linked"/>
                </p>
                <p>
                  <IntlText id="try_other_provider"/>
                </p>
              </>
            : <p>
                <IntlText id="email_maybe_linked"/>
              </p>
          }
        </Alert>
      }
      {[AuthProviders.password].map((provider: string | GenericObject) => {
        let providerId;
        let label;
        if (typeof provider === 'string') {
          providerId = provider;
        } else {
          const { id, action } = provider;
          providerId = id;
          label = action;
        }
        switch (providerId) {
          case AuthProviders.google: {
            return (
              <ButtonContainer>
                <GoogleButton onClick={signInWithGoogle}>
                    <Box mr={2}>
                      <GoogleLogo/>
                    </Box>
                    {showLink ? (
                      <IntlText id="link_account" values={{ provider: <GoogleText/> }}/>
                    ) : (
                      <IntlText id="login_with" values={{ provider: <Box sx={{marginLeft:'.2em'}}><GoogleText/></Box> }}/>
                    )}
                </GoogleButton>
              </ButtonContainer>
            );
          }
          case AuthProviders.facebook: {
            return (
              <ButtonContainer>
                <FacebookButton
                  onClick={() => (showLink ? linkFacebook(permissions = []) : facebookSignin())}
                >
                  <CdnImage 
                    src="/img/f_logo_RGB-White_58.png"
                    width={30}
                  />
                  <Box ml={1}>
                    <StyledLink>
                      {showLink ? (
                        <IntlText id="link_account" values={{ provider: <FacebookText/> }}/>
                      ) : label ? ( 
                            <IntlText id={label} values={{ provider: <FacebookText/> }} />
                        ) : (
                          <IntlText id="login_with" values={{ provider: <FacebookText/> }}/>
                      )}
                    </StyledLink>
                  </Box>
                </FacebookButton>
              </ButtonContainer>
            );
          }
          case AuthProviders.password: {
            return ( 
              <>
                <EmailLogin/>
                {loginProviders?.length > 1 && <Divider/>}
              </>
            ) 
          }
        }
      })}
    </>
  );
};

const ButtonContainer = styled.div`
  display: block;
  margin-bottom: 20px;
`;

const StyledLink = styled.span`
  color: #ffffff;
`

