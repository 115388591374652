
export const InventorySourceTypes = ['none', 'event', 'post', 'store'] as const;
export type InventorySourceType = typeof InventorySourceTypes[number];

export enum InventorySourceTypesEnum {
    none = 'none',
    event = 'event',
    post = 'post',
    store = 'store',
}

export interface InventorySource {
    id: string;
    type: InventorySourceType;
    name: string;
    date?: Date;
}