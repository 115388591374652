import { GenericObject } from 'components';
export const EmbedModal = ({
    settings, 
    onClose = () => {},
    basic = false,
}:{
    settings: GenericObject;
    onClose?: () => void;
    basic?: boolean;
}) => <></>
/*
import {useReducer, useEffect} from 'react';
import { PrimaryButton, Loading, GenericObject, CancelButton } from 'components';
import {reducer, flash} from 'lib';
import {useRefs, useSessionContext} from 'hooks';
import {getDoc, serverTimestamp, updateDoc} from 'firebase/firestore';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import Button from '@mui/material/Button';

export const EmbedModal = ({
    settings, 
    onClose = () => {},
    basic = false,
}:{
    settings: GenericObject;
    onClose?: () => void;
    basic?: boolean;
}) => {

    const {type} = settings || {};

    const [{
        ready = false,
        open = false,
        embedCode = '',
        token = '',
    }, dispatch] = useReducer(reducer, {});

    const {storeRef} = useRefs();

    const { mobile, organization } = useSessionContext();

    useEffect(() => {
        (async () => {
            if (!type || !organization) return;
            let store = await getDoc(storeRef!(organization.id || 'x'));
            if (store.exists()) {
                if (!store.get('token')) {
                    await updateDoc(storeRef!(organization.id || 'x'), { updatedAt: serverTimestamp() });
                    store = await getDoc(storeRef!(organization.id || 'x'));
                    if (!store.get('token')) {
                        flash.error('No API token set for store. Please wait a few seconds and try again.  If you do not receive a token after serveral tries, please contact support.');
                        close();
                        return;
                    }
                }
                dispatch({ 
                    token: store.get('token'),
                })
            } else {
                flash.error('Error reading store information!');
                close();
            }
        })()
    },[type, organization]);

    useEffect(() => {
        if (!token) return;
        dispatch({
            embedCode: buildEmbedCode(),
            ready: true,
        })
    },[token]);

    const close = () => {
        dispatch({ open: false });
        onClose();
    };

    const widget = () => {
        let result = `<div class="kwixl-widget" data-type="${type}"`;
        switch (type) {
            case 'events':
                break;
            case 'event':
                result += ` data-id="${settings.id}"`;
                break;
        }
        result += '/>';
        return result;
    }

    const buildEmbedCode = () => {
        let comment = '';
        switch (type) {
            case 'event':
                comment = 'event';
                break;
            case 'events':
                comment = 'event list';
                break;
            default:
                comment = 'widget';
        }
        return `<!-- Place this element where you want the ${comment} to appear on the page //-->\n${widget()}\n\n<!-- Place this script tag at the bottom of the page.  Only one script tag is needed per page. //-->\n<script async id="kwixl-script" type="text/javascript" src="${process.env.REACT_APP_CDN}/widgets/embed/index.js?apiKey=${organization?.id}.${token}">Javascript must be enabled.</script>`;
    }

    const copyText = async () => {
        try {
            await navigator.clipboard.writeText(embedCode);
            flash.success('Copied!');
        } catch (err: any) {
            flash.error("Error copying link! " + err.message);
        }
    }

    const Trigger = () => (
        <Button size={mobile ? 'small' : 'medium'} disabled={open} variant={ basic ? 'text' : 'outlined' } color={ basic ? 'primary' : "secondaryAction"} startIcon={<FilePresentOutlinedIcon/>} onClick={() => dispatch({ open: true })}>
            Embed
        </Button>
    )

  return (
    <>
    <Trigger/>
    <Dialog 
        maxWidth={'lg'} 
        open={open}
        onClose={close}
        disableEscapeKeyDown={true}
        scroll={'paper'}
    >
      <DialogTitle style={{textAlign: 'center'}}>
        Embed {type === 'events' ? 'Your Events' : 'Event'}
      </DialogTitle>
      <DialogContent>
        <p style={{marginBottom: '10px'}}>Copy and paste the code below into a page where you would like to advertise {type === 'event' ? 'this event' : 'your events'}.</p>
        {ready && embedCode && token 
            ?   <>
                    <TextField 
                        fullWidth
                        disabled
                        multiline
                        minRows={8}
                        maxRows={12}
                        value={embedCode}
                    />
                </>
            :   <Loading/>
        }
      </DialogContent>
      <DialogActions>
        <CancelButton close onClick={close}/>
        {embedCode && (
            <PrimaryButton
                startIcon
                onClick={copyText}
            >
                <ContentCopyIcon/> Copy Embed Code
            </PrimaryButton>
        )}
      </DialogActions>
    </Dialog>
    </>
  );
};
*/
