import Joi from "joi";

export const password_schema = Joi.object({
  password: Joi.string().min(8).max(25).required().messages({
    "string.min": `"Password" should have a minimum length of {#limit} characters`,
    "string.max": `"Password" should have a maximum length of {#limit} characters`,
    "any.required": `"Password" is a required field`,
  }),
  password2: Joi.string().min(8).max(25).valid(Joi.ref("password")).messages({
    "string.min": `"Confirmation password" should have a minimum length of {#limit} characters`,
    "string.max": `"Confirmation password" should have a maximum length of {#limit} characters`,
    "any.required": `"Confirmation password" is a required field and must match the Password field`,
    "any.only": `"Confirmation password" must match Password`,
  }),
});
