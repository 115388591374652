/**
 * Make sure all words are separated by only 1 space (if spaces) 
 * and return trimmed word parts
 * @param message 
 * @returns 
 */
export const prepClaim = (message: string) =>
  message
    .replace(/\s\s+/g, ' ')
    .trim()
    .toLowerCase()
    .split(' ')
    .map(part => part.trim());

/**
 * Find the position of the keyword that indicates which 
 * lot(s) (item(s)) the buyer is trying to claim
 * @param parts 
 * @returns 
 */
export const findLotKeywordIndex = (parts: string[]) => {
  let index = 0;
  let foundIndex = -1;
  // Trying \B as it matches the keywords between any word/non-word chars 
  // whereas \b only matches on word char boundaries
  while (index < parts.length && foundIndex < 0) {
    //let matches = parts[index].match(/\Blot\B|\Bitem\B|\Bitm\B|\B\#\B/gi);
    //let matches = parts[index].match(/\blot\b|\bitem\b|\bitm\b|\b\#\b/gi);
    let matches = parts[index].match(/lot|item|itm|product|\#/gi);
    if ((matches && matches.length) || parts[index] === '#') {
      foundIndex = index;
    }
    index++;
  }
  return foundIndex;
};

/**
 * Iterate through multiple variations of keyword matching 
 * and return all the results.  The intent is that the calling 
 * function will then iterate over the matches to try and start 
 * parsing the rest of the message to see if it is a claim.
 * @param parts 
 */
export const findLotKeywords = (parts: string[]) => {
  const keywords = [
    'lot',
    'item',
    'itm',
    'product',
    '#'
  ];
  const rules = [
    ['', ''],
    ['b', 'b'],
    ['B', 'B'],
    ['b', 'B'],
    ['B', 'b'],
  ]
  let results: string[] = parts.reduce((arr: string[], part: string) => {
    let matches: string[] = [];
    for (const rule of rules) {
      const regex = `${keywords.map(kw => `${rule[0] ? `\\${rule[0]}` : ''}${kw}${rule[1] ? `\\${rule[1]}` : ''}`).join('|')}`;
      const re = new RegExp(regex, 'gi');
      const m: string[] = part.match(re) || [];
      if (m.length) matches = matches.concat(m);
    }
    return matches.length ? arr.concat(matches) : arr;
  }, []);
  return results;

}

const hasTriggerWord = (parts: string[], exp: any) => {
  let index = 0;
  let triggerIndex = -1;
  while (index < parts.length) {
    const t = parts[index]
    let matches = t.toLowerCase()
      .trim()
      .match(exp);
    if (matches && matches.length > 0) {
      if (triggerIndex === -1 || index === triggerIndex + 1) {
        triggerIndex = index;
      }
    }
    index++;
  }
  return triggerIndex;
};

const hasClaimKeyword = (parts: string[]) => hasTriggerWord(parts, /plz|buy|have|haz|purchase|dib|grab|take|claim|clm|klm|klaim|klam|clam|clot|klot|swoop|sold|purchase/g);
//const hasProductKeyword = (parts: string[]) => hasTriggerWord(parts, /lot|item|itm|product|sku/g);

const couldBeClaim = (parts: string[]) => {
  const triggerIndex = hasClaimKeyword(parts);
  if (triggerIndex >= 0) {
    return true;
  }
  // Test 2: Check if there are more than 2 numbers
  let count = 0;
  parts.forEach(part => {
    if (parseFloat(part.trim().replace(/[^\d.]/g, '')) > 0) {
      count++;
    }
  });
  return count > 2;
};

const couldBeQuestion = (parts: string[]) => {
  if (!parts || !parts.length) return false;
  const m = parts.join(' ').toLowerCase().trim();
  const questionWords = ['what', 'who', 'where', 'why', 'when', 'how', 'whose'];
  if (questionWords.indexOf(parts[0]) >= 0) return true;
  if (m[m.length - 1] === '?') return true;
  return false;
};

export const parseClaim = (message: string, simple = false) => {

  let lot: string | null = null;
  let qty: number | null = null;
  let price: number | null = null;
  let simplified: boolean = false;
  let triggerIndex = -1;
  let index = 0;

  // remove 2+ spaces
  let parts = prepClaim(message);

  const reset = () => {
    index = 0;
    triggerIndex = -1;
  };

  const done = (reset = false) => {
    if (reset) {
      lot = null;
      price = null;
      qty = null;
      simplified = false;
    }
    return {
      type: reset ? null : 'claim',
      lot: lot ? lot.trim() : null,
      price,
      qty,
      simplified,
    };
  };

  // filter out all trigger words
  triggerIndex = hasClaimKeyword(parts);

  /*
  let productKeywordIndex = hasProductKeyword(parts);
  while (productKeywordIndex >= 0) {
    console.log(productKeywordIndex, parts);
    parts = parts.slice(productKeywordIndex + 1);
  }
  */

  // if doing simple claims, the format should be {keyword} {lot#} 
  // ex. sold 100, purchase 100, buy 100
  if (simple && triggerIndex >= 0) {
    if (triggerIndex >= 0 && parts.length > 1 && triggerIndex < parts.length - 1) {
      let itemIndex = findLotKeywordIndex(parts);
      if (itemIndex >= 0) parts.splice(itemIndex, 1);
      qty = 1;
      price = null;
      lot = parts[triggerIndex+1].replace(/\s|\#/g, ''); 
      if (lot) {
        simplified = true;
        return done();
      }
    }
  }

  // If not triggered by a claim word, see if there is a hashtag
  if (
    triggerIndex < 0 &&
    message.indexOf('#') < 0 &&
    message.indexOf('$') < 0
  ) {
    return done();
  }

  // trim to the purchase keywords and now check for any
  // articles leading up to the item to be purchased
  if (triggerIndex >= 0) {
    parts = parts.slice(triggerIndex + 1);
  }

  // check for any item keywords
  triggerIndex = findLotKeywordIndex(parts);

  // if a lot keyword trigger is found, assume the word AFTER is the item
  if (triggerIndex > -1) {
    lot = parts[triggerIndex + 1];
    // get everything after the lot trigger word and item
    if (lot.indexOf('$') > -1) {
      const [tempLot, tempPrice] = lot.split('$');
      if (tempLot) lot = tempLot.replace(/\s|\#|@/gi, '');
      try {
        if (parseFloat(tempPrice) > 0) {
          price = parseFloat(tempPrice);
        }
      } catch (err) {}
    }
    parts = parts.slice(triggerIndex + 2);
  }

  // remove any articles
  parts = parts.filter(part => {
    let matches = part
      .toLowerCase()
      .trim()
      .match(
        /\bfr\b|\bplease\b|\bcan\b|\bi\b|\bhave\b|\bhaz\b|\bfor\b|\bon\b|\bto\b|\bat\b|\bthe\b|\ba\b|\ban\b|\bone\b|\bof\b|\bx\b|\b@\b/gi
      );
    return !matches || matches.length < 1;
  });

  // see if it's a claim like "claim lot 100$20"
  if (message.indexOf('$') > -1) {
    // check for index greater than zero so that we don't catch
    // phrases like "claim lot 1 $20", etc.  We are looking only
    // for things like "claim 1$1"
    triggerIndex = parts.findIndex(part => part.indexOf('$') > 0);
    if (triggerIndex >= 0) {
      let part = parts[triggerIndex];
      const [tempLot, tempPrice] = part.split('$');
      if (tempLot) lot = tempLot.replace(/\s|\#|@/gi, '');
      try {
        if (parseFloat(tempPrice) > 0) {
          price = parseFloat(tempPrice);
        }
      } catch (err: any) {
      }
      if (lot && price) {
        // remove lot/price trigger
        parts.splice(triggerIndex, 1);
      }
    }
  }

  // stop here if there are no more parts
  if (!parts.length) {
    qty = 1;
    return done();
  }

  if (!lot) {
    // assume lot is first part
    lot = parts?.shift()?.replace(/\s|\#|@/gi, '') || null;
  }

  // filter out lot/item keywords
  /*
    parts = parts.filter(part => {
        let matches = part.trim().match(/\blot\b|\bitem\b|\bitm\b/gi);
        console.log('matches for', part, matches);
        return (!matches || !matches.length);
    })

    console.log('After lot keyword filter parts is now', parts);
    */
  // first let's set if any of the parts have a hash (#) as this
  // will be shorthand for identifying a lot
  reset();

  while (index < parts.length && triggerIndex < 0) {
    let part = parts[index].trim();
    if (part.indexOf('#') === 0) {
      if (part === '#' && index + 1 < parts.length) {
        triggerIndex = index + 1;
      } else {
        triggerIndex = index;
      }
    }
    index++;
  }

  // hash found
  if (triggerIndex > -1) {
    parts = parts.slice(triggerIndex);
    lot = parts?.shift()?.replace(/\s|\#|@/gi, '') || null;
  } else {
    // see if a lot trigger word such as 'lot', 'item', etc. is found
    // we'll take the next word after the trigger (if found) as the lot
    // if there wasn't a hash tag to identify the lot, try finding it manually
    index = 0;
    while (index < parts.length && triggerIndex < 0) {
      let matches = parts[index].trim().match(/\blot\b|\bitem\b|\bitm\b/gi);
      if (matches && matches.length) {
        triggerIndex = index;
      }
      index++;
    }
    if (triggerIndex > -1) {
      parts = parts.slice(triggerIndex + 1);
      lot = parts?.shift()?.replace(/\s|\#|@/gi, '') || null;
    }
  }

  // now try to get price
  reset();

  while (index < parts.length && triggerIndex < 0) {
    try {
      price = parseFloat(parts[index].replace(/[^\d.]/g, ''));
      if (price && price > 0) {
        triggerIndex = index;
      }
      //c;
    } catch (err) {}
    index++;
  }

  if (!price || triggerIndex < 0) return done(true);

  // remove price
  parts.splice(triggerIndex, 1);

  // filter out any quantity qualifiers
  parts = parts.filter(part => {
    let matches = part.trim().match(/\bx\b|\b\#\b|\b@\b/gi);
    return !matches || !matches.length;
  });

  // We have lot and price, set a default qty of 1
  // and then check if qty was specified
  qty = 1;
  reset();
  if (parts.length > 0) {
    index = 0;
    while (index < parts.length && triggerIndex < 0) {
      let part = parts[index].replace(/\D/g, '');
      if (part !== '') {
        let num = parseInt(part, 10);
        if (num > 0) {
          qty = num;
          triggerIndex = index;
        }
      }
      index++;
    }
    if (triggerIndex > -1) {
      parts.splice(triggerIndex, 1);
    }
  }

  // simple total check
  triggerIndex = parts.indexOf('=');
  if (triggerIndex >= 0) {
    parts = parts.slice(0, triggerIndex);
  }
  // one last filter...
  parts = parts
    .filter(part => {
      let matches = part
        .trim()
        .match(/\bqty\b|\bquantity\b|\bplease\b|\bplz\b|\=/gi);
      return !matches || !matches.length;
    })
    .filter(part => {
      let num = parseFloat(part.trim());
      return isNaN(num) || num < 0;
    });

  // lot is whatever text is left over
  if (parts.length > 0) {
    if (!lot) lot = '';
    if (triggerIndex >= 0) {
      lot +=
        ' ' +
        parts
          .slice(0, triggerIndex)
          .join(' ')
          .trim()
          .replace(/!|\#|@/, '');
    } else {
      lot +=
        ' ' +
        parts
          .join(' ')
          .trim()
          .replace(/!|\#|@/, '');
    }
  }

  return done();
};

export const parseBid = (message: string, simple = false) => {

  let lot: string | null;
  let price: number | null;
  let index = 0;
  let triggerIndex = -1;

  const reset = () => {
    index = 0;
    triggerIndex = -1;
  };

  const done = (reset = false) => {
    if (reset) {
      (lot = null), (price = null);
    }
    const qty = reset ? null : 1;
    return {
      type: reset ? null : 'bid',
      lot: lot ? lot.trim() : null,
      price,
      qty,
    };
  };

  let parts = prepClaim(message);

  if (couldBeQuestion(parts)) {
    return done(true);
  }

  // see if this is a claim
  if (couldBeClaim(parts)) {
    return parseClaim(message, simple);
  }

  // make sure there are not more than 2 numbers
  const numberCount = parts.filter((part: string) => parseFloat(part) > 0);
  // maybe trying to claim?
  if (numberCount.length > 2) {
    return done(true);
  }

  // See if first element is a number
  while (index < parts.length && triggerIndex < 0) {
    try {
      price = parseFloat(parts[index].replace(/\$/g, ''));
      if (price > 0) {
        triggerIndex = index;
      }
    } catch (err: any) {
    }
    index++;
  }

  if (triggerIndex < 0) {
    return done(true);
  }

  // remove price
  parts.splice(triggerIndex, 1);

  // make sure this isn't a question or statement
  reset();
  while (index < parts.length && triggerIndex < 0) {
    let matches = parts[index].trim().match(/\bis\b/gi);
    if (matches && matches.length > 0) {
      triggerIndex = index;
    }
    index++;
  }

  if (triggerIndex >= 0) {
    return done(true);
  }

  reset();
  // find trigger index for bidding keyword
  while (index < parts.length && triggerIndex < 0) {
    let matches = parts[index].trim().match(/bid|dib/g);
    if (matches && matches.length > 0) {
      triggerIndex = index;
    }
    index++;
  }

  if (triggerIndex >= 0) {
    parts = parts.slice(triggerIndex + 1);
  }

  // remove any articles
  parts = parts.filter((part: string) => {
    let matches = part.trim().match(/\bfor\b|\bon\b|\bto\b|\bat\b|\bthe\b/i);
    return !matches || matches.length < 1;
  });

  lot = parts
    .join(' ')
    .trim()
    .replace(/!|\#|@/, '');

  if (lot && lot !== '') {
    return done();
  }

  if (parts.length < 1) {
    return done(true);
  }

  // reset for parsing
  price = null;
  lot = null;
  reset();

  // see if first character is a trigger character like '#' or '@' or '!'
  // ex. !squid 25, #squid 25, @squid 25
  if (['#', '!', '@'].indexOf(parts[0].substr(0, 1)) > -1) {
    let part = parts[0];
    if (['#', '!', '@'].indexOf(part) > -1) {
      parts = parts.slice(1);
      part = parts[0];
    }
    lot = part.replace(/!|\#|@/, '');
    parts = parts.slice(1).filter((part: string) => {
      let matches = part
        .toLowerCase()
        .trim()
        .match(/\bfor\b|\bon\b|\bto\b|\bat\b|\bthe\b/i);
      return !matches || matches.length < 1;
    });
    index = 0;
    triggerIndex = -1;
    while (index < parts.length && triggerIndex < 0) {
      part = parts[index];
      try {
        price = parseFloat(part.replace(/\$/g, ''));
        if (price > 0) {
          triggerIndex = index;
        }
      } catch (err) {}
      index++;
    }
  }

  // get the rest of the lot name
  if (triggerIndex > 0) {
    lot += ' ' + parts.splice(0, triggerIndex).join(' ');
  }

  if (price && lot) {
    return done();
  }

  price = null;
  lot = null;
  reset();

  // First element was not a number, look for keywords
  while (index < parts.length) {
    let matches = parts[index].trim().match(/bid|dib/g);
    if (matches && matches.length > 0) {
      if (triggerIndex === -1 || index === triggerIndex + 1) {
        triggerIndex = index;
      }
    }
    index++;
  }

  if (triggerIndex < 0 && message.indexOf('#') < 0) {
    return done(true);
  }

  parts = parts.slice(triggerIndex + 1);

  reset();
  while (index < parts.length && triggerIndex < 0) {
    const part = parts[index];
    price = parseFloat(part.replace(/\$/g, ''));
    if (price > 0) {
      triggerIndex = index;
    }
    index++;
  }

  if (triggerIndex < 0) {
    return done(true);
  }

  if (triggerIndex + 1 >= parts.length) {
    price = null;
    return done(true);
  }

  parts = parts.slice(triggerIndex + 1).filter((part: string) => {
    let matches = part
      .toLowerCase()
      .trim()
      .match(/\bfor\b|\bon\b|\bto\b|\bat\b|\bthe\b/i);
    return !matches || matches.length < 1;
  });

  lot = parts.join(' ');

  return done();
};

export const parseMessage = (message: string, simple = false) =>
  parseBid(message, simple);
