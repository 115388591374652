import { ReactNode } from 'react';
import {CdnImage} from 'components';
import Box from '@mui/material/Box';


export const PausedVideo = ({ children }:{ children?: ReactNode }) => (
  <Box 
    height='100%'
    id="video-paused" 
    sx={{
      background: `url(https://cdn.kwixl.com/img/kwixl_hero.jpg) center center no-repeat`,
      backgroundSize: 'cover',
    }}
    display="flex"
    flexDirection="column"
    justifyContent="flex-start"
    textAlign={'center'}
  >
    <Box display='flex' justifyContent='center' mb={3} mt={{ xs: 15, md: 18 }}>
      <CdnImage src={"/icons/favicon-96x96.png"} height={96} width={96} permanent={true}/>
    </Box>
      <Box display='flex' justifyContent='center' fontSize={{ xs: '1.2em', sm: '2em' }} fontWeight='bolder' sx={{ color: '#fffff0' }}>
        {children}
      </Box>
  </Box>
);

