import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTES} from 'routes';
import {useSessionContext} from 'hooks';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent} from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const menuItems = [{
  name: 'Address',
  value: ROUTES.cp_settings_address,
  root: true,
}, {
  name: 'Events',
  value: ROUTES.cp_settings_event,
}, {
  name: 'Payments',
  value: ROUTES.cp_settings_payment,
}, {
  name: 'Shipping',
  value: ROUTES.cp_settings_shipping,
}, {
  name: 'Store',
  value: ROUTES.cp_settings_page,
}, {
  name: 'Policies',
  value: ROUTES.cp_settings_policy,
}];

export const SettingsMenu = () => {

  const {mobile} = useSessionContext();
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname;

  let active = menuItems.find(item => item.value === path);

  if (!active) active = menuItems[0];

  const value = menuItems.find(item => item.value === path)?.value || ROUTES.cp_settings_address;

  const handleClick = ({ target: { value }}: SelectChangeEvent) => {
    navigate(value);
  }

  return mobile 
    ? (
        <Box mt={2} mb={2}>
          <Select value={value} onChange={handleClick} fullWidth>
              {menuItems.map(item => (
                <MenuItem 
                  value={item.value}
                  selected={path === item.value || (path === ROUTES.cp_settings && item.root)}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </Box>
      )
    : (
        <Box mt={2} mb={2}>
          <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem/>}>
            {menuItems.map(item => (
              <MenuItem
                selected={path === item.value || (path === ROUTES.cp_settings && item.root)}
                onClick={() => navigate(item.value)}
              >
                {item.name}
              </MenuItem>
            ))}
          </Stack>
        </Box>
      );
};
