/**
 * The modal from which all others are born ;)
 */
import { useState, useEffect, FC, PropsWithChildren } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import HelpIcon from '@mui/icons-material/Help';
import { CancelButton } from 'components';
import { Modal } from './Modal';

interface Props extends PropsWithChildren {
    trigger?: string | JSX.Element;
    header?: string;
    open?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
}

export const BaseModal: FC<Props> = ({ 
    trigger = <HelpIcon/>,
    header = 'Notice',
    onOpen = () => {},
    onClose = () => {},
    children,
}) => {

    return (
        <Modal
            trigger={trigger 
                ? typeof trigger === 'string'  
                    ? <span style={{cursor:'pointer',fontWeight:'bolder'}}>{trigger}</span>
                    : trigger
                : <Button>Click to Open</Button>
            }
            opened={() => onOpen()}
            closed={() => onClose()}
            title={header}
            fullWidth
            maxWidth={'md'}
        >
            <DialogContent dividers>
                <DialogContentText>
                    {children}
                </DialogContentText>
            </DialogContent>
        </Modal>
    )
}