/*import {
  Model,
  validator,
  TransformValidateModel,
  TransformValidateDateTime,
} from '../Classes';
import {DateTime} from 'luxon';
*/
export enum PurchaseTypes {
  claim = 'claim',
  bid = 'bid',
  sale = 'sale',
}

/*
export class PurchaseUser extends Model<PurchaseUser> {
  @validator.IsString()
  @validator.IsOptional()
  uid?: string | null;

  @validator.IsString()
  @validator.IsOptional()
  fbId?: string | null;

  @validator.IsString()
  @validator.IsOptional()
  displayName?: string | null;
}

export class PurchaseChat extends Model<PurchaseChat> {
  @validator.IsString()
  id: string;
  @validator.IsString()
  message: string;
}

export class Purchase extends Model<Purchase> {
  @TransformValidateModel(PurchaseUser)
  user: PurchaseUser;

  @TransformValidateModel(PurchaseChat)
  @validator.IsOptional()
  chat?: PurchaseChat;

  @validator.IsString()
  @validator.IsOptional()
  productId: string;

  @validator.IsString()
  @validator.IsOptional()
  chatId?: string;

  @validator.IsEnum(PurchaseTypes)
  type: PurchaseTypes;

  @validator.IsString()
  eventId: string;

  @validator.IsString()
  orgId: string;

  @validator.IsString()
  description: string;

  @validator.IsNumber()
  price: number;

  @validator.IsNumber()
  qty: number = 1;

  @validator.IsString()
  lot: string;

  @TransformValidateDateTime()
  createdAt: DateTime;

  @TransformValidateDateTime()
  @validator.IsOptional()
  updatedAt?: DateTime;

  @TransformValidateDateTime()
  @validator.IsOptional()
  deletedAt?: DateTime;

  @validator.IsString()
  @validator.IsOptional()
  deletedBy?: string;
}
*/