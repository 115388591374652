import { toast, ToastOptions } from "react-toastify";

export const flashError = (msg: string, options?: ToastOptions) => 
  toast.error(msg, options);

export const flashSuccess = (msg: string, options?: ToastOptions) => 
  toast.success(msg, options);

export const flash = {
  error: flashError,
  success: flashSuccess,
};
