import { GenericObject } from 'components';

export const FBApi = async (
    path: string, 
    method: string, 
    token: string,
    data?: GenericObject,
): Promise<GenericObject> => {
    let payload: GenericObject = {
        access_token: token,
    }
    if (method.toUpperCase() === 'POST' && data) {
        payload = {
            ...payload,
            ...data,
        }
    }
    return new Promise ((resolve) => {
        try {
            window.FB.api(
                path, 
                method, 
                payload, 
                (result: GenericObject) => resolve(result)
            )
        } catch (err: any) {
            resolve({
                error: {
                    message: err.message,
                }
            });
        }
    })
}