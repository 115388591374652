import GavelIcon from '@mui/icons-material/Gavel';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

interface ItemTypeProps { 
    type: 'claim' | 'bid' | 'sale';
    [key:string]: any;
};

export const ItemTypeIcon = ({ type, ...rest }: ItemTypeProps) => {
    switch (type) {
        case 'bid':
            return <GavelIcon { ...rest }/>;
        case 'sale':
            return <AttachMoneyIcon { ...rest }/>;
        case 'claim':
            return <LocalOfferIcon { ...rest }/>;
    }
    return null;
}