import Alert, { AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import AlertTitle from '@mui/material/AlertTitle';

export const AlertBox = (props: AlertProps) => {
    const { children, title, ...rest } = props;
    return (
        <Box mt={1} mb={2}>
            <Alert { ...rest }>
                {title && <AlertTitle>{title}</AlertTitle>}
                {children}
            </Alert>
        </Box>
    )
}