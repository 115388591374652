import { useCallback, useContext, useEffect, useState } from 'react';
import type { Blocker, Transition } from 'history';
import { UNSAFE_NavigationContext, Navigator } from 'react-router-dom';
import {PrimaryButton, SecondaryButton} from 'components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

type BlockerNavigator = Navigator & {
  location: Location;
  block(blocker: Blocker): () => void;
};

export type ConfirmExitModalProps = {
  header?: string;
  hasUnsavedChanges: boolean;
  content?: any;
  confirmButton?: any;
  cancelButton?: any;
};

export const ConfirmExitModal = ({ 
    header = 'Confirm Page Exit',
    hasUnsavedChanges, 
    content = 'Are you sure you wish to leave?',
    confirmButton = 'Yes, leave',
    cancelButton = 'No, stay on page'
}: ConfirmExitModalProps) => {

  const navigator = useContext(UNSAFE_NavigationContext).navigator as BlockerNavigator;

  const [displayAlert, setDisplayAlert] = useState(false);
  const [transition, setTransition] = useState<Transition>();

  useEffect(() => {

    if (!hasUnsavedChanges) {
      return;
    }

    const {
      block,
      location: { pathname },
    } = navigator;

    try {
      const unblock = block((transition) => {
        const {
          location: { pathname: targetPathname },
        } = transition;
        // Note: We don't want to show the alert if the user is navigating to the same page.
        if (targetPathname === pathname) {
          return;
        }
        setDisplayAlert(true);
        setTransition({
          ...transition,
          retry() {
            unblock();
            transition.retry();
          },
        });
      });
      return unblock;
    } catch(err: any) {
      console.error(err.message);
    }
  }, [navigator, hasUnsavedChanges]);
  
  const leavePage = useCallback(() => {
    transition?.retry();
    setDisplayAlert(false);
  }, [transition]);

  const stayOnPage = useCallback(() => {
    setDisplayAlert(false);
  }, [setDisplayAlert]);

  return (
    <Dialog
        maxWidth="sm"
        open={displayAlert}
        onClose={stayOnPage}
    >
      <DialogTitle>
        {header}
      </DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={stayOnPage}>
          {cancelButton}
        </SecondaryButton>
        <PrimaryButton onClick={leavePage}>
          {confirmButton}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )
};