import { Parser } from "html-to-react";
import { BaseModal } from './BaseModal';
import Button from '@mui/material/Button';

const parser = Parser();

interface Props {
  text?: string,
  trigger?: any,
  header?: string,
  content?: string,
}

export const PolicyModal = ({ trigger, text, header, content }: Props) => 
  <BaseModal 
    trigger={trigger || <Button variant="text">{text}</Button>}
    header={header}
  >
    {parser.parse(content || '')}
  </BaseModal>
