import {useReducer, useEffect, createContext} from 'react';
import {reducer, debug} from 'lib';
import cloneDeep from 'lodash/cloneDeep';
import {useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import {Loading, GenericObject} from 'components';
import {EventStatus,EventType,VideoStatus} from '@kwixl/interface';
import {DateTime} from 'luxon';
import {
  query,
  where,
  addDoc,
  setDoc,
  orderBy,
} from 'firebase/firestore';
import type {DocumentSnapshot} from 'firebase/firestore';
import {
  useCollection,
  useDocument,
} from 'react-firebase-hooks/firestore';
import { nanoid } from 'nanoid';

export interface IEventContext {
  event: DocumentSnapshot;
  hostId: string;
  products: DocumentSnapshot[];
  purchases: DocumentSnapshot[];
  currentItem: DocumentSnapshot;
  claims: GenericObject[];
  facebookUsers: DocumentSnapshot[];
  attendees: DocumentSnapshot[];
  lastClaim: any;
  itemIndex: number | null;
  lastBlastId: string;
  blastMessage: string;
  claiming: boolean;
  ready: boolean;
  userTotal: number;
  eventTotal: number;
  addItem?: any;
  updateItem?: any;
  updateEvent?: any;
  getCurrentIndex?: any;
  setLastClaim?: any;
  setItemIndex?: any;
  pauseEvent?: any;
  getItemById?: any;
  getEventId?: any;
  updateProduct?: any;
  markClaimPrinted?: any;
  setUserPurchases?: any;
  eventEnded?: any;
  clearBlastMessage?: any;
  ccs?: any;
  productTimeout: number;
  card?: DocumentSnapshot;
}

const initialState = {
  hostId: null,
  purchases: [],
  facebookUsers: [],
  attendees: [],
  blastMessage: '',
  lastBlastId: '',
  currentItem: null,
  lastClaim: null,
  claims: [],
  itemIndex: null,
  videoStatus: VideoStatus.none,
  claiming: false,
  userTotal: 0,
  eventTotal: 0,
  ready: false,
};

export const EventContext =
  createContext<Partial<IEventContext>>({});

export const EventConsumer = EventContext.Consumer;

interface EventProviderProps {
  eventId?: string;
  children?: any;
}

export const EventProvider = ({
  eventId,
  children,
}: EventProviderProps) => {

  const {
    firebaseUser,
    defaultListenerOptions,
  } = useFirebaseContext();

  const {userProfile, organization} = useSessionContext();

  const {
    eventRef,
    blastMessagesRef,
    billingAccountRef,
    purchasesRef,
    eventFacebookChatUsersRef,
    inventoryRef,
    inventoryItemRef,
    productsRef,
    productRef,
    eventAttendeesRef,
  } = useRefs();

  const [
    {
      currentItem,
      claims,
      itemIndex,
      ready,
      userTotal,
      eventTotal,
      blastMessage,
      lastBlastId,
      hostId,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const eventIdValue = eventId || '_Xx__';

  const [event] = useDocument(
    eventRef!(eventIdValue),
    defaultListenerOptions,
  );

  const [products,] = useCollection(
    query(
      productsRef!, 
      where('source.id', '==', eventIdValue),
      orderBy('position', 'asc'),
    ),
    defaultListenerOptions,
  );

  const idField = event?.get('orgId') === userProfile?.orgId ? 'organization.id' : 'user.uid';
  const idValue = (userProfile?.orgId && event?.get('orgId') === userProfile?.orgId) ? userProfile?.orgId : firebaseUser?.uid || 'x__';

  const [purchases,] = useCollection(
    query(
      purchasesRef!,
      where('source.id', '==', eventIdValue),
      where(idField, '==', idValue),
      where('deletedAt', '==', null)
    ),
    defaultListenerOptions,
  );

  const [card,] = useDocument(
    billingAccountRef!(firebaseUser?.uid || 'xX__'),
    defaultListenerOptions
  )

  const [blast,] = useCollection(
    query(
      blastMessagesRef!,
      where('eventId', '==', eventIdValue),
      orderBy('createdAt', 'desc'),
    ),
    defaultListenerOptions,
  )

  const orgIdValue = (userProfile?.orgId && event?.get('orgId')) && (event?.get('orgId') === userProfile?.orgId) ? event.id : 'xx__';
  
  const [facebookUsers,] = useCollection(
    eventFacebookChatUsersRef!(orgIdValue),
    defaultListenerOptions,
  );
 
  const [attendees,] = useCollection(
    eventAttendeesRef!(orgIdValue),
    defaultListenerOptions,
  )

  useEffect(() => {
    let value = window.localStorage.getItem('kwixl.ho$t.id$$');
    if (!value) {
      value = nanoid(24);
      window.localStorage.setItem('kwixl.ho$t.id$$', value);
    }
    dispatch({ hostId: value });
  },[])

  useEffect(() => {
    if (!currentItem) return;
    const index = products?.docs?.findIndex(
      doc => doc.id === currentItem.id
    );
    dispatch({itemIndex: index});
  }, [currentItem]);

  useEffect(() => {
    if (!event) return;
    if (event.get('currentItem')) {
      const item = products?.docs?.find(doc => doc.id === event.get('currentItem'));
      if (item) dispatch({ currentItem: item, ready: true });
    }
    dispatch({ ready: true });
  }, [products, event]);

  useEffect(() => {
    if (!event || !userProfile) return;
    dispatch({
      eventTotal:
        userProfile?.orgId === event?.get('orgId')
          ? purchases?.docs?.reduce(
              (accum, next) => (accum += next.get('qty') * next.get('price')),
              0
            )
          : 0,
      userTotal: purchases?.docs?.filter(doc => doc.get('user.uid') === firebaseUser?.uid)
        .reduce((accum, next) => (accum += next.get('qty') * next.get('price')), 0),
    });
  }, [purchases]);

  const setLastClaim = (claim: GenericObject) => {
    dispatch({lastClaim: claim});
  };

  useEffect(() => {
    if (!blast?.docs?.length) return;
    if (blast.docs[0].id !== lastBlastId && 
      DateTime.utc().diff(
        DateTime.fromJSDate(blast.docs[0].get('createdAt').toDate()),
        ['seconds']
      ).seconds <= 5) {
        dispatch({blastMessage: blast.docs[0].get('message'), lastBlastId: blast.docs[0].id});
      }
  },[blast]);

  const clearBlastMessage = () => dispatch({blastMessage: ''});

  const eventEnded = () => {
    if (!event) return false;
    try {
      return (
        [EventStatus.closed, EventStatus.canceled, EventStatus.complete].indexOf(
          event?.get('status')
        ) > -1 || DateTime.utc().toMillis() >= DateTime.fromJSDate(event.get('endAt').toDate()).toMillis()
      );
    } catch (err: any) {
      console.error(err.message);
    }
    return false;
  };

  const ccs = () => {
    try {
      return event && [EventType.ccs, EventType.cca].indexOf(event?.get('type')) >= 0;
    } catch (err) {
      return false;
    }
  };

  const getItemIndex = (itemId: string) => {
    try {
      return products?.docs?.findIndex(({id}) => id === itemId);
    } catch (err) {
      return -1;
    }
  };

  const getEventId = () => (event ? event.id : null);

  // Already validated by edit form
  const addItem = async (item: GenericObject, index?: number) => {
    await addDoc(inventoryRef!(organization?.id), {
      source: {
        id: event?.id,
        type: 'event',
        name: event?.get('name'),
        date: event?.get('startAt').toDate(),
      },
      orgId: event?.get('orgId'),
      createdAt: DateTime.now().toUTC().toJSDate(),
      createdBy: firebaseUser?.uid,
      ...item,
    });
  };

  const updateItem = async (updatedItem: GenericObject) => {
    await setDoc(inventoryItemRef!(organization?.id, updatedItem.id), {...updatedItem}, { merge: true });
  };

  const setItemIndex = (index: number) => {
    dispatch({itemIndex: index});
  };

  const updateEvent = async (changes: GenericObject) => {
    await setDoc(eventRef!(event?.id || ''), changes, { merge: true });
  };

  const getCurrentIndex = () => itemIndex;

  const getItemById = (itemId: string) => {
    if (!products?.docs.length) return;
    const index = getItemIndex(itemId);
    if (!!index && index >= 0) {
      return products.docs[index];
    }
    return null;
  };

  const pauseEvent = () => {
    updateEvent({status: EventStatus.paused});
  };

  const markClaimPrinted = (claimId: string) => {
    const newClaims = cloneDeep(claims);
    let index = newClaims.findIndex(({id}:{id:string}) => id === claimId);
    if (index >= 0) {
      newClaims[index].printed = true;
    }
    dispatch({claims: newClaims});
  };

  /**
   * Updates a specified product
   */
  const updateProduct = async (productId: string, update: GenericObject) => {
    await setDoc(productRef!(productId), update, { merge: true });
  };

  const setUserPurchases = (data = []) => {
    dispatch({purchases: data});
  };

  debug('Event provider', products?.docs?.length);
  
  return (
    <EventContext.Provider
      value={{
        // values
        event,
        hostId,
        products: products?.docs?.filter(doc => !doc.get('deletedAt')) as DocumentSnapshot[],
        purchases: purchases?.docs?.filter(doc => doc.get('pending') !== true) as DocumentSnapshot[],
        productTimeout: 10,
        claims,
        itemIndex,
        ready,
        userTotal,
        eventTotal,
        currentItem,
        blastMessage,
        facebookUsers: facebookUsers?.docs,
        attendees: attendees?.docs,
        card,
        // functions
        updateEvent,
        getCurrentIndex,
        setItemIndex,
        pauseEvent,
        getItemById,
        addItem,
        updateItem,
        getEventId,
        setLastClaim,
        updateProduct,
        markClaimPrinted,
        setUserPurchases,
        eventEnded,
        ccs,
        clearBlastMessage,
      }}
    >
      {ready ? children : <Loading />}
    </EventContext.Provider>
  );
};
