export const en = {
  button_ccs: "Live CCS",
  event_ended_title: "The event has ended...",
  event_ended_message: "This event has ended. If you purchased items, you can go to your account to view your invoice and submit payment.",
  go_to_my_account: "Go To My Account",
  nav_home: "Home",
  login_link_sent: "A login link has been sent!",
  login_link_error: 'Error sending email link',
  invalid_email: 'Invalid e-mail address!',
  enter_registered_email: 'Enter your registered e-mail address',
  login_link_button: 'Send Sign-In Link',
  one_time_signin_title: 'One-Time Sign-in',
  login_link_error_message: 'There was an error sending your login link. This may be due to your account being disabled, or we could not find the email address you entered.',
  login_link_prompt: 'Please enter your registered e-mail address to receive a one-time-use link to sign-in to your account.',
  event_list_title: 'Events on',
  event_no_events: 'There are currently no events scheduled. Check back soon!',
  event_events_found: 'Below are currently scheduled events. Simply click on an event to enter or preview.',
  local_date_time: 'All dates and times are local.',
  load_more: 'Load More',
  live_commerce: 'Live Commerce',
  get_started_title: 'Get started today.',
  next_level_text: 'Ready to take your online sales to the next level? {signup}',
  your_choice_video: 'Your choice of live video stream...',
  our_platform: 'Our live commerce platform.',
  your_video_our_platform_text: 'Use your choice of live video stream provider ({app}, {youtube}, {facebook}) and our live commerce platform to take your live sale events to the next level!',
  learn_more: 'Learn More',
  get_started: 'Get Started',
  button_upcoming_events: 'See Upcoming Events!',
  easy_start_title: 'Easy to Use.',
  easy_start_text: 'Start your live video on {facebook} and point {app} to it.  Our AI-powered algorithms will turn comments into sales!',
  sign_up_online: 'sign-up on-line',
  title_hold_events: 'Hold Your Own Live Events.',
  message_hold_events: 'Ready to show the world what you\'ve got? Easily upgrade your account and start holding your own live events quickly. We even handle payment processing for your sales!',
  button_create_account: 'Create Your FREE Account!',
  payment_section_title: 'After the Sale...',
  payment_section_text: 'When your sale ends, We\'ll automatically generate invoices and help receive payments. Just hold your live event and we\'ll take care of the rest!',
  signup: 'Sign-Up',
  signin: 'Sign-In',
  signout: 'Sign-Out',
  sellon: 'Sell on',
  events: 'Events',
  privacy: 'Privacy',
  terms: 'Terms',
  pricing: 'Pricing',
  home: 'Home',
  help: 'Help',
  contact: 'Contact Us',
  sell: 'Sell',
  faq: 'Commonly Asked Questions',
  application_success: 'Your application has been submitted',
  application_error: 'Error submitting application: {message}',
  application_title: 'Seller Application',
  application_submitted: 'Your application has been submitted!  You can view your application progress on your dashboard and we\'ll contact you with any questions if necessary.',
  application_instructions: 'Simply fill out this application and submit for review.  We\'ll contact you with any questions and to talk about how your business could benefit from selling on',
  application_us_only: 'We are only accepting applications for U.S. businesses at this time.',
  dashboard_return: 'Return to Dashboard',
  ready_to_start: 'Ready to start selling on',
  please_note: 'Please Note',
  please_wait: 'Please wait...',
  form_errors: 'One or more errors were detected.  Please review and correct the highlighted fields below.',
  label_business_name: 'Business Name',
  label_business_type: 'Business Type',
  label_business_license: 'Business License',
  label_contact_name: 'Contact Name',
  label_business_contact: 'Business Contact',
  label_email: 'E-Mail',
  label_business_phone: 'Business Phone',
  label_business_address: 'Business Address',
  label_business_about: 'About Your Business',
  label_describe_business: 'Please describe your business including types of products sold and current on-line sales methods',
  label_fb_page: 'Facebook Page',
  label_accept_selling_terms: 'I have read and agree to the Terms and Conditions and further certify that my business does not sell any products in violation of these terms.',
  label_acceptance: 'Acceptance',
  button_submit_application: 'Submit Application',
  application_before_submit: 'Before submitting your application, please make sure you have read our {terms} as your participation in {app} as a seller will be governed by these terms.',
  text_fb_page: 'If you have a {fb} page for your business, please enter it here.  We\'d love to check it out!',
  text_business_license: 'Please provide your document filing id or business license.  If Sole-Proprietor, enter "N/A". ',
  label_individual: 'Individual',
  label_company: 'Company',
  label_non_profit: 'Non-Profit',
  label_govt: 'Gov\'t Entity (U.S. Only)',
  message_confirm_page_invalid: 'This page can only be accessed when verifying an account.',
  message_confirm_try_again: 'Didn\'t receive your code? Sometimes it can take a few minutes to arrive. If it\'s been a while, you can {link}.',
  try_again: 'try again',
  message_unverified: 'Your account is unverified and must be verified to continue. Please choose how you wish to confirm your account below.',
  title_verify: 'Verify Your Account',
  request_login_link: 'request a login link',
  message_confirm_invalid: 'This page can only be accessed when confirming an account. If you believe you reached this page in error, you can {link}.',
  title_confirm_email: 'Confirm by E-mail',
  error_sending_confirmation: 'Error sending confirmation',
  message_email_verify: 'We\'ll send an e-mail to the address you provided containing a verification code.',
  action_send_email_confirmation: 'Send E-mail Verification',
  message_check_spam: 'Be sure to check your junk/spam mail folder if you don\'t see it in your inbox.',
  title_confirm_sms: 'Confirm by Text/SMS',
  message_sms_confirm: 'We\'ll send a confirmation code to the phone number you enter below.',
  action_send_sms_confirmation: 'Send SMS Verification',
  message_sms_notice: 'Standard messaging rates may apply.',
  title_verification: 'Enter Verification Code',
  message_verification: 'Your confirmation code has been sent. Please keep this page open until you recieve your code and enter it below.',
  error_verification: 'There was an error confirming your account. Please verify the code and try again.',
  message_title_blocked: 'You Have Been Blocked',
  message_blocked: 'The event owner has blocked you from participating in the event.',
  title_categories: 'Categories',
  title_contact_us: 'Contact Us',
  message_contact_us: 'Please use the form below to send us any questions you may have regarding our services and how we can help you!',
  label_name: 'Your Name',
  placeholder_phone_optional: 'Phone number (Optional)',
  placeholder_message: 'Please enter your message here',
  action_send: 'Send',
  label_toll_free: 'Toll-Free Phone (U.S. Only):',
  label_other_resources: 'Other Resources:',
  title_faq: 'Commonly Asked Questions',
  title_support: 'Support Center',
  contact_success: 'Your message has been sent.',
  contact_error: 'There was an error submitting your message.',
  profile: 'Profile',
  billing: 'Billing',
  subscriptions: 'Subscriptions',
  dashboard: 'Dashboard',
  create_event: 'Create Event',
  menu_event_manager: 'Event Manager',
  menu_settings: 'Settings',
  menu_organization: 'My Organization',
  menu_customers: 'Customers',
  support: 'Support',
  join: 'Join',
  apply: 'Apply to Sell!',
  forgot_password: 'Forgot password?',
  password: 'Password',
  email_address: 'E-mail Address',
  login_error: 'Login Error',
  signin_error: 'There was an error signing in. Please check your login information and try again.',
  register_text: 'By registering and using this site you are agreeing to our {privacy} and {terms}.',
  login_to_link: 'Please login to link your account',
  login_to_provider: 'Login to {provider}',
  link_account: 'Link Your {provider} Account',
  login_with: 'Sign-In with {provider}',
  email_linked: 'The email attached to the account you are using to login already exists in our system with a different provider.',
  try_other_provider: 'To continue, please try one of the other login methods below to sign-in.',
  email_maybe_linked: 'This may be because the account you are attempting to link is already attached to a different Kwixl account. You\'ll need to login with that Kwixl account first, or try a different Facebook account.',
  invite_people: 'Invite People',
  add_people: 'Add Users to Your Organization',
  enter_user_email: 'Please enter the e-mail address of the person you would like to invite to your organization.',
  enter_email: 'Enter an e-mail address',
  cancel: 'Cancel',
  invite: 'Invite',
  invite_sent: 'Your invitation was sent to {email}.',
  invite_not_sent: 'Your invitation to {email} could not be sent.',
  save: 'Save',
  close: 'Close',
  my_notifications: 'My Notifications',
  view_invoice: 'View Invoice',
  no_notifications: 'You do not have any {type} notifications.',
  view_notification_type: 'View {type}',
  unread: 'unread',
  archive: 'archive',
  archived: 'archived',
  no_billing_method: 'You do not currently have a valid payment method on file and will not be able to access or create events until one is available.',
  add_billing: 'Add Billing',
  add_billing_shopify: 'This billing method is used for any events that you create directly on Kwixl. Events created through your Shopify(tm) channel will be billed to your store account.',
  previous: 'Previous', 
  next: 'Next',
  payment_method: 'Payment Method',
  payment_method_text: 'Your purchase method is used to pay for items purchased during events.',
  card: 'Card',
  expires: 'Expires',
  provide_payment_method: 'You will need to provide a payment method before you can participate in events.',
  billing_account: 'Billing Account',
  billing_account_text: 'The payment method listed here will be used to automatically pay invoices or costs not covered by event fees.',
  change: 'Change',
  add_billing_method: 'Please use the button below to add a new billing method to your account.',
  invoices: 'Invoices',
  title_invoice: 'Invoice',
  id: 'ID',
  date: 'Date',
  invoice_date: 'Invoice Date',
  amount: 'Amount',
  status: 'Status',
  refunded: 'Refunded',
  paid_date: 'Paid {date}',
  continue_with: 'Continue with {provider}',
  setting_up_event: 'Setting up your event...',
  button_pay_now: 'Pay Now',
  invoice_paid_on: 'This invoice was paid on {date}',
  invoice_payment_ready: 'Your invoice is ready for payment and will be automatically charged using the payment method on file.',
  invoice_payment_refunded: 'Your invoice payment was refunded on {date}.',
  invoice_not_ready: 'Your invoice is not yet ready for payment. You will be notified when the invoice is ready.',
  title_description: 'Description',
  title_price: 'Price',
  title_qty: 'Qty',
  title_total: 'Total',
  title_tax: 'Tax',
  title_invoice_total: 'Invoice Total',
  invoice_not_found: 'We could not find the requested invoice.',
  hash: '#',
  block_removed: 'Block removed.',
  block_remove_error: 'Error removing block from user!',
  blocked_users_description: 'Blocked users are added during an event. You can ban the user forever, for a certain amount of time, or just for the duration of the event.',
  name_email_filter: 'Filter by first, last name, handle or e-mail address',
  short_date: 'MM/dd/yyyy',
  title_customer: 'Customer',
  title_blocked: 'Blocked',
  title_type: 'Type',
  title_reason: 'Reason',
  title_expires: 'Expires',
  never: 'Never',
  remove_block_header: 'Remove User Block',
  remove_block_confirm: 'Remove the block for {name}? Are you sure?',
  no_blocked_users: 'No blocked users were found.',
  no_load_customer: 'Could not load customer record!',
  groups_description: 'This page allows you to create different groups to organize your customers, for example to offer different discount levels.',
  title_group: 'Group',
  delete_group: 'Delete Group',
  customers_description: 'This page lists people that have participated in your events and have made purchases.',
  search_by_name: 'Search by name',
  title_invoices_paid: 'Invoices/Paid',
  title_total_paid: 'Total Paid',
  title_avg_ticket: 'Avg. Ticket',
  no_customers: 'You currently do not have any customers.',
  enter_message: 'Enter your message',
  sending: 'Sending...',
  group_saved: 'Group saved.',
  group_save_error: 'Error saving group!',
  title_customer_group: 'Customer Group',
  label_group_name: 'Group Name',
  label_tax_rate: 'Tax Rate',
  tax_rate_prompt: 'Leave blank to use default tax rate.',
  label_shipping_discount: 'Shipping Discount',
  min_invoice_total: 'Min. Invoice Total',
  min_invoice_prompt: 'The minimum invoice amount to trigger the discount',
  label_invoice_discount: 'Invoice Discount',
  save_group: 'Save Group',
  none: 'None',
  group_updated: 'User group updated for {name}.',
  group_update_error: 'Error updating group for user {name}: {error}',
  social_selling_header: 'Leverage Social Selling!',
  social_selling_next_level: '{app} makes it easy to take your Social Selling game to the next level!',
  social_selling_facebook: 'Hold your live event on {platform} and {app} will help you turn comments into sales.',
  social_selling_after: 'After the sale, {app} handles invoicing, payment and shipping!',
  my_profile: 'My Profile',
  my_profile_image: 'My Profile Image',
  click_to_change: '{action} to change',
  tap: 'Tap',
  click: 'Click',
  info: 'Info',
  login: 'Login',
  phone: 'Phone',
  address: 'Address',
  payment: 'Payment',
  shipping_address: 'Shipping Address',
  shipping_address_prompt: 'Please provide the shipping address to use for your invoices.',
  profile_updated: 'Your profile has been updated.',
  profile_save_error: 'There was an error saving your profile: {error}',
  back_to_events: 'Back to Events',
  request_demo: 'Request a Demo!',
  confirm_payment: 'Confirm Payment',
  payment_method_notice: 'The following payment method will be used. If you wish to change your payment method, please visit your dashboard.',
  confirm_ticket_purchase: 'Confirm Ticket Purchase',
  payment_error: 'There was an error processing your payment. If the problem persists, please contact support.',
  payment_setup: 'Please go to your dashboard and setup a payment method before providing payment.',
  payment_initialize_error: 'Could not initialize payment',
  payment_successful: 'Payment successful',
  payment_final: 'All sales are final. By clicking the \'Confirm Payment\' button below, you are authorizing {seller} to charge your card for the amount specified.',
  no_payment_due: 'No payment is due at this time.',
  intro_ai: 'Turn comments into sales, leaving you free to interact with your audience and create a fun, engaging experience. Our AI-driven algorithms auto-detect when customers are wanting to make purchases!',
  point_app: 'Just point {app} to your posts and live videos and watch the sales come in!',
  intro_title: 'Automate Your {facebook} Live Sales',
  unknown_error: 'Unknown Error',
  edit_post_title: 'Edit Post',
  edit_post_scheduled: 'Your post has been scheduled for publishing.',
  edit_post_draft: 'Your post has been saved as draft.',
  edit_post_published: 'Your post was successfully published.',
  edit_post_error: 'Error saving post: {error}',
  edit_post_updated: 'Post updated',
  edit_post_new: 'New Post{target}',
  edit_post_create_text: 'Use the form below to create a new post for {facebook}.',
  edit_post_update_text: 'Updates made here are for internal purposes only.  Your post on {facebook} will not be updated.',
  edit_post_label_select_page: 'Select Page',
  edit_post_label_display_title: 'Display Title (for local use only)',
  edit_post_label_image: 'Upload Post Image',
  edit_post_label_message: 'Enter post message',
  edit_post_label_comment_level: 'Comment level',
  edit_post_comment_main: 'Main thread',
  edit_post_comment_product: 'Under product',
  edit_post_comment_any: 'Any level',
  edit_post_comment_helper_main: 'Only comments that are at the root level (i.e. main thread) of a post will be evaluated.',
  edit_post_comment_helper_product: 'Only comments that are direct replies to a product comment will be evaluated.',
  edit_post_comment_helper_any: 'Any comment on a post (including replies, replies to replies, etc.) will be evaluated.',
  edit_post_label_close_after: 'Auto-close (hours)',
  edit_post_close_helper: 'The post will close (no more comments accepted) after the number of hours have passed since posting.',
}

export default en;