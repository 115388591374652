import {useState, useEffect, useCallback} from 'react';
import {ConfirmModal} from 'modals';
import {useFirebaseContext, useRefs} from 'hooks';
import {flash} from 'lib';
import {
  setDoc,
  deleteDoc,
  getDoc,
} from 'firebase/firestore';
import Button from '@mui/material/Button';
import { DocumentSnapshot } from "firebase/firestore";
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

export const SubscribeButton = ({ 
  store, 
}:{
  store: DocumentSnapshot
}) => {

  const [subscription, setSubscription] = useState<DocumentSnapshot | null>(null);

  const {
    firebaseUser, 
  } = useFirebaseContext();

  const {
    subscriptionRef,
  } = useRefs();

  const subscriptionId = `${firebaseUser?.uid || 'x'}#${store.id || 'x'}`;

  const getSubscription = useCallback(async () => {
    if (!subscriptionId) return;
    let doc = await getDoc(subscriptionRef!(subscriptionId)).catch(err => console.log(err.message));
    if (doc?.exists()) setSubscription(doc);
  }, [subscriptionId, subscriptionRef]);

  useEffect(() => {
    (async () => {
      if (!store?.exists()) return;
      if (firebaseUser) await getSubscription();
    })()
  },[store, firebaseUser, getSubscription]);

  const subscribe = async () => {
    if (!store?.exists() || !firebaseUser?.uid) return;
    try {
      await setDoc(subscriptionRef!(subscriptionId), {
        orgId: store.id,
        name: store?.get('name') || '',
        email: true,
        sms: true,
        uid: firebaseUser.uid,
      });
      await getSubscription();
      flash.success('You have been subscribed!');
    } catch (err) {
      console.log(err);
      flash.error('Error creating subscription.');
    }
    /*
    openPhoneModal({
      value: firebaseUser?.phoneNumber,
      header: `Subscribe to ${store.name}`,
      text: `Please enter your phone number to subscribe to ${store.name} and be notified of new events.`,
      onSave: async (phone) => {
        try {
          const { data } = await Api.subscribeToSeller(store.id, phone);
          flash.success("You have been subscribed");
          dispatch({ subscription: data });
        } catch (err) {
          flash.error("There was an error saving the subscription");
        }
        closePhoneModal(false);
      },
    });
    */
  };

  const unsubscribe = async () => {
    try {
      await deleteDoc(subscriptionRef!(subscriptionId));
      setSubscription(null);
      flash.success('You have been unsubscribed');
    } catch (err: any) {
      flash.error(`There was an error unsubscribing: ${err.message}`);
    }
  };

    if (!store) return <span>No store</span>;
  
    return (
        <div style={{marginTop: '20px'}}>
          {!firebaseUser 
            ?  <p>Login to Subscribe to this seller!</p>
            :  subscription?.exists() ? (
                <ConfirmModal
                  title="Unsubscribe?"
                  content={`Do you wish to stop receiving notifications for ${store?.get('name')}?`}
                  trigger={
                    <Button variant='outlined' startIcon={<FavoriteIcon color='error'/>}>
                      Subscribed
                    </Button>
                  }
                  onConfirm={() => unsubscribe()}
                />
              ) : (
                <Button variant='outlined' onClick={() => subscribe()} startIcon={<FavoriteBorderIcon/>}>
                  Subscribe
                </Button>
              )
          }
        </div>
    );

};

