import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useFirebaseContext, useRefs} from 'hooks';
import {Loading, GenericObject, PrimaryButton, SecondaryButton, AppName} from 'components';
import {ROUTES} from 'routes';
import { updateDoc } from 'firebase/firestore';
import {InviteAction} from '@kwixl/interface';
import Alert from '@mui/material/Alert';

interface Props {
  invite: GenericObject;
  onAction?: (accepted: boolean) => void;
  onFinish?: () => void;
}

export const Invitation = ({
  invite, 
  onFinish = () => {},
  onAction = () => {},
}: Props) => {

  const navigate = useNavigate();

  const {
    firebaseUser,
    callable,
  } = useFirebaseContext();

  const { invitationRef } = useRefs();

  const [loading, setLoading] = useState<boolean>(false);
  const [handled, setHandled] = useState<boolean>(false);

  const acknowledge = async (accepted: boolean) => {
    setLoading(true);
    onAction(accepted);
    if (firebaseUser) {
      await updateDoc(invitationRef!(invite.id || 'x'), { action: accepted ? InviteAction.accept : InviteAction.decline });
    } else if (accepted) {
      navigate(ROUTES.signup, {
        state: {
          email: invite.email, 
          invite: invite.id
        },
      });
      return;
    } else {
      await callable!('invite-update', { id: invite.id, action: InviteAction.decline });
    }
    setLoading(false);
    setHandled(true);
    onFinish();

  };

  if (handled || invite?.action) return null;

  return (
    invite && (
      <Alert>
        You've been invited by <b>{invite.invitedBy.name}</b> to join their organization{' '}
        <b>{invite.organization.name}</b> on <AppName basic/>.
        {loading ? (
          <Loading />
        ) : (
          <span style={{display: 'inline-block', marginLeft: '20px'}}>
            <PrimaryButton size="small" onClick={() => acknowledge(true)} sx={{ mr: 1 }}>
              Accept
            </PrimaryButton>
            <SecondaryButton size="small" onClick={() => acknowledge(false)}>
              Decline
            </SecondaryButton>
          </span>
        )}
      </Alert>
    )
  );
};
