import Joi from "joi";

export const change_address_schema = Joi.object({
  name: Joi.string().required().min(1),
  address1: Joi.string().required(),
  address2: Joi.string().allow(null).allow("").optional(),
  city: Joi.string().required(),
  state: Joi.string().required(),
  zip: Joi.string().required(),
  country: Joi.string().required(),
});
