import { DocumentSnapshot } from 'firebase/firestore';
import { ChatInput } from './ChatInput';
import Box from '@mui/material/Box';

export const ChatForm = ({
  onCancel = () => {},
  stream = '',
  pageId = '',
  reply,
}:{
  onCancel?: () => void;
  stream?: string;
  pageId?: string;
  reply?: DocumentSnapshot;
}) => {
  return (
    <Box sx={{ padding: '5px 0', width: '100%', color: '#fffff0'}}>
        <ChatInput reply={reply} onCancel={onCancel} stream={stream} pageId={pageId}/>
    </Box>
  );
};