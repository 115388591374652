import {Model, validator, TransformValidateDateTime} from '../Classes';
import {DateTime} from 'luxon';

export class OrganizationCustomer extends Model<OrganizationCustomer> {
  @validator.IsString()
  uid: string;

  @TransformValidateDateTime()
  createdAt: DateTime;

  @validator.IsString()
  @validator.IsOptional()
  groupId?: string;
}
