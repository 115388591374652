import Joi from "joi";

export const change_profile_schema = Joi.object({
  handle: Joi.string().min(5).max(25).required().messages({
    "string.min": `"User Handle" should have a minimum length of {#limit} characters`,
    "string.max": `"User Handle" should have a maximum length of {#limit} characters`,
    "any.required": `"User Handle" is a required field`,
  }),
  firstName: Joi.string().min(1).required(),
  lastName: Joi.string().min(1).required(),
});
