import 'reflect-metadata';

import * as validator from 'class-validator';
import * as transformer from 'class-transformer';

// Errors are embedded on the model instance with this key.  A leading _ is
// required as those are treated at internal properties that are not exposed.
const ERRORS_KEY = '_errors';

export type NonFunctionPropertyNames<T> = {
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

// Extract all non-function properties from type T.  We also explicitly
// omit "id" because that is a special read-only getter for database-backed
// models
export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

// Like `NonFunctionProperties`, but make all optional
export type NonFunctionPropertiesOptional<T> = Partial<
  Pick<T, NonFunctionPropertyNames<T>>
>;

type PartialModel<
  P extends typeof Model,
  T extends Partial<InstanceType<P>>
> = {
  [K in keyof (InstanceType<P> | T)]: InstanceType<P>[K];
};

type ThrowableErrorConstructor<T> = new (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  code: any,
  message: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
) => T;

interface ThrowableErrorInterface {
  readonly code: string;
  readonly details: unknown;
}

type ThrowableError = ThrowableErrorConstructor<ThrowableErrorInterface>;

//interface SnapshotLike {
//  data: () => unknown;
//}

/**
 * Settings to configure loading and validation options for models.
 */
export interface ModelLoadOptions {
  /**
   * If true, validation errors will not be thrown.  See {@linkcode getErrors}
   * and {@linkcode isValid}.
   */
  silent?: boolean;

  /**
   * If defined, this call will be used to throw validation
   * errors.  It's interface is designed to be compatible with
   * Firebase's `functions.http.HttpError`.
   */
  throwableErrorClass?: ThrowableError;

  /**
   * When converting from a plain JS object to an instance of a {@linkcode Model},
   * the class-transform can choke if sees a data type that it doesn't
   * know how to handle propely (such as a
   * {@link https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp | Firestore Timestamp})
   * object.  In this case, if this flag is set to `true`, then the object will
   * be converted into a JSON string, then deserialized back into a JS
   * object before being transformed.
   *
   */
  safeMode?: boolean;

  /**
   * Custom transform options.
   */
  transformOpts?: transformer.ClassTransformOptions;

  /**
   * Custom validation options.
   */
  validateOpts?: validator.ValidatorOptions;
}

/**
 * Thrown when one or more validation errors are encountered.
 */
export class ValidationError extends Error {
  /**
   *
   * @param errors Array of errors
   */
  constructor(readonly errors: validator.ValidationError[]) {
    super();
  }
}

/**
 *
 * Create custom data models by subclassing this class.  To create
 * new instances use the `load` method.
 *
 */
export class Model<T> {
  constructor(data: NonFunctionPropertiesOptional<T> = {}) {
    Object.assign(this, data);

    //
    // HACK: We're hiding this so that typescript can't see it but when
    //       JSON.stringify is called with an instance of a Model, it'll still
    //       work properly
    //
    Object.defineProperty(this, 'toJSON', {
      value: (options?: transformer.ClassTransformOptions) => {
        const opts = {
          ...options,
          excludePrefixes: ['_'],
        };

        return transformer.classToPlain(this, opts);
      },
      writable: false,
      enumerable: false,
    });
  }

  /**
   *
   * Load an instance of this model.  When using this library in typescript,
   * this method should typically be used over {@linkcode Model.raw | raw}
   * because it applies typings to the incoming data.
   *
   */
  static load<P extends typeof Model>(
    this: P,
    data: NonFunctionPropertiesOptional<InstanceType<P>>,
    options: ModelLoadOptions = {}
  ): InstanceType<P> {
    return this.raw(data, options);
  }

  /**
   *
   * Load an instace of this model from an unknown data object.
   * Use this when data is coming from somewhere and the structure
   * of the data is unknown.  For all other use cases, it is probably
   * better to use {@linkcode Model.load} instead.
   *
   */
  static raw<P extends typeof Model>(
    this: P,
    data: unknown,
    options: ModelLoadOptions = {safeMode: true}
  ): InstanceType<P> {
    const validatorOptions: validator.ValidatorOptions = {
      whitelist: true,
      ...options.validateOpts,
    };

    const transformOptions: transformer.ClassTransformOptions = {
      exposeDefaultValues: true,
      ...options.transformOpts,
    };

    // If we're running in safeMode, then we run the incoming data
    // throught a JSON serialization -> deserialization process, otherwise
    // we pass the data object straight through.
    const preprocess = (data: unknown) =>
      options.safeMode === true ? JSON.parse(JSON.stringify(data)) : data;

    // Perform the actual transformation operation
    const instance = transformer.plainToClass(
      this,
      preprocess(data),
      transformOptions
    ) as InstanceType<P>;

    // Run the validation in synchronous mode and collect the errors
    const errors = validator.validateSync(instance, validatorOptions);

    // Process any errors
    if (errors.length > 0) {
      // Set the errors but make them read-only and non-enumerable
      Object.defineProperty(instance, ERRORS_KEY, {
        value: errors,
        writable: false,
        enumerable: false,
      });

      // If not in silentMode, we need to throw an error
      if (options.silent !== true) {
        // User-supplied error class
        if (options.throwableErrorClass) {
          throw new options.throwableErrorClass(
            'invalid-argument',
            errors.map(e => e.toString()).join(', ')
          );
        }

        //console.log(errors.map(e => e.toString()).join(', '));

        // Fall back to using ValidationError
        throw new ValidationError(errors);
      }
    }

    return instance;
  }

  /**
   * Perform a partial transform and validation operation on the provided data.
   * This might be useful if you want to perform an update operation on an
   * existing document.  For example:
   *
   *  const postUpdate = Post.partial({
   *   name: 'Post Title',
   *   updatedAt: DateTime.now().toUTC(),
   *  });
   *
   *  firebase.firestore().doc('posts/post-1').update(serialize(postUpdate));
   *
   * NOTE: This is currently in an experimental status--use with caution.
   */
  static partial<P extends typeof Model, T extends Partial<InstanceType<P>>>(
    this: P,
    data: T,
    options: ModelLoadOptions = {}
  ): PartialModel<P, T> {
    const validatorOptions: validator.ValidatorOptions = {
      skipMissingProperties: true,
      ...options.validateOpts,
    };

    // Setting `exposeDefaultValues` to false doesn't actually stop
    // default values from being added (why??)...so, below we remove any keys
    // that are on the resulting object that are not present on the passed
    // data object.
    const transformOptions: transformer.ClassTransformOptions = {
      exposeDefaultValues: false,
      ...options.transformOpts,
    };

    options = {
      ...options,
      validateOpts: validatorOptions,
      transformOpts: transformOptions,
    };

    const partial = this.raw(data, options) as T;

    const keys = Object.keys(data);

    // TODO: In order to be fully compliant with the shape of the input data,
    // this needs a recursive implementation that traverses through any
    // objects or arrays...
    Object.keys(partial).forEach(key => {
      if (keys.indexOf(key) < 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (partial as any)[key];
      }
    });

    return {...partial} as PartialModel<P, T>;
  }
}

/**
 *
 * When validation is in {@linkcode ModelLoadOptions.silent | silent} mode,
 * this can be used to retrieve any validation errors.
 *
 */
export const getErrors = <T extends typeof Model>(model: Model<T>) => {
  const property = Object.getOwnPropertyDescriptor(model, ERRORS_KEY);

  let errors: validator.ValidationError[] = [];
  if (property) {
    errors = property.value;
  }

  return errors;
};

/**
 *
 * When validation is in {@linkcode ModelLoadOptions.silent | silent} mode,
 * this checks to see if the model passed validation.
 *
 */
export const isValid = <T extends typeof Model>(model: Model<T>) => {
  const errors = getErrors(model);
  return errors.length === 0;
};

/**
 * Serializes the provided model into a JSON-friendly format.
 */
export const serialize = <T extends typeof Model>(
  model: Model<T>,
  opts?: transformer.ClassTransformOptions
) => {
  const descriptor = Object.getOwnPropertyDescriptor(model, 'toJSON');
  if (descriptor) {
    return descriptor.value(opts);
  }
};