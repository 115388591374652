import {useState, cloneElement} from 'react';
import {GenericObject, PrimaryButton, CancelButton} from 'components';
import {
  getStorage,
  ref as getStorageRef,
  uploadBytesResumable,
} from 'firebase/storage';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CheckIcon from '@mui/icons-material/Check';

export interface UploaderProps {
  header?: string;
  trigger: JSX.Element;
  content?: string;
  path?: string;
  dest: GenericObject;
  onClose?: () => void;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const Uploader = (props: UploaderProps) => {
  const {
    header = 'Upload File',
    content = 'Select a file to upload',
    trigger = <Button>Upload</Button>,
    dest = {},
    onSuccess = () => {},
    onError = () => {},
    onClose = () => {},
    ...modalProps
  } = props;

  const [open, toggleOpen] = useState(false);
  const [file, setFile] = useState<File>();
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const handleClose = () => {
    toggleOpen(false);
    onClose();
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const handleError = (err: any) => {
    onError(err);
    handleClose();
  };

  const getContentType = (ext: string) => {
    switch (ext) {
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'xls':
        return 'application/vnd.ms-excel';
      default:
        return 'text/csv';
    }
  };

  const upload = () => {
    if (!file) return null;
    setUploading(true);

    const parts = file.name.split('.');
    const ext = parts[parts.length - 1];

    const storage = getStorage();

    const storageRef = getStorageRef(
      storage,
      `${dest.path}/${dest.name}.${ext}`
    );

    const contentType = getContentType(ext);

    const uploadTask = uploadBytesResumable(storageRef, file, {contentType});

    uploadTask.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        switch (snapshot.state) {
          case 'paused':
            break;
          case 'running':
            break;
        }
      },
      error => {
        // Handle unsuccessful uploads
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }

        setUploading(false);
        handleError(error);
      },
      () => {
        setUploading(false);
        handleSuccess();
      }
    );
  };

  const Trigger = () => cloneElement(trigger, { disabled: open, onClick: () => toggleOpen(true) }) ;
    
  
  return (
    <>
      <Trigger/>
      <Dialog
        open={open}
        onClose={() => toggleOpen(false)}
      >
        <DialogTitle style={{textAlign: 'center'}}>{header}</DialogTitle>
        <DialogContent dividers>
          {uploading ? (
            <LinearProgress value={progress} variant="determinate" />
          ) : (
            <div>
              <p>{content}</p>
              <input
                type="file"
                name="file"
                onChange={e => e.target.files?.length ? setFile(e.target.files[0]) : null}
              />
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <CancelButton onClick={() => handleClose()}/>
          <PrimaryButton onClick={() => upload()} startIcon={<CheckIcon/>}>
            Upload
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
