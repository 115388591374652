import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';

export interface LoadingProps {
    children?: ReactNode;
    compact?: boolean;
    basic?: boolean;
}
export const Loading = ({ 
    children = <>{'Please wait'}</>, 
    compact = false, 
    basic = false,
    ...rest 
}: CircularProgressProps & LoadingProps) => (
    <Box style={{ flex: basic ? 0 : 1, width:'50%', textAlign: 'center', margin: basic ? '0 auto' : '50px auto' }} fontSize={compact ? 'small' : 'initial'}>
        <Box mb={(!basic && children) ? 3 : 0}>
            <CircularProgress { ...rest }/>
        </Box>
        { (!basic && children) && (
            <Box sx={{textAlign: 'center'}}>
                {children}
            </Box>
        )}
    </Box>
)