import { useVideoContext } from "hooks";
//import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import VideocamIcon from '@mui/icons-material/Videocam';
import Tooltip from '@mui/material/Tooltip';

export const CameraSelect = () => {

    const { 
        videoDevices,
        localVideoTrack,
        setCameraId,
        cameraId,
    } = useVideoContext();

    if ((videoDevices?.length || 0) < 2) return <></>;

    //let lastY = 60;

    return  (
        <>
            {videoDevices?.map((input, index) => {
                //lastY += index === 0 ? 46 : 37;
                return (
                    <Tooltip title={input.label}>
                        {/*<CameraSwitch
                            key={`__switch__${index}`}
                            style={{top: `${lastY}px`}}
                        >*/}
                            <Button
                                startIcon={<VideocamIcon/>}
                                color={
                                    cameraId === input.deviceId ? 'success' : 'disabled'
                                }
                                onClick={async () => {
                                    if (input.deviceId !== cameraId) {
                                        try {
                                            await localVideoTrack?.setDevice(
                                            input.deviceId
                                            );
                                            setCameraId!(input.deviceId);
                                        } catch (err) {}
                                        }
                                    }
                                }
                            >
                                {index + 1}
                            </Button>
                        {/*</CameraSwitch>*/}
                    </Tooltip>
                );
            })}
        </>
    )
}

/*
interface CameraSwitchProps {
    mobile?: boolean;
  }
  
const CameraSwitch = styled.div<CameraSwitchProps>`
    color: #000000;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    font-weight: bolder;
    position: absolute;
    right: -1px;
    ${(props: any) => (props.mobile === true ? "bottom : 20px;" : "top : 30px;")}
    z-index: 11;
    cursor: pointer;
    &:hover {
      border: 2px blue;
    }
`;
*/