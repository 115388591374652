import {CdnImage, FacebookText, YoutubeText, AppName} from 'components';
import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

export const VideoProviders = () => (
    <>
        <Grid container>
          <Grid item>
            <Grid item width={3}>
              <FacebookIcon />
            </Grid>
            <Grid item>
              <p>
                With <FacebookText />, you can broadcast your live video to your
                timeline or a page you manage and we'll embed the video on the{' '}
                <AppName /> event page. In addition, viewers can watch on{' '}
                <FacebookText /> and comments entered on <FacebookText /> will
                appear in the chat window.
              </p>
              <p>
                Viewers on the <AppName /> site will have the advantage over
                viewers on <FacebookText />.
              </p>
              <p>
                <FacebookText /> has the highest lag time (typically 20-30)
                seconds.
              </p>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item width={3}>
              <YouTubeIcon/>
            </Grid>
            <Grid item>
              <p>
                If you use <YoutubeText />, we'll embed the video in the{' '}
                <AppName /> event page, but comments left by viewers on{' '}
                <YoutubeText /> will not appear in the chat.
              </p>
              <p>
                <YoutubeText /> offers a lower lag time (typically 4-6 seconds)
                and you will need a <YoutubeText /> channel that is eligible for
                live broadcasts.
              </p>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item width={3}>
              <CdnImage centered size="small" src="/icons/apple-icon-114x114.png" permanent={true}/>
            </Grid>
            <Grid item>
              <p>
                The best experience for your users is to use <AppName />. If
                offers the lowest lag time (typically under 1 second) and best
                video.
              </p>
            </Grid>
          </Grid>
        </Grid>
    </>
)

export default VideoProviders;