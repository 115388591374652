import ListItemText from '@mui/material/ListItemText';
import styled from '@emotion/styled';
import Grid, { GridProps } from '@mui/material/Grid';
import { keyframes } from '@emotion/react';
import Typography from '@mui/material/Typography';

export const ListHeader = styled(ListItemText)`
    font-weight: bolder;
`

export const GridItem = (props: GridProps & { noWrap?: boolean}) => (
  <Grid item { ...props } fontSize={{ xs: 'small', md: 'initial' }}>
    {props.noWrap 
      ? <Typography fontSize={{ xs: 'small', md: 'initial' }}>
          {props.children}
        </Typography>
      : props.children
    }
  </Grid>
)

const fadeInOut = keyframes`
  0%, 100% { 
      opacity : 0 
    }
  50% { 
      opacity : 1 
    }
`;

export const FadeText = styled.span`
  width: 100%;
  animation : ${fadeInOut} 1.5s linear 0s infinite;
`

/*
import styled from '@emotion/styled';
import {Dropdown} from 'semantic-ui-react';

export const FormGroup = styled.div`
  display: block;
  margin-bottom: 20px;
`;

export const ResponsiveLabel = styled.div`
  display: block;
  width: 100%;
  font-weight: 800;
  margin-bottom: 5px;
  @media (min-width: 769px) {
    display: inline-block;
    max-width: 18%;
  }
`;

export const ResponsiveField = styled.div`
  word-break: break-all;
  display: block;
  width: 100%;
  @media (min-width: 769px) {
    display: inline-block;
    max-width: 70%;
    word-break: normal;
  }
`;

export const ResponsiveDropdown = styled(Dropdown)`
  @media only screen and (min-width: 769px) {
    min-width: 150px;
    width: 100%;
    max-width: 420px;
  }
`;
*/

export const Flex = styled.div`
  flex-grow: 1;
  flex: 1 1 auto;
  overflow: hidden;
`;


