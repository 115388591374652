import { ReactNode } from 'react';
import Link, { LinkProps } from '@mui/material/Link';

interface ExternalLinkProps extends LinkProps {
  to: string;
  onClick?: () => void;
  asLink?: boolean;
  children?: ReactNode;
  color?: string;
}

export const ExternalLink = ({ 
  to, 
  onClick = () => true, 
  asLink = false, 
  color = 'inherit',
  children, 
  ...rest
}: ExternalLinkProps) => {

  return (
    <Link component="a" href={to || '#'} sx={{ cursor: asLink ? 'pointer' : 'inherit', color }} target="_new" onClick={onClick} { ...rest }>
      {!!children ? children : to}
    </Link>
  );
};
