import {useEffect, useReducer, ChangeEvent} from 'react';
import {useFirebaseContext, useRefs} from 'hooks';
import {
  GenericObject, 
  Editor, 
  CurrencyField,
} from 'components';
import {reducer, formatCurrency} from 'lib';
import {DateTime} from 'luxon';
import {EventStatus,EventType} from '@kwixl/interface';
import {getDoc} from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
  initialValue?: GenericObject;
  onChange?: (name: string, value: any) => void;
  errors: GenericObject;
  lockType?: boolean;
  live?: boolean;
  ccs?: boolean;
}

const defaultEvent = {
  name: '',
  description: '',
  startAt: null,
  preview: true,
  type: EventType.kwixl,
  categoryId: '',
};

export const EventDetailsForm = (props: Props) => {
  const {
    errors = {},
    onChange = () => {},
    initialValue = defaultEvent,
    lockType = true,
    live = false,
    ccs = false,
  } = props;

  const {defaultListenerOptions} = useFirebaseContext();
  const { rootCategoryRef } = useRefs();

  const [categories] = useDocument(
    rootCategoryRef,
    defaultListenerOptions,
  )

  const [{
    event = initialValue,
    //categorySelections = [],
  }, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    (async () => {
      const now = new Date();
      now.setHours(now.getHours());
      now.setMinutes(0);
      while (DateTime.fromJSDate(now).diff(DateTime.now(), ['minutes']).minutes <= 15) {
          if (now.getMinutes() === 45) {
              now.setHours(now.getHours()+1);
              now.setMinutes(0);
          } else {
              now.setMinutes(now.getMinutes()+15);
          }
      }
      if (!initialValue?.startAt) handleChange('startAt', now);
      const root = await getDoc(rootCategoryRef! || 'x');
      dispatch({ categories: root });
    })();
  }, []);

  useEffect(() => {
    if (!categories?.exists()) return;
    const selections: any[] = [];
    Object.keys(categories?.data() || {})
      .filter(key => Object.keys(categories.get(`${key}.children`) || {}).length > 0)
      .sort((a: string, b: string) => b.localeCompare(a))
      .forEach((parent, index) => {
        const category = categories?.get(parent);
        //if (index > 0) selections.push(<Dropdown.Divider/>);
        selections.push(<MenuItem value={''} disabled>{category.name}</MenuItem>);
        Object.keys(category.children || {})
          .sort((a: string, b: string) => b.localeCompare(a))
          .forEach((childKey: string) => {
            const child = category.children[childKey];
            selections.push(
              <MenuItem value={childKey}>{` ${child.name}`}</MenuItem>
            )
          })
      })
    dispatch({ categorySelections: selections });
  }, [categories]);

  useEffect(() => {
    if (!initialValue) return;
    dispatch({ event: initialValue });
  },[initialValue]);

  const handleChange = (name = '', value: string | boolean | number | Date) => {
    if (!name) return;
    dispatch({
        event: {
          ...event,
          [name]: value,
        }
    });
    onChange(name, value);
  }

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  return (
    <>
        {(!live && !ccs) && (
          <Box mb={2}>
                <FormControl fullWidth>
                  <InputLabel>Event Type</InputLabel>
                  <Select 
                      name="type"
                      label="Event Type"
                      disabled={lockType && event?.type}
                      value={event?.type || 'kwixl'}
                      onChange={handleSelectChange}
                  >
                      <MenuItem value={EventType.kwixl}>Kwixl Live Event</MenuItem>
                      <MenuItem value={EventType.ccs}>Facebook Live Event</MenuItem>
                  </Select>
                  <FormHelperText>
                      {(() => {
                          switch (event?.type) {
                              case 'ccs':
                              return 'Your event is broadcast to Facebook using Kwixl and buyers purchase by making claims in the comments of the video on Facebook.';
                              default:
                              return 'Your event will be held on Kwixl and the video can be broadcast through Kwixl, Facebook or Youtube.';
                          }
                      })()}
                  </FormHelperText>
                </FormControl>
            </Box>
        )}
        {/*<Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
              <Select 
                  label="Category"
                  name="categoryId"
                  placeholder='Select a category'
                  value={event?.category?.name}
                  onChange={handleSelectChange}
                  error={errors?.category}
              >
                  {categorySelections}
              </Select>
              {errors?.category && <FormHelperText color="error">{errors.category}</FormHelperText>}
          </FormControl>
        </Box>*/}
        <Box mb={2}>
          <FormControl fullWidth>
            <TextField
                autoFocus
                label={ccs ? !live ? "Video Title" : "Event Name" : "Event Name"}
                name="name"
                placeholder={ccs ? "Enter a title for your video" : "Enter a name for your upcoming event"}
                value={event?.name}
                onChange={handleTextChange}
                error={errors?.name}
                helperText={errors?.name || ''}
            />
          </FormControl>
        </Box>
        {event?.type === EventType.kwixl && (
            <Box mb={2}>
              <FormControlLabel
                  label='Ticketed Event'
                  control={
                    <Switch
                        name="ticketed"
                        checked={event?.ticketed}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.checked)}
                    />
                  }
                />  
                <FormHelperText>If enabled, a ticket must be purchased to enter the event.</FormHelperText>
            </Box>
        )}
        {event?.ticketed && (
            <Box mb={2}>
              <FormControl>
                <CurrencyField 
                  label="Ticket Price"
                  name="ticketPrice"
                  value={event?.ticketPrice}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, parseFloat(formatCurrency(e.target.value)))}
                  error={errors?.ticketPrice}
                  helperText={errors?.ticketPrice || ''}
                />
              </FormControl>
            </Box>
        )}
        {(!live && !ccs) && (
            <Box mb={2}>
              <FormControl error={errors?.startAt}>
                <DateTimePicker 
                  label="Event Date"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  disabled={
                      !!event?.status &&
                      event?.status !== EventStatus.none &&
                      event?.status !== EventStatus.pending
                  }
                  value={DateTime.fromJSDate(event?.startAt || new Date())}
                  onChange={(date: DateTime | null) => {
                      if (date) handleChange('startAt', date.toJSDate())
                  }}
                />
                {errors?.startAt && <FormHelperText>{errors.startAt}</FormHelperText>}
              </FormControl>
            </Box>
        )}
        {!live && (
            <p>
                <label>
                    {ccs 
                      ? `Enter a description for your video.`
                      : `An event description (optional) is displayed on the event preview page
                          as well as the event page itself. Anything specific to the event
                          should be entered here.`
                  }
                </label>
            </p>
        )}
        {!live && (
          <Box mb={2}>
            <FormControl fullWidth>
                <InputLabel sx={{ verticalAlign: 'top'}}>Description</InputLabel>
                  <Editor
                    html={false}
                    key="_description_editor"
                    initialValue={event?.description}
                    onChange={value => {
                        handleChange('description', value);
                    }}
                  />
            </FormControl>
          </Box>
        )}
      </>
  );
};
