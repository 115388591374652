import Joi from "joi";

export const email_schema = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: false })
    .required()
    .messages({
      "string.empty": `"Email" must be a valid email address`,
      "string.email": `"Email" must be a valid email address`,
    }),
});
