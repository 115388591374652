import styled from "@emotion/styled";
import Button, { ButtonProps } from "@mui/material/Button";

export const ActionButton = styled(Button)<ButtonProps & { top?: number, right?: number }>`
  position: absolute;
  top: ${(props) => props.top || "10"}px;
  right: ${(props) => props.right || "10"}px;
  z-index: 11;
`;

export const CameraWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000000;
`;

export const HiddenVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
`;

export const PausedMessage = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 65px;
  background-color: rgba(0,0,0,.5);
  color: #ffffff;
  text-align: center;
  z-index: 10;
  font-size: 1.2rem;
  padding-top: 1.25rem;
`;

export const MicrophoneSwitch = styled.div`
  color: #000000;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  font-weight: bolder;
  position: absolute;
  right: 10px;
  top: 50px;
  z-index: 11;
  cursor: pointer;
  &:hover {
    border: 2px blue;
  }
`;

export const VolumeSwitch = styled.div`
  color: #000000;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  font-weight: bolder;
  position: absolute;
  right: 10px;
  top: 120px;
  z-index: 11;
  cursor: pointer;
  &:hover {
    border: 2px blue;
  }
`;

/*
export const StatusBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 35px;
    color: yellow;
    text-align: center;
    font-weight: bolder;
    z-index : 3;
`
*/

export const VolumeWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100px;
  height: 12px;
  background-color: darkgrey;
`;

export const VolumeMeter = styled.div`
  background-color: #34eb3d;
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 12px;
  display: inline-block;
`;
