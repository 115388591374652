
import {CdnImage} from 'components';
export const Logo = ({ inline = false }) => 
    <CdnImage 
        height='40px' 
        width='40px' 
        sx={{ 
            display: inline ? 'inline-block' : 'block', 
            height: '48px', 
            width: '48px' 
        }} 
        duration={0} 
        src="/img/logo.png"
    />
