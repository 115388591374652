import Avatar from '@mui/material/Avatar';
import { useSessionContext, useFirebaseContext } from 'hooks';
import { debug } from 'lib';

export const UserAvatar = ({ 
    src = '', 
    name = '', 
    height = 0, 
    width = 0, 
    nocache = false 
}) => {

    const { firebaseUser } = useFirebaseContext();
    const { userProfile } = useSessionContext();

    if (!src) src = userProfile?.photoURL || firebaseUser?.photoURL;
    
    if (!name) name = userProfile?.displayName || firebaseUser?.displayName;

    if (!height && !width) {
        height = 40;
        width = 40;
    } else if (height && !width) {
        width = height;
    } else if (width && !height) {
        height = width;
    }

    if (src && nocache) {
        if (src.includes('?'))
            src += '&' 
        else 
            src += '?';
        src += `_nc=${new Date().getTime()}`
    }
    
    debug('Avatar:', src);

    return src 
        ? <Avatar src={src} sx={{ height, width }}/>
        : <Avatar sx={{ height, width }}>{name.substring(0, 1).toUpperCase()}</Avatar>;

}