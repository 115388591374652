/**
 * This claim modal is used by the standard admin ui for an event.
 * There is a separate ClaimModal used for FB posts in components.
 */
import {useReducer, useEffect, ChangeEvent, cloneElement} from 'react';
import {
  reducer, 
  flash,
  formatCurrency, 
} from 'lib';
import {
  Money, 
  GenericObject, 
  CancelButton, 
  CheckButton,
  cdnImage,
  Loading,
} from 'components';
import {useEventContext, useRefs, useSessionContext} from 'hooks';
import {PurchaseTypes, parseMessage} from '@kwixl/interface';
import {nanoid} from 'nanoid';
import {
  addDoc,
  updateDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import type {DocumentSnapshot} from 'firebase/firestore';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import TagIcon from '@mui/icons-material/Tag';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const initialState: GenericObject = {
  selectedUser: null,
  purchaseUser: null,
  purchaseChat: null,
  purchaseClaim: null,
  product: null,
  formErrors: {},
  searchQuery: '',
  parsedMessage: null,
  searchProducts: [],
  buyers: [],
};

interface Props {
  trigger: JSX.Element;
  user?: GenericObject;
  chat?: GenericObject;
  purchase?: DocumentSnapshot;
  onClose?: () => void;
}

export const CommentClaimModal = ({
  trigger,
  user, 
  chat, 
  purchase, 
  onClose = () => {},
}: Props) => {
  
  const {
    event, 
    products, 
    purchases, 
    //ccs, 
    facebookUsers,
    attendees,
  } = useEventContext();

  const {
    organization,
  } = useSessionContext();

  const {
    purchasesRef,
    eventChatRef,
    inventoryRef,
  } = useRefs();

  const [
    {
      open = false,
      purchaseChat,
      purchaseClaim,
      product,
      formErrors,
      parsedMessage,
      searchProducts,
      buyers,
      selectedUser,
      purchaseUser,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  useEffect(() => {
    const b: GenericObject = {};
    const results: GenericObject[] = [];
    purchases?.forEach(p => {
      const key = p.get('user.uid') || p.get('user.fbId');
      if (key && !b[key]) {
        b[key] = true;
        results.push({
          key,
          value: {
            key,
            uid: p.get('user.uid') || null,
            fbId: p.get('user.fbId') || null,
            displayName: p.get('user.displayName'),
            photoURL: p.get('user.photoURL') || null,
          },
          image: {avatar: true, src: cdnImage(p.get('user.photoURL') || '/img/default-avatar.png')},
          text: p.get('user.displayName'),
        });
      }
    });
    attendees?.forEach(p => {
      if (!results.find(r => r.key === p.get('uid'))) {
        results.push({
          key: p.get('uid'),
          value: {
            key: p.get('uid'),
            uid: p.get('uid'),
            fbId: null,
            displayName: p.get('displayName'),
            photoURL: p.get('photoURL') || null,
          },
          image: {avatar: true, src: cdnImage(p.get('user.photoURL') || '/img/default-avatar.png')},
          text: p.get('displayName'),
        })
      }
    });
    facebookUsers?.forEach(p => {
      if (!results.find(r => r.key === p.id || (p.get('uid') && p.get('uid') === r.key))) {
        results.push({
          key: p.id,
          value: {
            key: p.id,
            uid: p.get('uid') || null,
            fbId: p.id,
            photoURL: p.get('photoURL') || null,
            displayName: p.get('displayName'),
          },
          text: p.get('displayName'),
          image: {avatar: true, src: cdnImage(p.get('photoURL') || '/img/default-avatar.png')},
        })
      }
    });
    dispatch({buyers: results});
    if (!purchase) {
      const selected = results.find(u => u.key === user?.uid || u.key === user?.fbId);
      dispatch({ selectedUser: selected?.key });
    }
  }, [purchases, attendees, facebookUsers]);

  useEffect(() => {
    const results = products?.map(product => ({
      key: product.id,
      text: product.get('name') ? `Lot ${product.get('lot')}: ${product.get('name')}` : `Lot ${product.get('lot')}`,
      value: product.id,
    }));
    dispatch({ 
      searchProducts: results, 
      parsedMessage: chat?.claim || null 
    });
    if (chat) {
      if (!chat.claim?.lot && chat?.message) {
        chat.claim = parseMessage(chat.message, event?.get('settings.simplified')) || {};
      }
      dispatch({ 
        purchaseChat: chat.claim, 
        parsedMessage: chat.claim,
      });
      let payload: GenericObject = {}
      if (chat.claim?.lot) {
        const p = products?.find(doc => `${doc.get('lot')}` === `${chat.claim.lot}`);
        if (p) {
          payload.product = p;
          payload.purchaseClaim = {
            productId: p.id,
            description: p.get('name'),
            price: parseFloat(p.get('price') || 0),
            type: p.get('type') || PurchaseTypes.claim,
          };
        } else {
          // have to add product to searchProducts
          const key = `add-${nanoid(8)}`;
          const name = `Lot ${chat.claim.lot}`;
          payload.purchaseClaim = {
            productId: key,
            description: name,
            price: chat.claim.price || 0,
            type: chat.claim.type || PurchaseTypes.claim,
          };
          payload.searchProducts = results || [];
          payload.searchProducts.push({
            key,
            text: name ? `Lot ${chat.claim.lot}: ${name}` : `Lot ${chat.claim.lot}`,
            value: key,
          });
        }
        payload.purchaseClaim.lot = chat.claim.lot || '';
        payload.purchaseClaim.qty = chat.claim.qty || 1;
      }
      dispatch(payload);
    }
  }, [products, chat]);

  useEffect(() => {
    if (!user) return;
    dispatch({purchaseUser: {...user, key: user.uid || user.fbId}});
  }, [user]);

  useEffect(() => {
    if (!purchase) return;
    const payload: GenericObject = {
      purchaseClaim: purchase.data(),
    };
    if (purchase?.get('user')) {
      const user = purchase.get('user');
      payload.purchaseUser = user;
      payload.selectedUser = user.uid || user.fbId;
    }
    if (purchase?.get('chat')) {
      payload.purchaseChat = purchase.get('chat');
    }
    dispatch(payload);
  }, [purchase]);

  const handleClose = () => {
    dispatch({ open: false });
    onClose();
  }

  const save = async () => {
    try {
      let purchaseId = '';
      const user = {
        ...purchaseUser,
        uid: purchaseUser.uid || selectedUser || null,
      }
      // purchase payload
      let payload = {
        ...purchaseClaim,
        price: parseFloat(purchaseClaim.price),
        qty: parseInt(purchaseClaim.qty),
        user,
        uid: selectedUser || purchaseUser?.uid || null,
      };
      if (purchase?.id) {
        await updateDoc(purchase.ref, payload);
        flash.success('Claim updated.');
        purchaseId = purchase.ref.id;
      } else {
        // add to inventory first
        const {
          description,
          lot,
          price,
          qty,
        } = purchaseClaim;
        const newProduct = {
          name: description,
          description,
          lot,
          price: parseFloat(price),
          qty: parseInt(qty),
        };
        const item = await addDoc(
          inventoryRef!(organization?.id || 'x'),
          {
            ...newProduct,
            createdAt: serverTimestamp(),
            deletedAt: null,
            image: null,
            orgId: organization?.id,
            published: true,
            reduceQty: false,
            shipping: false,
            source: {
              id: event?.id,
              type: 'event',
              name: event?.get('name'),
              date: event?.get('startAt').toDate(),
            },
            taxable: false,
            type: PurchaseTypes.claim,
            updatedAt: serverTimestamp(),
          }
        )
        const purchasePayload = {
          ...payload,
          source: {
            id: event?.id,
            name: event?.get('name'),
            type: 'event',
            date: event?.get('startAt').toDate(),
          },
          chat: { 
            ...purchaseChat || {},
            id: chat?.id || null,
          },
          productId: item.id,
          product: {
            id: item.id,
            ...newProduct,
          },
          orgId: organization?.id,
          organization: {
            id: organization?.id,
            name: organization?.name,
          },
          pending: false,
          invoiceId: null,
          createdAt: serverTimestamp(),
          deletedAt: null,
        }
        const ref = await addDoc(purchasesRef!, purchasePayload);
        purchaseId = ref.id;
        flash.success('Claim added.');
      }

      if (purchaseId && chat?.id && event?.id) {
        await setDoc(
          eventChatRef!(event.id, chat.id), 
          {
            claim: {
              id: purchaseId, 
              status: 'accepted'
            },
            purchaseId,
          },{
            merge: true,
          });
      }

      handleClose();
    } catch (err: any) {
      flash.error(`Error saving claim: ${err.message}`);
    }
  };

  const handleChange = ({ target: { name, value }}: ChangeEvent<HTMLInputElement>) => {
    if (formErrors) {
      delete formErrors[name];
    }
    let val = value;
    switch (name) {
      case 'price':
        val = formatCurrency(value);
        break;
      case 'qty':
        if (val) {
          val = val.replace(/[^\d-]/g, '');
        }
        break;
    }
    dispatch({purchaseClaim: {...purchaseClaim, [name]: val}, formErrors});
  };

  const handleBuyerChange = ({ target: {value} }: SelectChangeEvent<HTMLInputElement>) => {
    const u = buyers.find(({key}:{key: string}) => key === value);
    dispatch({
      selectedUser: value,
      purchaseUser: {
        key: u.key,
        fbId: u.fbId || null,
        displayName: u.displayName,
        uid: u.uid || null,
        photoURL: u.photoURL || null,
      },
    });
  };

  const handleProductSelect = ({ target }: SelectChangeEvent<HTMLInputElement>) => {
    const {value} = target;
    let update = {};
    if (value === '__add__') {
      update = {
        product: {
          id: value
        },
        purchaseClaim: {
          productId: value,
          type: PurchaseTypes.claim,
        },
      }
    } else {
      const p = products?.find(({id}) => id === value);
      if (p) {
        update = {
          product: p,
          purchaseClaim: {
            ...purchaseClaim,
            productId: p.id,
            lot: p.get('lot'),
            description: p.get('name'),
            price: p.get('price'),
            type: p.get('type') || PurchaseTypes.claim,
          },
        };
      } else {
        const key = `add-${nanoid(8)}`;
        update = {
          product: {
            id: key,
            name: value,
          },
          purchaseClaim: {
            ...purchaseClaim,
            description: value,
            type: PurchaseTypes.claim,
            productId: key,
          },
          searchProducts: [...searchProducts, {key, text: value, value: key}],
        };
      }
    }
    dispatch(update);
  };

  const fieldsValid = () => {
    const baseFields = purchaseClaim?.lot &&
      purchaseClaim?.price > 0 &&
      purchaseClaim?.qty > 0 &&
      selectedUser
        ? true
        : false;
    return product?.id === '__add__' 
      ? baseFields && purchaseClaim?.description.trim() !== ''
      : baseFields;
  };

  const validPrice =
    purchaseClaim?.type === PurchaseTypes.bid
      ? product?.meta?.bid
        ? parseFloat(product?.meta?.bid || 0) +
          parseFloat(product?.meta?.increment || 1)
        : 0
      : purchaseClaim?.price || 1;

  const formMarkup = () => (
    <>
      <DialogContent dividers>
        {purchaseChat?.message && (<Box mt={2} mb={3}>{purchaseChat.message}</Box>)}
        <Box mt={1} mb={2}>
          <FormControl fullWidth>
            <InputLabel>Buyer</InputLabel>
            <Select
              placeholder='Select Customer'
              fullWidth
              onChange={handleBuyerChange}
              value={selectedUser}
            >
              {buyers.map((b: any) => (<MenuItem value={b.key}>{b.text}</MenuItem>))}
            </Select>
          </FormControl>
        </Box>
        <Box mt={1} mb={2}>
          <FormControl fullWidth>
            <InputLabel>Item</InputLabel>
            <Select
              value={purchaseClaim?.productId}
              onChange={handleProductSelect}
            >
              {[{
                  key: '__add__',
                  text: 'Add New Product',
                  value: '__add__',
                }]
                .concat(searchProducts || [])
                .map((option: any) => (<MenuItem value={option.value}>{option.text}</MenuItem>))}
            </Select>
          </FormControl>
        </Box>
        {product?.id === '__add__' && (
          <Box mt={1} mb={2}>
            <FormControl fullWidth>
              <TextField
                label="Lot"
                disabled={purchaseClaim?.id || product?.lot ? true : false}
                name="lot"
                value={purchaseClaim?.lot}
                onChange={handleChange}
                placeholder="Lot"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TagIcon/>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Box>
        )}
        {product?.id === '__add__' && (
          <Box mt={1} mb={2}>
            <FormControl fullWidth>
              <TextField
                label="Description"
                name="description"
                value={purchaseClaim?.description}
                onChange={handleChange}
                placeholder="New product name"
              />
            </FormControl>
          </Box>
        )}
        {purchaseClaim?.type === PurchaseTypes.bid && (
          <Box mt={1} mb={2}>
            <FormControl fullWidth>
              <TextField
                label="Current bid"
                value={
                  <Money
                    value={parseFloat(product?.meta?.bid || product?.price)}
                  />
                }
                disabled
              />
            </FormControl>
          </Box>
        )}
        <Box mt={1} mb={2}>
          <FormControl fullWidth>
            <TextField
              label={purchaseClaim?.type === PurchaseTypes.bid ? 'Bid' : 'Price'}
              name="price"
              value={purchaseClaim?.price}
              onChange={handleChange}
              placeholder="Price"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon/>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
          {parsedMessage
            ? purchaseClaim?.type === PurchaseTypes.bid
              ? purchaseClaim?.price < validPrice && (
                  <span style={{color: 'red'}}>
                    <WarningIcon/> Bid
                    is less than <Money value={validPrice} />
                  </span>
                )
              : parsedMessage?.price > 0 &&
                parsedMessage?.price !== validPrice && (
                  <span style={{color: 'red'}}>
                    <WarningIcon/> Buyer
                    claimed at <Money value={parsedMessage.price} />
                  </span>
                )
            : null
          }
        </Box>
        <Box mt={1} mb={2}>
          <FormControl fullWidth>
            <TextField
              label="Qty"
              name="qty"
              value={purchaseClaim?.qty}
              onChange={handleChange}
              placeholder="Qty"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloseIcon/>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Box>       
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose}/>
        <CheckButton
          onClick={() => save()}
          disabled={fieldsValid() === false}
        />
      </DialogActions>
    </>
  )

  const Trigger = () => cloneElement(trigger, { disabled: open, onClick: () => dispatch({ open: true })});
  
  
  return (
    <>
      <Trigger/>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown={true} maxWidth="sm">
        <DialogTitle>{purchase?.id ? 'Edit' : 'Add'} Claim</DialogTitle>
        { buyers?.length > 0 ? formMarkup() : <DialogContent dividers><Loading/></DialogContent> }
      </Dialog>
    </>
  );
};
