export const slugify = (slug: string) => {
    while (slug !== '' && !slug[0].toLowerCase().match(/[a-zA-Z0-9_]/)) {
      slug = slug.substring(1);
    }
    return slug
      .replace(/[^a-zA-Z0-9-_]/gi, '')
      .replace(/___+/g, '__')
      .replace(/--+/g, '-')
      .toLowerCase();
  };
  