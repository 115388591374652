import {createContext, useEffect, useState, ReactNode} from 'react';
import {flash, debug} from 'lib';
import {useEventContext, useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import {animateScroll} from 'react-scroll';
import {DateTime} from 'luxon';
import {UserRoles} from '@kwixl/interface';
import {
  query,
  where,
  orderBy,
  limit,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  QuerySnapshot,
  DocumentData,
  DocumentReference,
} from 'firebase/firestore';
import {GenericObject} from 'components';
import { useCollection } from 'react-firebase-hooks/firestore';

export interface IChatContext {
  chatMessages: QuerySnapshot<unknown, DocumentData> | undefined;
  chatAtBottom: boolean;
  handleChatMessages: any;
  addChatMessage: any;
  setChatAtBottom: any;
  deleteChat: any;
  updateChat: any;
  scrollChat: any;
  sendChatMessage: any;
  updateChatReaction: any;
  getChatMessages: any;
  undoChatClaim: any;
  addChatReaction: any;
}

export const ChatContext = createContext<Partial<IChatContext>>({
  chatAtBottom: true,
});

export const ChatProvider = ({children}:{children: ReactNode}) => {

  const {
    eventChatsRef,
    eventChatRef,
    chatReactionRef,
  } = useRefs();

  const {
    firebaseUser, 
    defaultListenerOptions,
  } =
    useFirebaseContext();

  const {
    userProfile, 
    organization,
  } = useSessionContext();
  
  const {event} = useEventContext();

  const [chatAtBottom, setChatAtBottom] = useState(true);

  const [chatMessages] = useCollection(
    query(
      eventChatsRef!(event?.id || 'x'),
      where('deletedAt', '==', null),
      orderBy('createdAt', 'desc'),
      limit(100)
    ),
    defaultListenerOptions,
  )

  useEffect(() =>{
    if (chatAtBottom) {
      window.setTimeout(() => {
        scrollChat();
      }, 250);
    }
  },[chatMessages, chatAtBottom]);

  const scrollChat = () => {
    if (!document.getElementById('chat-scroller')) {
      return;
    }
    animateScroll.scrollToBottom({
      duration: 50,
      smooth: true,
      containerId: 'chat-scroller',
    });
  };

  const sendChatMessage = async (
    message: string, 
    isSystem?: boolean, 
    id?: string, 
    replyTo?: string,
  ) => {
    if (!event?.id || !eventChatsRef || !eventChatRef || !message) return;
    if (message) {
      try {
        const ref: DocumentReference = id ? eventChatRef(event.id, id) : doc(eventChatsRef(event.id));
        await setDoc(ref, {
          uid: firebaseUser?.uid,
          createdAt: DateTime.now().toUTC().toJSDate(),
          type: isSystem
            ? 'system'
            : userProfile?.role === UserRoles.admin &&
              organization?.id === event?.get('orgId')
            ? UserRoles.admin
            : UserRoles.user,
          handle: userProfile?.handle || null,
          displayName: `${userProfile?.firstName} ${userProfile?.lastName}`,
          photoURL: firebaseUser?.photoURL || null,
          eventId: event?.id,
          orgId: event?.get('orgId'),
          message,
          replyTo: replyTo || null,
          deletedAt: null,
        }, {
          merge: true,
        });
        debug('Added chat', ref.id, 'for', event?.id);
      } catch (err: any) {
        console.error(err.message);
      }
    }
  };

  const addChatReaction = async (chatId: string) => {
    debug('Add reaction for', chatId);
    const chat = chatMessages?.docs?.find((doc => doc.id === chatId));
    if (!chat) return;
    debug('Found chat', chat.id);
    const update: GenericObject = {};
    const found = (chat.get('reactions') || []).find((r: GenericObject) => r.uid === firebaseUser?.uid);
    update.reactions = found 
        ? arrayRemove({ uid: firebaseUser?.uid, type: 'like' })
        : arrayUnion({ uid: firebaseUser?.uid, type: 'like' })
    if (Object.keys(update).length > 0) {
      try {
        await updateDoc(chat.ref, update);
      } catch (err: any) {
        flash.error(`Error adding chat reaction: ${err.message}`);
        return;
      }
    }
    try {
      await setDoc(chatReactionRef!(event?.id || '', chatId, firebaseUser?.uid || ''), {
        type: found?.type === 'like' ? 'unlike' : 'like',
        chatId,
        uid: firebaseUser?.uid,
      },{
        merge: true,
      });
    } catch (err: any) {
      flash.error(`Error adding chat reaction: ${err.message}`);
    }
  };

  const deleteChat = async (id: string) => {
    await updateDoc(eventChatRef!(event?.id || '', id), {deletedAt: serverTimestamp()});
  };

  debug('Chat provider');
  
  return (
    <ChatContext.Provider
      value={{
        chatAtBottom,
        chatMessages,
        setChatAtBottom,
        scrollChat,
        sendChatMessage,
        addChatReaction,
        deleteChat,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
