import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {flash} from 'lib';
import {Phone, IntlText, PrimaryButton, Header} from 'components';
import {useFirebaseContext, useIntlText} from 'hooks';
import {ROUTES} from 'routes';
import styled from '@emotion/styled';
import SmsIcon from '@mui/icons-material/Sms';
import Box from '@mui/material/Box';

interface Props {
  user: any,
  invite?: string,
}

export const SMSConfirmation = ({user, invite}: Props) => {

  const navigate = useNavigate();

  const {callable} = useFirebaseContext();
  const {intlText} = useIntlText();

  const [phone, setPhone] = useState(!!user ? user.phone : '');

  const [loading, setLoading] = useState(false);

  const sendSMSConfirmation = async () => {
    if (!callable) return;
    setLoading(true);
    const {data} = await callable('auth-verify', {
      action: 'create',
      method: 'sms',
      value: phone,
      uid: user?.uid,
    });
    if (!data?.success) {
      flash.error(intlText("error_sending_confirmation"));
      setLoading(false);
      return;
    }
    const {uid, displayName} = user;
    navigate(ROUTES.verify_method('sms'), {state: {user: {uid, displayName}, value: phone, invite}});
  };

  return (
    <Box mt={2}>
      <Header as="h5"><IntlText id="title_confirm_sms"/></Header>
      <p><IntlText id="message_sms_confirm"/></p>
      <Phone country={'us'} value={phone} onChange={(phone: string) => setPhone(phone)} />
      <Box mt={2}>
        <PrimaryButton
          fullWidth
          variant="contained"
          loading={loading}
          disabled={loading}
          endIcon={<SmsIcon/>}
          onClick={() => sendSMSConfirmation()}
        >
          <IntlText id="action_send_sms_confirmation" />
        </PrimaryButton>
      </Box>
      <p>
        <small>* <IntlText id="message_sms_notice"/></small>
      </p>
    </Box>
  );
};