import {useEffect, useState, ChangeEvent} from 'react';
import {validate} from 'lib';
import {address_schema} from 'schema';
import {AddressForm, RequiredField, GenericObject, PrimaryButton, SecondaryButton} from 'components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';

interface Props {
  address?: GenericObject;
  trigger?: any;
  onSuccess?: (address: GenericObject) => void;
  cancelButton?: boolean;
}

export const EditAddressModal = ({
  address = {},
  trigger = (
    <Link component="a">
      Change
    </Link>
  ),
  onSuccess = (address) => {},
  cancelButton = true,
}: Props) => {

  const [userAddress, setUserAddress] = useState<GenericObject>(address);
  const [open, setOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<GenericObject>({});

  useEffect(() => {
    setUserAddress(address);
  }, []);

  const handleClose = () => {
    //setUserAddress();
    setOpen(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserAddress({...userAddress, [name]: value});
    delete errors[name];
    setErrors(errors);
  };

  const handleAddressChange = (update: GenericObject, formErrors: GenericObject) => {
    setUserAddress({
      ...userAddress,
      ...update,
    });
    setErrors(formErrors);
  };

  const handleSubmit = () => {
    const addressErrors = validate(userAddress, address_schema);
    if (addressErrors) {
      setErrors(addressErrors);
      return;
    }
    onSuccess(userAddress);
    handleClose();
  };

  return (
    <>
      <span onClick={() => !open ? setOpen(true) : {}} style={{ padding: 0, margin: 0, cursor: 'pointer' }}>
        {trigger}
      </span>
      <Dialog
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>Change Shipping Address</DialogTitle>
        <DialogContent dividers>
          <>
            <p>Enter an updated shipping address below.</p>
            <RequiredField error={errors.hasOwnProperty('name')}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={userAddress.name}
                placeholder="Addressee"
                onChange={handleChange}
              />
            </RequiredField>
            <AddressForm
              initial={userAddress}
              onChange={handleAddressChange}
              errors={errors}
            />
          </>
        </DialogContent>
        <DialogActions>
          {cancelButton && (
            <SecondaryButton startIcon={<CloseIcon/>} onClick={handleClose}>
              Cancel
            </SecondaryButton>
          )}
          <PrimaryButton
            onClick={() => {
              handleSubmit();
            }}
            startIcon={<CheckIcon/>}
          >
            Save Address
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
