import { useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import WestIcon from '@mui/icons-material/West';
import IconButton from '@mui/material/IconButton';
import { VideoStatusIcon } from './VideoStatusIcon';
import { NetworkQuality } from './NetworkQuality';
import { useVideoContext, useEventContext } from 'hooks';
import {VolumeWrapper, VolumeMeter} from '../styled';
import { VideoStatus } from '@kwixl/interface';
import { FadeText, VideoProviderIcon } from 'components';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Stack from '@mui/material/Stack';

export const StatusBar = ({
    onExit = () => {},
}:{
    onExit: () => void;
}) => {

    const { 
        networkQuality, 
        localAudioTrack,
        stream, 
    } = useVideoContext();

    const volumeRef = useRef<NodeJS.Timer>();
    const meterRef = useRef<HTMLElement>();

    useEffect(() => {
        setRef();
    },[]);

    useEffect(() => {
        
        switch (stream?.get('status')) {
          case VideoStatus.started:
            volumeRef.current = setInterval(() => {
              const level = localAudioTrack?.getVolumeLevel() || 0;
              setVolume(level);
            }, 100);
            break;
          case VideoStatus.stopped:
            setVolume(0);
            break;
          case VideoStatus.none:
            break;
        }
        return () => {
          if (volumeRef.current) clearInterval(volumeRef.current);
        }
    }, [stream]);

    const setRef = () => {
        const el = document.getElementById('volume-indicator');
        if (el) {
            meterRef.current = el;
        } 
    }

    const setVolume = (value: number) => {
        if (!meterRef.current) setRef();
        if (!meterRef.current) return;
        meterRef.current.style.width = value * 100 + 'px';
    }

    return (
        <Box 
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '50px',
                zIndex: 3,
            }}
        >
          <Stack direction="row" spacing={1}>
            <Box sx={{ display: { xs: 'initial', md: 'none' } }}>
              <IconButton onClick={onExit}>
                <WestIcon fontSize="large" color="info"/>
              </IconButton>
            </Box>
            <Box pt={.5}>
              <IconButton>
                <VideoProviderIcon value={stream?.get('provider')} height={24} width={24}/>
              </IconButton>
            </Box>
            <Box>
              <VideoStatusIcon/>
            </Box>
            <Box pt={1.7}>
              <NetworkQuality value={networkQuality || 0} />
            </Box>
            <Box pt={1.7}>
              <VolumeWrapper>
                <VolumeMeter id="volume-indicator"/>
              </VolumeWrapper>
            </Box>
            <Box pt={1.6} pr={1.5}>
              {(() => {
                switch (stream?.get('status')) {
                    case VideoStatus.started:
                        return (
                            <FadeText>
                                <FiberManualRecordIcon color="error"/>
                            </FadeText>
                        )
                    case VideoStatus.paused:
                        return (
                          <FiberManualRecordIcon color="warning"/>
                        )
                    default:
                        return null
                }
              })()}
            </Box>
          </Stack>
        </Box>
    )
}