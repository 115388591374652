import { PrivacyContent } from "content";
import { BaseModal } from './BaseModal';

interface Props {
  header?: string,
  trigger?: any
}

export const PrivacyModal = ({ header = 'Privacy Policy', trigger }: Props) => 
  <BaseModal 
    trigger={trigger || 'Privacy Policy'}
    header={header}
  >
    <PrivacyContent/>
  </BaseModal>