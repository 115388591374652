import {useNavigate} from 'react-router-dom';
import {flash} from 'lib';
import {Money, GenericObject, PrimaryButton} from 'components';
import {ROUTES} from 'routes';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import { PaymentModal } from 'modals';

interface Props {
  eventId: string;
  orgId: string;
  amount: number;
  onSuccess?: (intent: GenericObject) => void;
}

export const PurchaseTicket = ({eventId, orgId, amount, onSuccess}: Props) => {
  
  const navigate = useNavigate();

  const handleSuccess = async (data: {[key: string]: any}) => {
    if (data?.ticket) {
      navigate(ROUTES.event(eventId), {state: {ticket: data.ticket}});
    } else {
      flash.error('There was an error issuing your ticket.');
    }
  };

  return (
    <PaymentModal 
      trigger={
        <PrimaryButton 
          startIcon={<BookOnlineIcon/>}
        >
           Get Your Ticket! (<Money value={amount} />)
        </PrimaryButton>
      }
      onSuccess={onSuccess || handleSuccess}
      settings={{
        type: 'ticket',
        orgId,
        id: eventId,
        amount,
      }}
    />
     
  );
};
