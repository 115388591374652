
import IconButton from '@mui/material/IconButton';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import { useVideoContext } from 'hooks';

export const MicToggle = () => {

    const { muting, toggleMic } = useVideoContext();

    return (
        <IconButton 
            onClick={() => toggleMic!()}
        >
            { muting && <MicOffIcon color="error" fontSize="large"/> }
            { !muting && <MicIcon htmlColor='limegreen' fontSize="large"/> }
        </IconButton>
    )
}