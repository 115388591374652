import Grid from '@mui/material/Grid';
import {
  FacebookText,
  AppName,
  KwixlCamText,
} from 'components';
import FacebookIcon from '@mui/icons-material/Facebook';
import WifiIcon from '@mui/icons-material/Wifi';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import DialogContent from '@mui/material/DialogContent';

export const VideoSourceStep = ({ 
    onSelect,
}: { 
    onSelect: (type: string) => void;
}) => {

    return (
        <>
            <DialogContent dividers>
                <p>
                    Choose where to get the video to use for your sale. With either
                    option, you'll use <AppName /> to monitor and manage claims.
                </p>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                    <Card>
                        <CardActionArea 
                            onClick={() => onSelect('live')}
                        >
                        <CardMedia sx={{textAlign:'center'}}>
                            <WifiIcon style={{fontSize:'60px'}} />
                        </CardMedia>
                        <CardHeader title={<><KwixlCamText basic/> for <FacebookText basic/></>} sx={{textAlign:'center'}}/>
                        <CardContent>
                            We'll setup your <FacebookText /> video for you and you'll use
                            your camera to send a video feed directly to <FacebookText basic/>{' '}
                            through <AppName basic/>. <sup>*</sup>Incurs additional charges.
                        </CardContent>
                        </CardActionArea>
                    </Card>
                    </Grid>
                    <Grid item md={6}>
                    <Card>
                        <CardActionArea
                            onClick={() => onSelect('embed')}
                        >
                        <CardMedia sx={{textAlign:'center'}}>
                            <FacebookIcon
                                color="info"
                                style={{fontSize:'60px'}}
                            />
                        </CardMedia>
                        <CardHeader title={'I already have a live video!'} sx={{textAlign:'center'}}/>
                        <CardContent>
                            Choose the video to use from one of your live videos already on{' '}<FacebookText basic/>. You'll 
                            use <FacebookText basic/> Live to broadcast and manage your video.
                        </CardContent>
                        </CardActionArea>
                    </Card>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
}