/*import { Model, validator, TransformValidateModel, TransformValidateDateTime } from '../Classes';
import {DateTime} from 'luxon';*/

export enum MessageType {
    user = 'user'
}

/*
export class MessageUser extends Model<MessageUser> {

    @validator.IsString()
    orgId: string;
    
    @validator.IsString()
    uid: string;

    @validator.IsString()
    name: string;

}

export class Message extends Model<Message> {

    @validator.IsString()
    @validator.IsOptional()
    threadId?: string;
    
    @validator.IsEnum(MessageType)
    messageType: MessageType;

    @TransformValidateModel(MessageUser)
    from: MessageUser;

    @TransformValidateModel(MessageUser)
    to: MessageUser;

    @validator.IsString()
    orgId: string;

    @validator.IsString()
    parentId: string;

    @validator.IsString()
    subject: string;

    @validator.IsString()
    message: string;

    @validator.IsObject()
    @validator.IsOptional()
    meta?: {[key:string]:any};
    
    @TransformValidateDateTime()
    createdAt: DateTime;

    @TransformValidateDateTime()
    @validator.IsOptional()
    readAt?: DateTime;

    @TransformValidateDateTime()
    @validator.IsOptional()
    repliedAt?: DateTime;

    @TransformValidateDateTime()
    @validator.IsOptional()
    deletedAt?: DateTime;

    @validator.IsString()
    @validator.IsOptional()
    deletedBy?: string;

}
*/