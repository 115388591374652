import {useEffect} from 'react';
import {MediumDate, AlertBox} from 'components';
import {parseEmojis} from 'lib';
import styled from '@emotion/styled';
import {animateScroll} from 'react-scroll';
import {useFirebaseContext, useSessionContext} from 'hooks';
import {
  DocumentSnapshot,
} from 'firebase/firestore';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const containerId = 'kwixl-message-container';

export const Messages = ({ thread, messages }:{ thread: DocumentSnapshot, messages?: DocumentSnapshot[] | undefined }) => {

    const {
        firebaseUser, 
    } = useFirebaseContext();
    
    const {organization} = useSessionContext();

    useEffect(() => {
        scrollToBottom();
    },[messages]);

    const scrollToBottom = () => {
        animateScroll.scrollToBottom({
        duration: 150,
        smooth: true,
        containerId,
        });
    };

  const MessageText = ({ message, readAt = false }:{ message: DocumentSnapshot, readAt?: boolean }) => (
    <>
        <small>
            <Stack direction="column" mb={1}>
                <span style={{fontWeight:'bolder'}}>{message.get('from.name')}</span>
                <MediumDate date={message.get('createdAt')} />
            </Stack>
        </small>
      <Typography>
        {message.get('message').trim('\n').split('\n').map((line: any) => <>{parseEmojis(line)}<br/></>)}
      </Typography>
      {readAt && message.get('readAt') && (
        <Typography fontSize="9px" mt={1}>
          Read at <MediumDate date={message.get('readAt')}/>
        </Typography>
      )}
    </>
  )

  const recipient = thread?.get('user.id') === firebaseUser?.uid 
        ? thread?.get('org.name') 
        : thread?.get('user.name');

    return (
        <>
            {!messages?.length && (
                <AlertBox severity="info">
                    This is the start of your conversation with <strong>{recipient}</strong>. 
                    <p>Enter a message below and they will be notified according to their notification settings.</p>
                </AlertBox>
            )}
              {messages?.map(msg => {
                const dir = msg.get('from.id') === firebaseUser?.uid || msg.get('from.id') === organization?.id ? 'right': 'left';
                return (
                  <MessageWrapper dir={dir} key={`__msg_${msg.id}`}>
                    {dir === 'left' ? (
                      <Grid container>
                        <Grid item sx={{ maxWidth: '80%'}}>
                          <Msg severity="info" icon={false}>
                            <MessageText message={msg}/>
                          </Msg>
                        </Grid>
                        <Grid item flexGrow={1}/>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item flexGrow={1}/>
                        <Grid item sx={{ maxWidth: '80%'}}>
                          <Msg severity="success" icon={false} info={true}>
                              <MessageText message={msg} readAt={true}/>
                          </Msg>
                        </Grid>
                      </Grid>
                    )}
                  </MessageWrapper>
                );
              })}
        </>

    )
}

interface MsgProps {
  info?: boolean;
}

const Msg = styled(Alert)<MsgProps>`
  text-align: left;
  border-radius: ${props => props?.info ? '20px 20px 0 20px' : '20px 20px 20px 0'} !important;
`;

const MessageWrapper = styled.div`
  text-align: ${props => props.dir};
  width: 100%;
  padding: 5px;
`;


