import { useState, useRef } from "react";
import styled from "@emotion/styled";

interface Props {
  onChange?: (value: string) => void;
  size: number;
  error?: boolean;
}
export const VerifyCode = ({ onChange, size, error }: Props) => {
  const CODE_LENGTH = new Array(size).fill(0);
  const input = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false);

  const handleClick = () => {
    input.current?.focus();
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleKeyUp = (e: any) => {
    if (e.key === "Backspace") {
      setValue(value.slice(0, value.length - 1));
    }
  };

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    const updatedValue =
      value && value.length >= CODE_LENGTH.length
        ? value
        : (value + newValue).slice(0, CODE_LENGTH.length);
    onChange!(updatedValue);
    setValue(updatedValue);
  };
  const values = value ? value.split("") : [];

  const selectedIndex =
    values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;

  const hideInput = !(values.length < CODE_LENGTH.length);

  return (
    <CodeInputContainer onClick={handleClick}>
      <CodeInput
        value=""
        ref={input}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onFocus={handleFocus}
        onBlur={handleBlur}
        leftPosition={`${selectedIndex * (40 + 10)}px`}
        opacity={hideInput ? 0 : 1}
        type="tel"
      />
      {CODE_LENGTH.map((v, index) => {
        const selected = values.length === index;
        const filled =
          values.length === CODE_LENGTH.length &&
          index === CODE_LENGTH.length - 1;
        return (
          <CodeInputCell
            selected={selected}
            filled={filled}
            focused={focused}
            error={error}
          >
            {values[index]}
          </CodeInputCell>
        );
      })}
    </CodeInputContainer>
  );
};

interface CodeInputCellProps {
  error?: boolean;
  selected?: boolean;
  focused?: boolean;
  filled?: boolean;
}

const CodeInputCell = styled.div<CodeInputCellProps>`
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  position: relative;
  margin-right: 10px;

  ${(props) =>
    props.error &&
    `border-color: red;
    border-width: 2px;
    background-color: #ffffff`};

  ${(props) =>
    (props.selected || props.filled) &&
    props.focused &&
    `border-color: #000000;
    box-shadow: 0 0 4px 0 #222222;`}
`;

interface CodeInputProps {
  leftPosition: string;
  opacity: number;
}

const CodeInput = styled.input<CodeInputProps>`
  width: 32px;
  top: 0px;
  bottom: 0px;
  position: absolute;
  border: none;
  font-size: 22px;
  text-align: center;
  background-color: transparent;
  outline: none;
  left: ${(props) => props.leftPosition};
  opacity: ${(props) => props.opacity};
  z-index: 1;
`;

const CodeInputContainer = styled.div`
  position: relative;
  display: flex;
`;