import { useState, MouseEvent } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { UserRoles } from '@kwixl/interface';
import { useSessionContext } from 'hooks';

export interface ContextMenuOption {
    text?: string;
    icon?: JSX.Element;
    click?: () => void;
    element?: JSX.Element;
    roles: UserRoles[];
}

export const ContextMenu = ({ options }:{ options: ContextMenuOption[] }) => {
    
    const { 
        userProfile,
    } = useSessionContext();

    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    }

    return (
      <>
        <IconButton 
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(e.currentTarget);
                setOpen(true);
            }}
        >
            <MoreVertIcon/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            {options.filter(option => option.roles.includes(userProfile?.role))
            .map((option, index) => {
            return option.element 
                ? <MenuItem key={`__option_${index}`}>{option.element}</MenuItem>
                : <MenuItem onClick={option.click} key={`__option_${index}`}>
                    <Button startIcon={option.icon}>{option.text}</Button>
                </MenuItem>
            })}
        </Menu>
      </>
    )
  }