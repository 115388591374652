import {useState, useEffect} from 'react';
import {Sound, GenericObject, Header} from 'components';
import styled from '@emotion/styled';
import Table from '@mui/material/Table';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface SoundOption {
  title: string;
  value: string;
}

const soundOptions: SoundOption[] = [
  {
    title: 'None',
    value: 'none',
  },
  {
    title: 'Bell',
    value: 'bell',
  },
  {
    title: 'Laser Sword',
    value: 'lasersword',
  },
  {
    title: 'Sandy Creature',
    value: 'sandyperson',
  },
  {
    title: 'Cheering',
    value: 'cheering1',
  },
  {
    title: 'Cheering with Horn',
    value: 'cheering2',
  },
];

export const SoundSettings = ({ current = 'none', onSelect = (value: string) => {}}) => {

  const [sound, setSound] = useState<GenericObject>(soundOptions[0]);

  useEffect(() => {
    return () => {
      setSound(soundOptions[0]);
    };
  }, []);

  useEffect(() => {
    setSound(soundOptions.find(({value}) => value === current) || soundOptions[0]);
  }, [current]);

  const playSound = (s: string) => {
    const sound = soundOptions.find(({value}) => value === s);
    setSound(sound || soundOptions[0]);
    onSelect(sound?.value || 'none');
    try {
      const el = document.getElementById(
        `${sound?.value}-test`
      ) as HTMLMediaElement;
      if (el) {
        el.play();
      }
    } catch (err: any) {
      console.log('Could not play sound', err.message);
    }
  };

  return (
    <>
      <Typography component="header" variant="h6" sx={{ marginBottom: '.7em', textDecoration: 'underline', fontWeight: 'bold' }}>Sounds</Typography>
      <Grid container mb={0} mt={0}>
        <Grid item xs={12} md={9}>
          <span style={{fontWeight: 'bolder'}}>Successful Claim</span>
          <p>
            <small>
              This setting allows you to customize the sound that is played
              for the user when their claim is successful.
            </small>
          </p>
        </Grid>
        <Grid item xs={12} md={3}>
            <Select
              fullWidth
              placeholder='Select sound'
              value={sound?.value}
              onChange={(e: SelectChangeEvent<HTMLInputElement>) => playSound(e.target.value as string)}
            >
                {soundOptions.map((s) => <MenuItem value={s.value}>{s.title}</MenuItem>)}
            </Select>
        </Grid>
      </Grid>
      {soundOptions.map(({value}, index) => (
        <Sound id={`${value}-test`} file={value} key={`__soundopt_${index}`} />
      ))}
    </>
  );
};

