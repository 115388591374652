import { memo } from 'react';
import Emoji from 'react-emoji-render';
import isEqual from 'lodash/isEqual';
import {
  useSessionContext,
  useEventContext,
  useFirebaseContext,
  useChatContext,
} from 'hooks';
import {cdnImage, ChatAvatar} from 'components';
import styled from '@emotion/styled';
import { ConfirmModal, CommentClaimModal } from 'modals';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Box from '@mui/material/Box';
import {VideoProvider} from '@kwixl/interface';
import { DocumentSnapshot } from 'firebase/firestore';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { debug } from 'lib';

export const Message = ({
  data, 
  post = false, 
  type = 'kwixl',
  onReply = () => {},
}:{ 
  data: DocumentSnapshot, 
  post?: boolean; 
  type?: string;
  onReply?: (chat: DocumentSnapshot) => void;
}) => {

  const {userProfile, organization} = useSessionContext();
  const {ccs, event} = useEventContext();
  const {addChatReaction, deleteChat} = useChatContext();

  const {firebaseUser} = useFirebaseContext();

  if (!type) {
    try {
      type = ccs() ? 'ccs' : 'kwixl';
    } catch (err) {
      type = 'kwixl';
    }
  }

  try {
    const {
      orgId,
      uid,
      fbId,
      message = '',
      decorator,
      handle,
      photoURL,
      displayName = '',
      parent,
    } = data?.data() || {};

    let type = data.get('commentId') && data.get('type') !== 'admin' ? 'capture' : data.get('type');
    
    const isSystem = type === 'system';

    let avatarImage = photoURL;

    if (message.replace(/\s/gi, '') === '') return null;

    const showName = post || (firebaseUser && organization?.id === orgId);

    const adminView = userProfile?.role === 'admin' && organization?.id === orgId;

    const reactions = data?.get('reactions') || [];

    const found =
      reactions.findIndex(({uid}:{uid:string}) => uid === firebaseUser?.uid) > -1;

    let msg = message;

    if (isSystem) {
      avatarImage = cdnImage('/icons/apple-icon-60x60.png', true);
    }

    if (decorator === 'success') {
      msg = showName ? `${displayName} (${handle}) ${msg}` : `${handle} ${msg}`;
    }

    debug('User avatar', avatarImage);

    // mobile flag is used for admin mobile presenter view
    return (
      <Box sx={{overflow: 'hidden' }} color={'inherit'} mb={1}>
          <Box
            fontSize={{ xs: 'small', md: 'initial' }}
            display="flex"
            flexDirection="row"
            sx={{
              margin: { xs: post ? '0' : '0 5px 5px 0', md: '5px 1rem' },
              minHeight: '48px',
              backgroundColor: 'transparent',
              color: 'inherit',
              padding: { xs: post ? '0' : '1rem', md: '5px 1rem' }
            }}
          >
            {(data?.get('uid') || data?.get('fbId')) && !isSystem && (
              <AvatarContainer>
                <ChatAvatar
                  image={avatarImage}
                  name={isSystem ? '' : displayName}
                />
              </AvatarContainer>
            )}
            <Box flex={1} display="flex" flexDirection="column" justifyContent="flex-start" fontSize="small">
              <Box fontWeight='bolder' flex={0}>
                {displayName}{handle ? ` (${handle}${type === 'admin' ? ' - admin' : ''})` : type === 'admin' ? ' (admin)' : ''}
              </Box>
              { parent && (
                <Box>Replied to {parent.from.name} - <span style={{ fontStyle: 'italic' }}>"<Emoji text={parent.message} />"</span></Box>
              )}
              <Box flex={0} display="flex" flexDirection="column" justifyContent="flex-start">
                <Emoji text={msg} />
              </Box>
              <Box flex={0}>
                {firebaseUser && !post && (
                  <Box fontSize={11}>
                    <Stack direction="row" spacing={2}>
                      <Box onClick={() => addChatReaction(data?.id)} sx={{cursor:'pointer'}}>
                        {found ? (
                          <StarIcon sx={{verticalAlign:'bottom'}}/>
                        ) : (
                          <StarOutlineIcon sx={{verticalAlign:'bottom'}}/>
                        )}
                        {reactions.length || 0}
                      </Box>
                      {adminView && !post && data?.id && (
                        <Box display="flex" flexDirection="column" justifyContent="flex-end">
                          <ConfirmModal
                            title="Delete Chat"
                            content={
                              <>
                                <p>
                                  "<Emoji text={msg} />"
                                </p>
                                <p>
                                  The chat will be archived and hidden from anyone in
                                  the event.
                                </p>
                                <p>
                                  You will still be able to view it from your
                                  dashboard.
                                </p>
                              </>
                            }
                            trigger={<Box sx={{cursor:'pointer'}}>Delete</Box>}
                            onConfirm={() => deleteChat(data?.id)}
                          />
                        </Box>
                      )}
                      {(adminView && 
                        !parent && 
                        type === 'capture' && 
                        (type === 'ccs' ||
                          event?.get('stream.provider') === VideoProvider.facebook)) && (
                            <Box display="flex" flexDirection="column" justifyContent="flex-end">
                              <span style={{ cursor: 'pointer' }} onClick={() => onReply(data)}>Reply</span>
                            </Box>
                        )}
                      {(adminView && 
                        !parent && 
                        type === 'capture' && 
                        (type === 'ccs' ||
                          event?.get('stream.provider') === VideoProvider.facebook)) &&
                        !data.get('claim.id') && (
                          <Box display="flex" flexDirection="column" justifyContent="flex-end">
                            <CommentClaimModal 
                              trigger={
                                <Box sx={{ textDecoration: 'underline', fontWeight: 'bolder', cursor: 'pointer' }}>Add Claim</Box>
                              }
                              user={{
                                uid,
                                fbId,
                                displayName,
                              }}
                              chat={{ ...data?.data(), id: data?.id }}
                            />
                          </Box>
                      )}
                      {(adminView && data.get('claim.id')) && (
                        <Box display="flex" flexDirection="column" justifyContent="flex-end">
                          <Tooltip title="Claim has been accepted">
                            <CheckCircleOutlineIcon htmlColor="lime"/>
                          </Tooltip>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
      </Box>
    );
  } catch (err: any) {
    console.error(err.message);
    return null;
  }
};

const isSame = (prevProps: any, nextProps: any) => {
  return isEqual(prevProps.data, nextProps.data);
};

export default memo(Message, isSame);

const AvatarContainer = styled.div`
  flex: 0 1 42px;
`;

