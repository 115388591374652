import { useVideoContext } from 'hooks';
import VideocamIcon from '@mui/icons-material/Videocam';
import { FadeText } from 'components';
import IconButton from '@mui/material/IconButton';

type IconColors = 'disabled' | 'success' | 'error' | 'warning';

export const VideoStatusIcon = () => {

    const { videoClient } = useVideoContext();

    const connectionState = videoClient?.connectionState;

    let color: IconColors = "disabled";

    switch (connectionState) {
        case 'CONNECTED':
            color = 'success';
            break;
        case 'DISCONNECTED':
            color = 'error';
            break;
        case 'CONNECTING':
            color = 'warning';
    }

    return (
        <IconButton>
            {
                color === 'warning' 
                    ? <FadeText><VideocamIcon color={color} fontSize="large"/></FadeText>
                    : <VideocamIcon color={color} fontSize="large"/>
            }
        </IconButton>
    )
}